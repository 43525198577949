import { create } from 'zustand';
import { getBalance } from "@/service/account";

interface BalanceState {
	balance: number
	fetch: () => void
	reset: () => void
}

const useBalanceStore = create((set): BalanceState => ({
	balance: null,
	fetch: async () => {
		const res = await getBalance();
		set({
			balance: res?.data?.availableBalanceStr ?? null
		})
	},
	reset: () => {
		set({
			balance: null
		})
	}
}))

export {
	useBalanceStore,
	BalanceState
};