import React, { useEffect, useRef } from "react";
import Swiper from "@/components/Swiper";
import { InfiniteScroll, SideBar } from "antd-mobile";
import ItemCard from "@/bizComponents/ItemCard";
import { getBanner } from "@/service/common";
import { getAllBoxType } from "@/service/dict";
import { useSetState } from "ahooks";
import { BannerPositionEnum, IState } from "@/interfaces";
import { bannerListAdapt, boxItemListAdapt, boxTypeListAdapt } from "@/utils/biz";
import { useTranslation } from "react-i18next";
import { getBoxListByType, getRecommendBoxList } from "@/service/box";
import Loading from "@/components/Loading";
import { useNavigate } from "react-router-dom";
import { removeRepeat } from "@/utils/util";
import './index.scss';
import cn from "classnames";
import useDynamicItemHooks from "@/hooks/use-dynamic-item-hooks";

const defaultActiveKey = 'recommendation';
const functions = [
  {
    fn: getBanner,
    key: 'banner',
    args: BannerPositionEnum.list
  },
  {
    fn: getAllBoxType,
    key: 'boxType'
  },
];

interface IParamsRecommend {
  needLoading: boolean
  pageNum: number
  loadMore: boolean
}
interface IParamsNormal extends IParamsRecommend {
  boxType: string
}

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainRef = useRef(null);
  const [state, setState] = useSetState<IState>({
    banner: [],
    boxType: [],
    boxItemList: [],
    loading: false,
    activeKey: defaultActiveKey,
    boxItemLoading: false,
    border: "0 0 0 16px",
    pageNum: 1,
    pageSize: 10,
    hasMore: false
  });

  const getData = async (fn, type, args) => {
    setState({
      loading: true
    })
    const { data } = await fn(args) || {data: "[]"};
    try {
      let list: any[] = typeof data === 'string' ? (JSON.parse(data || "[]") || []) : data;
      if (type === 'boxType') {
        list = boxTypeListAdapt(list, t);
      }
      if (type === 'banner') {
        list = bannerListAdapt(list);
      }
      setState({
        [type]: list
      })
    } catch {

    } finally {
      setState({
        loading: false
      })
    }
  }
  const getRecommendBoxItemList = async (params: IParamsRecommend) => {
    const { needLoading, pageNum, loadMore } = params;
    if (needLoading) {
      setState({
        boxItemLoading: true
      })
    }
    const res = await getRecommendBoxList({
      pageNum,
      pageSize: state.pageSize
    });
    if (res) {
      const { data } = res;
      const formattedData = boxItemListAdapt(data.list);
      const List = loadMore ? [...state.boxItemList, ...formattedData] : formattedData;
      setState({
        boxItemList: removeRepeat(List),
        hasMore: !data?.isLastPage,
        pageNum: data?.nextPage
      })
    }
    setState({
      boxItemLoading: false
    })
  }
  const getBoxItemListByType = async (params: IParamsNormal) => {
    const { boxType, needLoading, pageNum, loadMore } = params;

    if (needLoading) {
      setState({
        boxItemLoading: true
      })
    }
    const res = await getBoxListByType({
      boxType,
      pageNum,
      pageSize: state.pageSize
    });
    if (res) {
      const { data } = res;
      const formattedData = boxItemListAdapt(data.list);
      const List = loadMore ? [...state.boxItemList, ...formattedData] : formattedData;
      setState({
        boxItemList: removeRepeat(List),
        hasMore: !data?.isLastPage,
        pageNum: data?.nextPage
      })
    }
    setState({
      boxItemLoading: false
    })
  }
  const onItemClick = (data: any) => {
    navigate(`/detail/${data.id}`)
  }
  const onSideChange = async (boxType: string) => {
    const border = defaultActiveKey === boxType ? '0 0 0 16px' : '16px 0 0 16px';
    setState({
      activeKey: boxType,
      pageNum: 1,
      boxItemList: [],
      border
    });
    if (boxType === defaultActiveKey) {
      await getRecommendBoxItemList({
        needLoading: true,
        pageNum: 1,
        loadMore: false,
      });
    } else {
      await getBoxItemListByType({
        boxType,
        needLoading: true,
        pageNum: 1,
        loadMore: false,
      });
    }
  }
  const loadMore = async () => {
    if (state.activeKey === defaultActiveKey) {
      await getRecommendBoxItemList({
        needLoading: false,
        pageNum: state.pageNum,
        loadMore: true,
      })
    } else {
      await getBoxItemListByType({
        boxType: state.activeKey,
        needLoading: true,
        pageNum: state.pageNum,
        loadMore: true,
      });
    }
  }

  useEffect(() => {
    functions.forEach((item: any) => {
      getData(item.fn, item.key, item.args)
    });
    getRecommendBoxItemList({
      needLoading: true,
      pageNum: 1,
      loadMore: false,
    });
  }, [])

  useEffect(() => {
    if (!state.boxItemLoading) {
      const sideActiveDom = document.querySelector('.adm-side-bar-item.adm-side-bar-item-active');
      const sideBottom = sideActiveDom?.getBoundingClientRect().bottom;
      const mainBottom = mainRef.current?.getBoundingClientRect().bottom;
      if (parseInt(String(sideBottom)) === parseInt(mainBottom)) {
        setState({
          border: "16px 0 0 0"
        })
      }
    }
  }, [state.boxItemLoading])

  const SIDE_WIDTH = 119;
  const { margin, number } = useDynamicItemHooks(SIDE_WIDTH);
  const padding = (v) => state.boxItemList.length <= number ? v : margin;

  return (
    <div className="list-wrapper">
      {
        state.loading ? <Loading fullscreen /> :
          <>
            <Swiper dataSource={state.banner} />
            <div className="container">
              <div className="side">
                <SideBar
                  activeKey={state.activeKey}
                  onChange={onSideChange}
                  style={{"--background-color": "transparent"}}
                >
                  {state.boxType.map(item => (
                    <SideBar.Item key={item.key} title={item.title} />
                  ))}
                </SideBar>
              </div>
              <div style={{
                borderRadius: state.border,
                paddingLeft: padding(12),
                paddingRight: padding(12)
              }} className="main-wrapper" ref={mainRef}>
                <div className="main">
                  {
                    state.boxItemLoading ? <Loading /> :
                      <>
                        {
                          state.boxItemList.map((item, idx) => (
                            <ItemCard
                              className={cn('card', {
                                'margin-none': (idx + 1) % number === 0
                              })}
                              dataInfo={item}
                              onClick={onItemClick}
                              key={idx}
                              style={{
                                marginRight: padding(14),
                              }}
                            />
                          ))
                        }
                      </>
                  }
                </div>
                {
                  !state.boxItemLoading &&
                  <InfiniteScroll loadMore={loadMore} hasMore={state.hasMore} threshold={50}/>
                }
              </div>
            </div>
          </>
      }
    </div>
  )

}

export default List;