import React from "react";
import { useTranslation } from "react-i18next";

import './item-in-box.scss';

interface IProps {
	note: string
	show: boolean
}

const ItemInBox = (props: IProps) => {
	const { t } = useTranslation();

	if (!props.show) {
		return null;
	}

	return (
		<>
			<div className="item-in-box">
				<div className="left" />
				<div className="content">{t('bb.items-in-the-box')}</div>
				<div className="right" />
			</div>
			<p className="item-in-box-note">{props.note}</p>
		</>
	)

}

export default ItemInBox;