import axios, { CreateAxiosDefaults, AxiosResponse } from "axios";
import { Toast } from "antd-mobile";
import { ACCESS_TOKEN, ErrorCode } from "@/interfaces";
import i18n from "@/i18n";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const config = {
	baseURL: process.env.NODE_ENV !== 'development' ? 'https://apitest.pixibox.tech' : '',
	timeout: 10000,
} as CreateAxiosDefaults

const request = axios.create(config);

const isValidResponse = (code: number) => code === 0;

const errorCodeHandler = (data: any, extra?: any): Promise<AxiosResponse> => {
	let message = i18n['t']('bb.system-busy');

	return new Promise((resolve, reject) => {
		if (isValidResponse(data.code)) {
			resolve(data);
		} else if (extra.hideToast) {
			reject(data);
		} else {
			switch (data.code) {
				case ErrorCode.LOGIN_EXPIRED:
					message = null;
					window.location.replace("/#/login");
					break;
				case ErrorCode.BALANCE_INSUFFICIENT:
					message = null;
					window.location.replace("/#/top-up");
					break;
				case ErrorCode.PASSWORD_ERROR:
					message = i18n['t']('bb.password-error');
					break;
				case ErrorCode.USER_NOT_EXIST:
					message = i18n['t']('bb.user-not-exist');
					break;
				case ErrorCode.CODE_EXPIRED:
					message = i18n['t']('bb.code-expired');
					break;
			}
			if (message) {
				Toast.show({
					content: message,
					maskClickable: false,
				});
			}
			reject(data);
		}
	})
}

// Interceptors
request.interceptors.request.use(
	(config) => {

		if (config.data instanceof FormData) {
			return config;
		}

		// 固定传参
		const payload = {
			sysCode: "pixibox",
			appType: "h5",
			countryCode: "mexico",
			language: i18n.language
		}

		config.data = {
			...payload,
			...config.data
		}
		if (config.headers['access-token']) {
			config.data = {
				...payload,
				...config.data,
				accessToken: localStorage.getItem(ACCESS_TOKEN)
			}
		}
		return config;
	},
	(error): any => {
		return Promise.reject(error);
	}
);

request.interceptors.response.use(
	response => {
		const { data, config } = response;
		return errorCodeHandler(data, config.headers);
	}, error => {
		let msg = i18n['t']('bb.system-busy');
		let { message } = error;
		if (message.includes("timeout")) {
			msg = i18n['t']('bb.request-is-timeout');
		}

		Toast.show({
			content: msg,
			maskClickable: false,
		})
		return Promise.reject(error);
	}
);

export default request;