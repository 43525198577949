import React  from "react";
import { Ellipsis, Image } from "antd-mobile";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import { BoxStatusEnum, DeliveryStatus } from "@/interfaces";
import './common.scss';
import Coin from "@/components/Coin";

interface IProps {
  item: any
  type: BoxStatusEnum
  onOpenBox?: (args: any) => void
}

const Common = (props: IProps) => {
  const { t } = useTranslation();
  const { item, type, onOpenBox } = props;

  const deliveredTitleMap = {
    [DeliveryStatus.selectedDelivered]: t('bb.selected-delivery'),
    [DeliveryStatus.delivered]: t('bb.delivered'),
    [DeliveryStatus.signed]: t('bb.signed')
  }

  const renderTitle = (status: DeliveryStatus) => {
    switch(type) {
      case BoxStatusEnum.recycled:
        return t('bb.recycled');
      case BoxStatusEnum.waitOpen:
        return t('bb.pending-open-box');
      case BoxStatusEnum.delivered:
        return [
          t('bb.delivered'),
          <span className="sub-title" key={status}>{deliveredTitleMap[status]}...</span>
        ];
      case BoxStatusEnum.waitDeliver:
        return t('bb.pending-deliver');
      default:
        return '';
    }
  }

  return (
    <div className="common-items-wrapper">
      <p className="title">{renderTitle(item.deliveryStatus)}</p>
      <div className="item-container">
        <div className="item-info-wrapper">
          <Image src={item.pic} width={80} height={80} />
          <div className="item-info">
            <Ellipsis className="item-name" content={item.name} />
            <p className="item-price item-align-center">{t('bb.price')}:&nbsp;<Coin amount={item.price ?? 0} /></p>
            {
              type === BoxStatusEnum.delivered &&
              <p className="item-align-center">{t('bb.freight')}:&nbsp;<Coin amount={item.freight ?? 0} /></p>
            }
          </div>
        </div>
        {
          type === BoxStatusEnum.waitOpen &&
          <Button
            color="warning"
            className="open-btn"
            onClick={() => onOpenBox(item)}
          >
            {t('bb.open-box')}
          </Button>
        }
      </div>
      {
        (type === BoxStatusEnum.delivered || type === BoxStatusEnum.waitDeliver)?
        <div className="receive-info-area">
          <div>
            <p className="deliver-info">{t('bb.consignee')}: {item.receiveName}</p>
            <p className="deliver-info">{t('bb.address')}: {item.receiveAddress}</p>
            {
              item.receiveMobile ?
                <p className="deliver-info">{t('bb.phone-number')}: {item.receiveMobile}</p> :
                <p className="deliver-info">{t('bb.email')}: {item.receiveEmail}</p>
            }
          </div>
          <div>
            <p className="deliver-info">{t('bb.order-time')}: {item.orderTime}</p>
            <p className="deliver-info">{t('bb.order-number')}: {item.orderNo}</p>
          </div>
        </div> :
        <>
          <p className="item-time">{t('bb.order-time')}: {item.orderTime}</p>
          <p className="item-order-id">{t('bb.order-number')}: {item.orderNo}</p>
        </>
      }
    </div>
  )
}

export default Common;