import React from "react";
import Common from "./Common";
import Empty from "@/components/Empty";

interface IProps {
  dataSource: any[]
  onCancelOrder?: (arg: string) => void
  onPayOrder?: (arg: string) => void
  onTimeOut?: () => void
  cancelOrderLoading?: any
}

const WaitPay = (props: IProps) => {
  const { dataSource, ...others } = props;

  return (
    <>
      {
        dataSource.length === 0 ?
          <Empty /> :
          <div className="wait-pay-wrapper">
            {
              dataSource.map(item => (
                <Common key={item.id} item={item} type="wait-pay" {...others} />
              ))
            }
          </div>
      }
    </>
  )
}

export default WaitPay;