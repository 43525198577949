import React, { useEffect, useRef } from "react";
import { AddIcon, LinkIcon, LocationIcon } from "@/components/SvgIcon";
import { Image, Toast } from "antd-mobile";
import Button from "@/components/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './index.scss';

import { useSetState } from "ahooks";
import { createFreight, deliveryCount, payFreight } from "@/service/order";
import { getDefaultAddress } from "@/service/address";
import PaymentModal from "@/bizComponents/PaymentModal";
import { CHECKED_ADDRESS_ITEM_KEY, CHECKED_PRODUCT_LIST_KEY } from "@/interfaces";
import Empty from "@/components/Empty";
import Coin from "@/components/Coin";
import { calcBuyBtnAmount } from "@/utils/biz";
import { BalanceState, useBalanceStore } from "@/store";
import { decrypt, encrypt } from "@/utils/util";

const SelectAddress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const balance = useBalanceStore((state: BalanceState) => state.balance);
  const fetchBalance = useBalanceStore((state: BalanceState) => state.fetch);
  const itemsInSession = decrypt(sessionStorage.getItem(CHECKED_PRODUCT_LIST_KEY), null);
  const addressInSession = decrypt(sessionStorage.getItem(CHECKED_ADDRESS_ITEM_KEY), null);
  const addressAreaRef = useRef(null);
  const freightRef = useRef(null);
  const [state, setState] = useSetState({
    items: itemsInSession || [],
    freight: 0,
    selectedAddress: {
      receiveName: undefined,
      receiveMobile: undefined,
      receiveAddress: undefined
    },
    payVisible: false,
    orderNo: undefined,
    leftTime: 0,
    freightLoading: false,
    maxHeight: '',
  })

  const onAddressAdd = () => {
    navigate('/address-box')
  }
  const getFreight = async () => {
    const idList = state.items.map(d => d.id);
    const res = await deliveryCount(idList);
    if (res?.data) {
      setState({
        freight: res?.data?.deliveryTotalAmount
      })
    }
  }
  const getDefaultAdd = async () => {
    const res = await getDefaultAddress();
    setState({
      selectedAddress: res?.data
    })
    sessionStorage.setItem(CHECKED_ADDRESS_ITEM_KEY, encrypt(res?.data));
  }
  const onPay = async () => {
    const idList = state.items.map(d => d.id);
    const res = await createFreight(idList);
    if (res?.data) {
      const { orderNo, expireTime } = res.data;
      fetchBalance();
      setState({
        payVisible: true,
        orderNo,
        leftTime: expireTime
      })
    }
  }
  const onPayFreight = async () => {
    setState({
      freightLoading: true
    })
    const differencePrice = calcBuyBtnAmount(balance, state.freight);

    if (differencePrice) {
      navigate(`/top-up?v=${differencePrice}`);
      return;
    }
    const res = await payFreight({
      orderNo: state.orderNo,
      ...state.selectedAddress,
    });
    if (res?.code === 0) {
      Toast.show({
        content: t('bb.payment-successfully'),
        duration: 1000,
        afterClose: () => {
          sessionStorage.removeItem(CHECKED_PRODUCT_LIST_KEY);
          sessionStorage.removeItem(CHECKED_ADDRESS_ITEM_KEY);
          navigate(-1)
          setState({
            freightLoading: false
          })
        }
      })
    } else {
      setState({
        freightLoading: false
      })
    }
  }
  const calcScrollHeight = () => {
    const ADDRESS_AREA_HEIGHT = addressAreaRef.current?.getBoundingClientRect().height || 0;
    const NAV_HEIGHT = 44;
    const FREIGHT_HEIGHT = freightRef.current?.getBoundingClientRect().height || 0;
    const BTN_HEIGHT = 50;
    const MARGIN = 29 + 16 + 16;
    const WINDOW_HEIGHT = window.innerHeight;

    const V = NAV_HEIGHT + ADDRESS_AREA_HEIGHT + FREIGHT_HEIGHT + BTN_HEIGHT + MARGIN;

    setState({
      maxHeight: `${WINDOW_HEIGHT - V}px`,
    })
  }

  useEffect(() => {
    calcScrollHeight();
  }, [addressAreaRef, freightRef, state.freight])

  useEffect(() => {
    if (itemsInSession !== null) {
      getFreight();
    }
    if (addressInSession === null) {
      getDefaultAdd();
    } else {
      setState({
        selectedAddress: addressInSession
      })
    }
  }, [])

  if (itemsInSession === null) {
    return <Empty />
  }

  return (
    <div className="select-address-wrapper">
      {
        state.selectedAddress.receiveName ?
          <div ref={addressAreaRef} className="have-address-info" onClick={onAddressAdd}>
            <div className="address-left">
              <LocationIcon style={{flex: 'none'}}/>
              <div className="address-left-info">
                <p className="base-info">
                  <span>{state.selectedAddress.receiveName}</span>
                  <span>{state.selectedAddress.receiveMobile}</span>
                </p>
                <p>{state.selectedAddress.receiveAddress}</p>
              </div>
            </div>
            <LinkIcon style={{flex: 'none'}}/>
          </div> :
          <div ref={addressAreaRef} className="address-add-info" onClick={onAddressAdd}>
            <AddIcon />
            <p className="address-add-text">{t('bb.add-address')}</p>
          </div>
      }
      <div className="select-address-item-scroller" style={{maxHeight: state.maxHeight}}>
        {
          state.items.map((item, idx) => (
            <div className={`select-address-item ${state.items.length - 1 === idx ? 'last' : ''}`} key={item.id}>
              <div className="select-address-item-info">
                <Image src={item.pic} width={80} height={80}/>
                <p>{item.name}</p>
              </div>
              <p className="select-address-order-no">{t('bb.order-number')}: {item.orderNo}</p>
            </div>
          ))
        }
      </div>
      {
        state.freight > 0 &&
        <p ref={freightRef} className="select-address-freight">{t('bb.freight')}&nbsp;<Coin amount={state.freight} /></p>
      }
      <Button
        size="large"
        fixed
        wrapperPadding={"0 24px"}
        onClick={onPay}
        disabled={!state.selectedAddress.receiveName}
      >
        {t('bb.pay-now')}
      </Button>
      <PaymentModal
        visible={state.payVisible}
        onClose={() => setState({ payVisible: false })}
        type="freight"
        price={state.freight}
        onPay={onPayFreight}
        leftTime={state.leftTime}
        loading={state.freightLoading}
      />
    </div>
  )
}

export default SelectAddress;