import React, { useEffect } from 'react';
import Swiper from '@/components/Swiper';
import CategoryType from './components/CategoryType';
import Tabs from '@/components/Tabs';
import { useNavigate } from 'react-router-dom';
import Loading from "@/components/Loading";
import { useSetState } from 'ahooks';
import { getBanner } from "@/service/common";
import { getHorseRaceLamp, getIndexBoxType, getNavigation } from "@/service/dict";
import { useTranslation } from "react-i18next";
import { getBoxListByType, getRecommendBoxList } from "@/service/box";
import { BannerPositionEnum, IState, LinkTypeEnum } from "@/interfaces";
import { bannerListAdapt, boxTypeListAdapt, boxItemListAdapt } from "@/utils/biz";
import Items from "@/pages/Home/components/Items";
import { removeRepeat } from "@/utils/util";
import Marquee from "@/components/Marquee";
import './index.scss';

const functions = [
	{
		fn: getBanner,
		key: 'banner',
		args: BannerPositionEnum.home
	},
	{
		fn: getNavigation,
		key: 'nav'
	},
	{
		fn: getHorseRaceLamp,
		key: 'horseLamp'
	},
	{
		fn: getIndexBoxType,
		key: 'boxTypeList'
	},
];
const defaultActiveKey = 'recommendation';

interface IParamsRecommend {
	needLoading: boolean
	pageNum: number
	loadMore: boolean
}
interface IParamsNormal extends IParamsRecommend {
	boxType: string
}

const Home = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [state, setState] = useSetState<IState>({
		nav: [],
		banner: [],
		boxTypeList: [],
		horseLamp: [],
		boxItemList: [],
		loading: false,
		activeKey: defaultActiveKey,
		boxItemLoading: false,
		pageNum: 1,
		pageSize: 10
	});

	const getData = async (fn: Function, type: string, args?: any) => {
		setState({
			loading: true
		})
		const { data } = await fn(args) || {data: "[]"};
		try {
			let list: any[] = typeof data === 'string' ? (JSON.parse(data || "[]") || []) : data;
			if (type === 'boxTypeList') {
				list = boxTypeListAdapt(list, t);
			}
			if (type === 'banner') {
				list = bannerListAdapt(list);
			}
			setState({
				[type]: list
			})
		} catch (e){
			console.log("err", e)
		} finally {
			setState({
				loading: false
			})
		}
	}

	const getRecommendBoxItemList = async (params: IParamsRecommend) => {
		const { needLoading, pageNum, loadMore } = params;
		if (needLoading) {
			setState({
				boxItemLoading: true
			})
		}
		const res = await getRecommendBoxList({
			pageNum,
			pageSize: state.pageSize
		});
		if (res) {
			const { data } = res;
			const formattedData = boxItemListAdapt(data.list);
			const List = loadMore ? [...state.boxItemList, ...formattedData] : formattedData;
			setState({
				boxItemList: removeRepeat(List),
				hasMore: !data?.isLastPage,
				pageNum: data?.nextPage
			})
		}
		setState({
			boxItemLoading: false
		})
	}

	const getBoxItemListByType = async (params: IParamsNormal) => {
		const { boxType, needLoading, pageNum, loadMore } = params;

		if (needLoading) {
			setState({
				boxItemLoading: true
			})
		}
		const res = await getBoxListByType({
			boxType,
			pageNum,
			pageSize: state.pageSize
		});
		if (res) {
			const { data } = res;
			const formattedData = boxItemListAdapt(data.list);
			const List = loadMore ? [...state.boxItemList, ...formattedData] : formattedData;
			setState({
				boxItemList: removeRepeat(List),
				hasMore: !data?.isLastPage,
				pageNum: data?.nextPage
			})
		}
		setState({
			boxItemLoading: false
		})
	}

	const onItemClick = (data: any) => {
		navigate(`/detail/${data.id}`)
	}

	const onCategoryChange = (target: any) => {
		if (target.logoLink) {
			if (target.linkEnv === LinkTypeEnum.inner) {
				navigate(target.logoLink);
			} else {
				window.open(target.logoLink, "new_open")
			}
		}
	}

	const onTabChange = async (boxType: string) => {
		setState({
			activeKey: boxType,
			pageNum: 1,
			boxItemList: []
		});
		if (boxType === defaultActiveKey) {
			await getRecommendBoxItemList({
				needLoading: true,
				pageNum: 1,
				loadMore: false,
			});
		} else {
			await getBoxItemListByType({
				boxType,
				needLoading: true,
				pageNum: 1,
				loadMore: false,
			});
		}
	}

	const loadMore = async () => {
		if (state.activeKey === defaultActiveKey) {
			await getRecommendBoxItemList({
				needLoading: false,
				pageNum: state.pageNum,
				loadMore: true,
			})
		} else {
			await getBoxItemListByType({
				boxType: state.activeKey,
				needLoading: true,
				pageNum: state.pageNum,
				loadMore: true,
			});
		}
	}

	useEffect(() => {
		functions.forEach((item: any) => {
			getData(item.fn, item.key, item?.args)
		});
		getRecommendBoxItemList({
			needLoading: true,
			pageNum: 1,
			loadMore: false,
		});
	}, [])

	return (
		<div className="home-wrapper">
			{
				state.loading ?
					<Loading fullscreen /> :
					<>
						<Swiper dataSource={state.banner} />
						<Marquee dataSource={state.nav} />
						<CategoryType dataSource={state.horseLamp} onChange={onCategoryChange} />
						<Tabs
							dataSource={state.boxTypeList}
							onChange={onTabChange}
							activeKey={state.activeKey}
						/>
						<Items
							loading={state.boxItemLoading}
							dataSource={state.boxItemList}
							onItemClick={onItemClick}
							loadMore={loadMore}
							hasMore={state.hasMore}
						/>
					</>
			}
		</div>
	)
}

export default Home;