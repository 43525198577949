import React from "react";
import { useTranslation } from "react-i18next";

const Rule = () => {
	const { t } = useTranslation();

	return (
		<div style={{padding: "0 18px", fontSize: 16}}>
			<div dangerouslySetInnerHTML={{__html: t('bb.rule-content')}} />
		</div>
	)
}

export default Rule;