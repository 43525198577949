import React, { useEffect, useState } from "react";
import TabBar from "../TabBar";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import routes from "@/routes";
import { FloatingBubble, Image } from 'antd-mobile';
import './index.scss';
import { IndexPopupState, useIndexPopupStore } from "@/store";
import { LinkTypeEnum } from "@/interfaces";

interface IProps {
  children: any
}

const showTabBarList = ['/', '/mine'];
const hideLayoutList = ['/activity-a', '/activity-b', '/activity-c'];

const Layout = (props: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const indexPopup = useIndexPopupStore((state: IndexPopupState) => state.indexPopup);
  const indexPopupFetch = useIndexPopupStore((state: IndexPopupState) => state.fetch);
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);

  const showTabBar = () => showTabBarList.includes(location.pathname);
  const hideLayout = () => hideLayoutList.includes(location.pathname);

  const onBubbleClick = () => {
    if (indexPopup.iconLinkEnv === LinkTypeEnum.inner) {
      if (location.pathname !== indexPopup.iconLink) {
        navigate(indexPopup.iconLink)
      }
    } else {
      window.open(indexPopup.iconLink, "new_open")
    }
  }

  useEffect(() => {
    const navEle = document.querySelector('.bb-nav-bar-id');
    if (visible !== !!navEle) {
      setVisible(!!navEle)
    }
  }, [location.pathname])

  const resizeUpdate = (e: any) => {
    let h = e.target.innerHeight;
    setHeight(h);
  }

  useEffect(() => {
    let h = window.innerHeight;
    setHeight(h);
    window.addEventListener('resize', resizeUpdate);
    if (!hideLayout) {
      indexPopupFetch();
    }
    return () => {
      window.removeEventListener('resize', resizeUpdate);
    }
  }, [])

  if (hideLayout) {
    return children;
  }

  return (
    <div className="bb-layout" style={{minHeight: height}}>
      <div className={`bb-layout-children ${visible ? '' : 'nav-bar-hided'}`}>
        <Nav routes={routes} />
        { children }
      </div>
      { showTabBar() && <TabBar /> }
      {
        indexPopup.showIcon &&
        <FloatingBubble
          axis='xy'
          magnetic='x'
          style={{
            '--initial-position-bottom': '64px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
            '--z-index': '20'
          }}
          onClick={onBubbleClick}
        >
          <Image src={indexPopup.iconImg} style={{borderRadius: '100%', '--width': '48px', '--height': '48px'}}/>
        </FloatingBubble>
      }
    </div>
  )

}

export default Layout;