import React from "react";
import { Button as AdmButton, ButtonProps } from "antd-mobile";
import cn from 'classnames';

import './index.scss';

interface IProps extends ButtonProps {
  wrapperPadding?: string | number
  fixed?: boolean
  wrapperClassName?: string
}

const Button = (props: IProps) => {
  const { className, children, wrapperPadding, fixed, wrapperClassName, ...others } = props;

  if (wrapperPadding) {
    return (
      <div className={cn("bb-button-container", wrapperClassName, {fixed})} style={{padding: wrapperPadding}}>
        <AdmButton className={cn('bb-button', className)} {...others}>
          {children}
        </AdmButton>
      </div>
    )
  }

  return (
    <AdmButton className={cn('bb-button', className)} {...others}>
      {children}
    </AdmButton>
  )

}

export default Button;