import {
	agreementContentEN,
	privacyContentEN, ruleContentEN,
} from "@/locales/largeContent";

export default {
	translation: {
		"bb.start-login": "Login",
		"bb.please-input-email-or-phone-number": "Please enter your email or phone number",
		"bb.email-or-phone-number-ten-digits": "Email/phone number (10 digits)",
		"bb.next-step": "Next",
		"bb.please-input-verification-code": "Please enter your verification code",
		"bb.please-input-password": "Please enter your password",
		"bb.get-verification-code": "Get",
		"bb.resend": "Resend",
		"bb.verification-code-input": "Enter the verification code",
		"bb.password-input": "Enter the password",
		"bb.agree-agreement-and-privacy": "I have read and accept the <0>{{agreement}}</0> and <1>{{privacy}}</1>",
		"bb.agree": "I have read and accept the ",
		"bb.agreement": "\"Service Agreement\"",
		"bb.agreement-title": "Service Agreement",
		"bb.privacy": "\"Privacy Policy\"",
		"bb.privacy-title": "Privacy Policy",
		"bb.set-password": "Set password",
		"bb.set-password-six-digits": "Set 6-digit password",
		"bb.continue": "Continue",
		"bb.skip": "Skip",
		"bb.box": "Box",
		"bb.mine": "Me",
		"bb.all-boxes": "All boxes",
		"bb.top-up": "Recharge",
		"bb.rules-of-the-game": "Game rules",
		"bb.recommendation": "Recommendation",
		"bb.box-detail": "Box details",
		"bb.buy": "Buy",
		"bb.try-it-for-free": "Try for free",
		"bb.items-in-the-box": "Items in this box",
		"bb.open-box-tip": "Note: One of the products will be found when you open the box.",
		"bb.confirm-pay": "Confirm payment",
		"bb.box-price": "Box price",
		"bb.the-order-closes-in-time": "The order will be closed after {{time}}",
		"bb.freight": "Freight",
		"bb.pay": "Pay",
		"bb.pay-success": "Successful payment",
		"bb.open-case": "Open",
		"bb.open-it-later": "Open later",
		"bb.open-it-now": "Open now",
		"bb.if-later-open-in-my-box": "Choose \"open later\", and you can go to \"My box\" to open it",
		"bb.deliver": "Deliver",
		"bb.recycle-some-money": "Recover(<0>{{amount}}</0>)",
		"bb.one-more-time": "Again",
		"bb.value-of-item": "Product value",
		"bb.confirm-order": "Confirm the order",
		"bb.pay-now": "Pay immediately",
		"bb.recycle-some-items": "Recover {{number}} products",
		"bb.recycle-price": "Recovery amount",
		"bb.confirm-recycle": "Confirm recovery",
		"bb.my-orders": "My Order",
		"bb.my-boxes": "My box",
		"bb.address": "Delivery address",
		"bb.about-us": "About Us",
		"bb.contact-customer-service": "Contact customer service",
		"bb.faq": "Common problem",
		"bb.customer-agreement": "",
		"bb.customer-service-feedback": "Feedback",
		"bb.setting": "Setting",
		"bb.transactions": "Transaction Record",
		"bb.pending-pay": "Pending payment",
		"bb.done": "Completed",
		"bb.closed": "Closed",
		"bb.the-order-closes-in-minutes": "The order will be closed after {{time}}",
		"bb.cancel-order": "Cancel order",
		"bb.payment-now": "Pay now",
		"bb.close": "Close",
		"bb.transaction-done": "Transaction completed",
		"bb.pending-open-box": "Pending to open",
		"bb.pending-pick-up": "Pending to recovery",
		"bb.recycled": "Recovered",
		"bb.pending-deliver": "Pending to send",
		"bb.delivered": "Delivered",
		"bb.order-time": "Order time",
		"bb.recycle": "Recovery",
		"bb.open-box": "Open",
		"bb.price": "Price",
		"bb.exchange-amount": "Recovery amount",
		"bb.your-items-prepare-deliver": "Your product is in the process of delivering...",
		"bb.your-items-delivered": "Your product has been delivered, please wait to update the delivery status...",
		"bb.your-items-signed": "Your product has been delivered...",
		"bb.box-number": "Box number",
		"bb.withdraw": "Withdraw",
		"bb.box-buy": "Blind box purchase",
		"bb.top-up-amount": "Recharge amount",
		"bb.please-input-top-up-amount": "Please enter the amount you want to recharge.",
		"bb.contact-us": "Contact us",
		"bb.email": "Email",
		"bb.email-require": "*Email",
		"bb.work-time": "Operating hours",
		"bb.problem-feedback": "Feedback",
		"bb.please-input-suggestion-or-problem": "Please fill in suggestions or questions",
		"bb.upload-screenshot": "Upload screenshot",
		"bb.your-email-or-phone": "Your email/phone number",
		"bb.submit": "Submit",
		"bb.add-address": "Add delivery address",
		"bb.confirm": "Confirm",
		"bb.add-address-2": "Add delivery address",
		"bb.modify-address": "Modify delivery address",
		"bb.full-name-require": "*Name (full name)",
		"bb.phone-number-require": "*Phone number",
		"bb.phone-number": "Phone number",
		"bb.full-address-require": "*Detailed address",
		"bb.postal-code": "Post code",
		"bb.version": "Version",
		"bb.logout": "Log out",
		"bb.withdraw-record": "Withdrawals record",
		"bb.withdraw-amount-available": "Amount currently available for withdrawal",
		"bb.withdraw-amount": "Withdrawal amount",
		"bb.your-phone-number": "Your phone number",
		"bb.your-email": "Your email",
		"bb.apply-withdraw": "Apply for withdrawal",
		"bb.withdraw-all-amount": "Withdraw all balance",
		"bb.withdraw-result": "Withdrawal result",
		"bb.withdraw-apply-success": "The withdrawal request is successful, please wait for customer service to process.",
		"bb.apply-time": "Application time",
		"bb.applied": "Applied",
		"bb.processing": "Processing",
		"bb.withdraw-success": "Successful withdrawal",
		"bb.withdraw-failed": "Withdrawal failed",
		"bb.process-time": "Processing time",
		"bb.home": "Home",
		"bb.address-detail": "Delivery address details",
		"bb.order-detail": "Order details",
		"bb.login": "Log in",
		"bb.phone-or-email": "Email/Phone number",
		"bb.consignee": "Receiver",
		"bb.order-number": "Order number",
		"bb.selected-delivery": "Selected to delivery",
		"bb.signed": "Have been received",
		"bb.set-default-address": "Set as default address",
		"bb.system-busy": "System is busy ",
		"bb.system-error": "System error",
		"bb.balance-insufficient": "Insufficient balance available",
		"bb.login-expired": "Login has expired, please login again",
		"bb.user-not-exist": "User not found",
		"bb.password-error": "Incorrect login password",
		"bb.code-expired": "Verification code has expired",
		"bb.email-send-failed": "Email sending failed, please try again",
		"bb.sms-send-failed": "SMS sending failed, please try again",
		"bb.select-country-title": "Please select your country and language",
		"bb.privacy-content": privacyContentEN,
		"bb.agreement-content": agreementContentEN,
		"bb.change-lang": "Change language",
		"bb.cancel-order-alert-title": "Are you sure to cancel this order?",
		"bb.rule-content": ruleContentEN,
		"bb.input-amount-wrong": "Incorrect amount entered",
		"bb.input-format-wrong": "wrong format",
		"bb.please-check-agreement": "Please read and agree the agreement first",
		"bb.set-successfully": "Set successfully",
		"bb.add-successfully": "Added successfully",
		"bb.deleted-successfully": "Deleted successfully",
		"bb.update-successfully": "Update successfully",
		"bb.payment-successfully": "Payment successful",
		"bb.recover-successfully": "Recovered successfully",
		"bb.cancel-successfully": "Canceled successfully",
		"bb.send-successfully": "Successful delivery",
		"bb.the-picture-is-too-large": "The picture is too large",
		"bb.feedback-successfully": "Successful feedback",
		"bb.password-format-wrong": "Password format error",
		"bb.request-is-timeout": "The request has timed out.",
		"bb.yes": "Yes",
		"bb.no": "No",
		"bb.number-of-products": " products",
		"bb.pay-with": "Pay with {{type}}",
		"bb.minimum-recharge": `Minimum recharge is $`
	}
}