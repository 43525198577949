import React, { useEffect } from "react";
import SwipeAction from "@/bizComponents/SwipeAction";
import Button from "@/components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { AddIcon } from "@/components/SvgIcon";
import { useTranslation } from "react-i18next";
import { deleteAddress, getAddressList } from "@/service/address";
import { useSetState } from "ahooks";
import { CHECKED_ADDRESS_ITEM_KEY, CHECKED_PRODUCT_LIST_KEY, IState } from "@/interfaces";
import Loading from "@/components/Loading";
import { InfiniteScroll, Toast } from "antd-mobile";
import './index.scss';
import { decrypt, encrypt } from "@/utils/util";

const Address = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const ifFromBox = decrypt(sessionStorage.getItem(CHECKED_PRODUCT_LIST_KEY), null) !== null;
  const [state, setState] = useSetState<IState>({
    addressList: [],
    loading: false,
    selectedAddress: {
      id: null
    },
    pageNum: 1,
    pageSize: 10,
    hasMore: false,
  })

  const onDeleteAddress = async (data: any) => {
    const res = await deleteAddress(data.id);
    const sessionItem = decrypt(sessionStorage.getItem(CHECKED_ADDRESS_ITEM_KEY), {});
    if (res?.code === 0) {
      if (data.id === sessionItem.id) {
        sessionStorage.removeItem(CHECKED_ADDRESS_ITEM_KEY);
      }
      Toast.show({
        content: t('bb.deleted-successfully'),
        duration: 1000,
        afterClose: () => {
          getAddress();
        }
      })
    }
  }
  const onAddAddress = () => {
    navigate('/address/add');
  }
  const onEditAddress = (item: {id: string}) => {
    navigate(`/address/${item.id}`)
  }
  const getAddress = async () => {
    setState({ loading: true });
    const res = await getAddressList({
      pageNum: 1,
      pageSize: state.pageSize
    });
    if (res?.data) {
      setState({
        addressList: res?.data?.list,
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
    setState({ loading: false });
  }
  const onCheckedAddress = (selectedAddress) => {
    setState({
      selectedAddress
    })
  }
  const onSelectAddressConfirm = () => {
    sessionStorage.setItem(CHECKED_ADDRESS_ITEM_KEY, encrypt(state.selectedAddress))
    navigate(-1);
  }
  const loadMore = async () => {
    const res = await getAddressList({
      pageNum: state.pageNum,
      pageSize: state.pageSize
    });
    if (res?.data) {
      const append = res?.data?.list;
      setState({
        addressList: [...state.addressList, ...append],
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
  }

  useEffect(() => {
    getAddress();
  }, [])

  return (
    <div className="address-wrapper">
      <div className="add-container" onClick={onAddAddress}>
        <AddIcon className="add-icon" />
        <span>{t('bb.add-address')}</span>
      </div>
      {
        state.loading ? <Loading /> :
          <>
            <SwipeAction
              dataSource={state.addressList}
              onDelete={onDeleteAddress}
              onEdit={onEditAddress}
              showCheckbox={location.pathname === '/address-box' && ifFromBox}
              onChecked={onCheckedAddress}
            />
            <InfiniteScroll loadMore={loadMore} hasMore={state.hasMore} threshold={50}/>
          </>
      }
      {
        location.pathname === '/address-box' && ifFromBox &&
        <Button
          fixed
          wrapperPadding={"15px 24px 0 24px"}
          size="large"
          onClick={onSelectAddressConfirm}
          disabled={!state.selectedAddress.id}
          wrapperClassName="select-address-btn-wrapper"
        >
          {t('bb.confirm')}
        </Button>
      }
    </div>
  )

}

export default Address;