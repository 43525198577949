import React from "react";
import PaymentModal from "@/bizComponents/PaymentModal";
// import PaymentResult from "@/bizComponents/PaymentResult";
import OpenBox from "@/bizComponents/OpenBox";
import { useSetState } from "ahooks";
import { payBox } from "@/service/order";
import { useNavigate } from "react-router-dom";

interface IProps {
	visible: boolean
	onOpenAgain: () => void
	onClosePayModal: () => void
	orderNo: string
	boxPrice: string | number
	onCloseOpenBox?: () => void
	afterRecycled?: () => void
	leftTime: number
	onOpenItLater?: () => void
	tryFreeVisible?: boolean
	differencePrice: number
	onPaySuccess?:  () => void
	boxName?: string
	boxImage: string
}

const BoxFromPayToOpen = (props: IProps) => {
	const navigate = useNavigate();
	const {
		visible, onClosePayModal, onOpenAgain,
		orderNo, boxPrice, afterRecycled,
		leftTime, onOpenItLater, onCloseOpenBox,
		tryFreeVisible, differencePrice, onPaySuccess,
		boxName, boxImage
	} = props;

	const [state, setState] = useSetState({
		// payResVisible: false,
		openBoxVisible: false,
		payLoading: false,
	})

	const setOpenBoxVisible = (bool: boolean) => {
		setState({ openBoxVisible: bool });
	}

	const onOneMoreTime = () => {
		setState({
			openBoxVisible: false,
		})
		onOpenAgain && onOpenAgain()
	}

	const onPay = async () => {
		setState({
			payLoading: true
		})
		if (differencePrice) {
			navigate(`/top-up?v=${differencePrice}`);
			return;
		}
		const payBoxRes = await payBox(orderNo);
		if (payBoxRes?.code === 0) {
			setState({
				openBoxVisible: true
			})
			onPaySuccess && onPaySuccess();
		}
		setState({
			payLoading: false
		})
	}

	const closeOpenBox = () => {
		setOpenBoxVisible(false);
		onCloseOpenBox && onCloseOpenBox()
	}

	const onAfterRecycled = () => {
		setOpenBoxVisible(false);
		afterRecycled && afterRecycled();
	}

	const handleOpenItLater = () => {
		setOpenBoxVisible(false);
		onOpenItLater && onOpenItLater()
	}

	return (
		<>
			<PaymentModal
				type="box"
				visible={visible}
				onClose={onClosePayModal}
				onPay={onPay}
				price={boxPrice}
				loading={state.payLoading}
				leftTime={leftTime}
				boxName={boxName}
			/>
			{/*{*/}
			{/*	state.payResVisible &&*/}
			{/*	<PaymentResult*/}
			{/*		onConfirm={() => {*/}
			{/*			setState({*/}
			{/*				payResVisible: false,*/}
			{/*				openBoxVisible: true*/}
			{/*			})*/}
			{/*		}}*/}
			{/*	/>*/}
			{/*}*/}
			{
				(state.openBoxVisible || tryFreeVisible) &&
				<OpenBox
					orderNo={orderNo}
					onOpenedClose={closeOpenBox}
					onOneMoreTime={onOpenAgain ? onOneMoreTime : undefined}
					afterRecycled={onAfterRecycled}
					onOpenItLater={handleOpenItLater}
					ifTryFree={tryFreeVisible}
					boxName={boxName}
					boxImage={boxImage}
				/>
			}
		</>
	)

}

export default BoxFromPayToOpen;