import React, { useEffect, useRef, useState } from "react";
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { useLockScroll } from "@/hooks/use-lock-scroll";
import './index.scss';

interface IProps {
	children: any,
	className?: string
	lock?: boolean
	style?: React.CSSProperties
}

const FullScreenContainer = (props: IProps) => {
	const { children, className, lock = true, style } = props;
	const ref = useRef(null);
	const [height, setHeight] = useState(window.innerHeight);

	useLockScroll(ref, lock);

	const resizeUpdate = (e: any) => {
		let h = e.target.innerHeight;
		setHeight(h);
	}

	useEffect(() => {
		let h = window.innerHeight;
		setHeight(h);
		window.addEventListener('resize', resizeUpdate);

		return () => {
			window.removeEventListener('resize', resizeUpdate);
		}
	}, [])

	const styles = style ? style : {height}

	const dom = (
		<div
			className={cn("bb-full-screen-container", className)}
			ref={ref}
			style={styles}
		>
			<div className="full-screen-children">
				{ children }
			</div>
		</div>
	)

	return createPortal(dom, document.body)
}

export default FullScreenContainer;