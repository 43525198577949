import React from "react";
import Common from "./Common";
import Empty from "@/components/Empty";

interface IProps {
  dataSource: any[]
}

const WaitPay = (props: IProps) => {

  return (
    <>
      {
        props.dataSource.length === 0 ?
          <Empty /> :
          <div className="done-wrapper">
            {
              props.dataSource.map(item => (
                <Common key={item.id} item={item} type="done" />
              ))
            }
          </div>
      }
    </>

  )
}

export default WaitPay;