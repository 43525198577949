import React, { useEffect } from "react";
import { SuccessIcon } from "@/components/SvgIcon";
import FullScreenContainer from "@/components/FullScreenContainer";
import Button from "@/components/Button";

import './index.scss';
import { useTranslation } from "react-i18next";
import { ContactState, useContactStore } from "@/store";

const WithdrawResult = (props) => {
  const { t } = useTranslation();
  const contactInfo = useContactStore((state: ContactState) => state.info);
  const fetch = useContactStore((state: ContactState) => state.fetch);

  useEffect(() => {
    if (!contactInfo.email) {
      fetch()
    }
  }, [contactInfo.email])

  return (
    <FullScreenContainer className="bb-withdraw-result">
      <p className="withdraw-result-title">{t('bb.withdraw-result')}</p>
      <div className="success-feedback">
        <SuccessIcon />
        <p className="tips">{t('bb.withdraw-apply-success')}</p>
      </div>
      <div className="border-line" />
      <div className="contact">
        <p className="title">{t('bb.contact-customer-service')}</p>
        <p className="email">{contactInfo.email}</p>
      </div>
      <Button fixed wrapperPadding={"0 24px"} size="large" onClick={props.onOk}>OK</Button>
    </FullScreenContainer>
  )

}

export default WithdrawResult;