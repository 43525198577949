import React  from "react";
import { Checkbox, Ellipsis, Image } from "antd-mobile";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import { waitPickupBoxAdapt } from "@/utils/biz";
import Empty from "@/components/Empty";
import ItemRecycler from "@/bizComponents/ItemRecycler";
import { useMount, useSetState } from "ahooks";
import { boxRecycleCount } from "@/service/user";
import { CHECKED_ADDRESS_ITEM_KEY, CHECKED_PRODUCT_LIST_KEY, IState } from "@/interfaces";
import { useNavigate } from "react-router-dom";
import './wait-pickup.scss';
import Coin from "@/components/Coin";
import { encrypt } from "@/utils/util";

interface IProps {
  dataSource: any[]
  afterRecycled?: () => void
}

const list = new Map();

const WaitPickup = (props: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dataSource, afterRecycled } = props;
  const [state, setState] = useSetState<IState>({
    recycleVisible: false,
    checkedItemList: [],
    recycleTotalAmount: 0
  })

  const formattedData = waitPickupBoxAdapt(dataSource);

  const onRecycle = () => {
    if (state.checkedItemList.length === 0) {
      return;
    }

    const idList: number[] = state.checkedItemList.map(d => d.id)

    boxRecycleCount(idList).then(res => {
      if (res?.data) {
        setState({
          recycleVisible: true,
          recycleTotalAmount: res?.data?.recycleTotalAmount
        })
      }
    })
  }

  const onCheckItem = (item: any) => {
    if (list.get(item.id)) {
      list.delete(item.id)
    } else {
      list.set(item.id, item)
    }

    const checkedItemList = Array.from(list.values());

    sessionStorage.setItem(CHECKED_PRODUCT_LIST_KEY, encrypt(checkedItemList));

    setState({
      checkedItemList
    })
  }

  const onCloseItemRecycler = () => {
    setState({
      recycleVisible: false
    })
  }

  const clearData = () => {
    list.clear();
    sessionStorage.removeItem(CHECKED_PRODUCT_LIST_KEY);
    sessionStorage.removeItem(CHECKED_ADDRESS_ITEM_KEY);
  }

  useMount(() => {
    clearData();
  })

  return (
    <>
      {
        formattedData.length === 0 ?
          <Empty /> :
          <div className="wait-pickup-wrapper">
            <p className="title">{t('bb.pending-pick-up')}</p>
            {
              formattedData.map(item => (
                <div className="items-wrapper" key={item.id}>
                  <Checkbox className="pickup-checkbox" onChange={() => onCheckItem(item)}>
                    <Image src={item.pic} width={80} height={80} />
                    <div className="item-info">
                      <Ellipsis className="item-name" content={item.name} />
                      <p className="item-price">{t('bb.price')}:&nbsp;<Coin amount={item.price ?? 0} /></p>
                    </div>
                  </Checkbox>
                  <p className="item-time">{t('bb.order-time')}: {item.orderTime}</p>
                  <p className="item-order-id">{t('bb.order-number')}: {item.orderNo}</p>
                </div>
              ))
            }
            <div className="btn-group">
              <Button
                className="recycle-btn"
                size="small"
                fill="outline"
                color="warning"
                onClick={onRecycle}
                disabled={state.checkedItemList.length === 0}
              >
                {t('bb.recycle')}
              </Button>
              <Button
                color="warning"
                size="small"
                onClick={() => navigate('/select-address')}
                disabled={state.checkedItemList.length === 0}
              >
                {t('bb.deliver')}
              </Button>
            </div>
            <ItemRecycler
              recycleTotalAmount={state.recycleTotalAmount}
              dataSource={state.checkedItemList}
              visible={state.recycleVisible}
              onClose={onCloseItemRecycler}
              onAgreementSwitch={(recycleVisible) => setState({ recycleVisible })}
              afterRecycled={afterRecycled}
            />
          </div>
      }
    </>
  )

}

export default WaitPickup;