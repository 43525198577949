import React, { useState } from "react";
import { Ellipsis, Image, Toast } from "antd-mobile";
import Button from "@/components/Button";
import Popup from "@/components/Popup";
import { useTranslation } from "react-i18next";
import AgreementModal from "@/bizComponents/AgreementModal";
import { boxRecycle, IBoxRecycleParam } from "@/service/user";
import './index.scss';
import Coin from "@/components/Coin";

interface IProps {
	visible: boolean
	onClose?: () => void
	dataSource: any[]
	recycleTotalAmount: number
	onAgreementSwitch: (arg: boolean) => void
	afterRecycled?: () => void
}

const ItemRecycler = (props: IProps) => {
	const { t } = useTranslation();
	const { visible, onClose, dataSource, recycleTotalAmount, onAgreementSwitch, afterRecycled } = props;
	const [agreeChecked, setAgreeChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(false);

	const onAgreeChange = () => {
		setAgreeChecked(!agreeChecked);
	}

	const onConfirm = async () => {
		if (!agreeChecked) {
			Toast.show({
				content: t('bb.please-check-agreement'),
				position: 'bottom'
			});
			return;
		}
		setLoading(true);
		const idList: number[] = dataSource.map(d => d.id);
		const payload = {
			boxId: idList,
			recycleCheckAmount: recycleTotalAmount
		}
		const res = await boxRecycle(payload as IBoxRecycleParam);
		if (res?.code === 0) {
			setBtnDisabled(true);
			Toast.show({
				content: t('bb.recover-successfully'),
				duration: 1000,
				afterClose: () => {
					afterRecycled && afterRecycled();
				}
			})
		}
		setLoading(false);
	}

	return (
		<Popup visible={visible} className="bb-item-recycler" onClose={onClose}>
			<div className="bb-item-recycler-children">
				<p className="title">{t('bb.recycle-some-items', {number: dataSource.length})}</p>
				<div className="bb-item-recycler-container">
					{
						dataSource.map((item) => (
							<div className="bb-item-recycler-content" key={item.id}>
								<Image src={item.pic} width={64} height={64} />
								<p>
									<Ellipsis content={item.name} />:&nbsp;
									<Coin amount={item.price} />
								</p>
							</div>
						))
					}
				</div>
				<p className="items-cost">{t('bb.recycle-price')}:&nbsp;<Coin amount={recycleTotalAmount} /></p>
				<Button
					disabled={btnDisabled}
					size="large"
					wrapperPadding="0 24px"
					onClick={onConfirm}
					loading={loading}
				>{t('bb.confirm')}
				</Button>
				<AgreementModal showPrivacy={false} onChecked={onAgreeChange} onAgreementSwitch={onAgreementSwitch}/>
			</div>
		</Popup>
	)

}

export default ItemRecycler;