import React from "react";
import Common from "./Common";
import Empty from "@/components/Empty";
import { BoxStatusEnum } from "@/interfaces";

interface IProps {
  dataSource: any[]
  onOpenBox?: (args: any) => void
}

const WaitOpen = (props: IProps) => {
  const { dataSource, ...others } = props;

  return (
    <>
      {
        dataSource.length === 0 ?
          <Empty /> :
          <div className="wait-open-wrapper">
            {
              dataSource.map(item => (
                <Common
                  item={item}
                  type={BoxStatusEnum.waitOpen}
                  key={item.id}
                  {...others}
                />
              ))
            }
          </div>
      }
    </>
  )

}

export default WaitOpen;