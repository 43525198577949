import React from 'react';
import {APP_LINK} from "@/interfaces";
import './index.scss';

const ActivityA = () => {
  return (
    <div className="activity-a-container">
      <div className="activity-a">
        <a href={APP_LINK} className="link"> </a>
      </div>
    </div>
  )
}

export default ActivityA;