import React, { useEffect } from "react";
import { getTransactions } from "@/service/account";
import { transactionsAdapt } from "@/utils/biz";
import { TradeType, DirectionType, PaymentType } from "@/interfaces";
import { useTranslation } from "react-i18next";
import './index.scss';
import { useSetState } from "ahooks";
import { InfiniteScroll } from "antd-mobile";
import PageContainer from "@/bizComponents/PageContainer";
import { removeRepeat } from "@/utils/util";
import Coin from "@/components/Coin";

const Transactions = () => {
  const { t } = useTranslation();
  const [state, setState] = useSetState({
    data: [],
    pageNum: 1,
    pageSize: 10,
    hasMore: false,
    loading: false
  })

  const TradeTypeMap = {
    [TradeType.topUp]: t('bb.top-up'),
    [TradeType.consumption]: t('bb.box-buy'),
    [TradeType.recycle]: t('bb.recycle'),
    [TradeType.withdraw]: t('bb.withdraw')
  }

  const renderTitle = (item: any) => {
    if (item.remark === PaymentType.PaymentFreight) {
      return t('bb.freight')
    }
    return TradeTypeMap[item.tradeType];
  }

  const loadMore = async () => {
    const res = await getTransactions({
      pageNum: state.pageNum,
      pageSize: state.pageSize
    });
    if (res?.data?.list) {
      const append = transactionsAdapt(res?.data?.list);
      setState({
        data: removeRepeat([...state.data, ...append]),
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
  }

  const getData = async () => {
    setState({
      loading: true
    });
    const res = await getTransactions({
      pageNum: 1,
      pageSize: state.pageSize
    });
    if (res?.data?.list) {
      const data = transactionsAdapt(res?.data?.list);
      setState({
        data,
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
    setState({
      loading: false
    });
  }

  useEffect(() => {
    getData();
  }, [])

  const Item = (props: {item: any}) => {
    const { item } = props;
    return (
      <div className="items">
        <div>
          <p className="title">{renderTitle(item)}</p>
          <p className="time">{item.time}</p>
        </div>
        <p className="amount">
          {item.directionType === DirectionType.income ? '+' : '-'}&nbsp;
          <Coin amount={item.tradeAmount} className="reverse-coin"/>
        </p>
      </div>
    )
  }

  return (
    <PageContainer loading={state.loading} data={state.data}>
      <div className="transactions-wrapper">
        { state.data.map(item => <Item key={item.id} item={item}/>) }
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={state.hasMore} threshold={0} />
    </PageContainer>
  )
}

export default Transactions;