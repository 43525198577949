import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { useCountDown } from "ahooks";
import { Trans, useTranslation } from "react-i18next";
import Coin from "@/components/Coin";
import { DefaultBoxIcon } from "@/components/SvgIcon";
import './index.scss';

interface IProps {
	visible: boolean
	onClose: () => void
	onPay?: () => void
	type: 'box' | 'freight'
	price: string | number
	loading?: boolean
	leftTime: number
	boxName?: string
}

const PaymentModal = (props: IProps) => {
	const { t } = useTranslation();
	const { visible, onClose, onPay, type, price, loading, leftTime, boxName } = props;

	const [targetDate, setTargetDate] = useState<number>();
	const [, formattedRes] = useCountDown({
		targetDate,
		onEnd: () => {
			// alert('End of the time');
		},
	});
	const { hours, minutes, seconds } = formattedRes;
	const time = `${hours}:${minutes}:${seconds}`

	useEffect(() => {
		setTargetDate(leftTime)
	}, [visible])

	return (
		<Modal visible={visible} onClose={onClose} className="bb-payment-modal">
			<p className="payment-modal-title">{t('bb.confirm-pay')}</p>
			{
				boxName &&
				<p className="box-name">{boxName}</p>
			}
			{
				type === 'box' ? <DefaultBoxIcon /> :
					<div className={`payment-modal-pic freight`} />
			}
			{
				type === 'box' &&
				<div className="payment-modal-count-down">
					<Trans i18nKey="bb.the-order-closes-in-time">
						<p>{{time}}</p>
					</Trans>
				</div>
			}
			<p className="payment-modal-price">
				{type === 'freight' ? t('bb.freight') : t('bb.box-price')}&nbsp;<Coin amount={price} />
			</p>
			<Button
				className="payment-modal-btn"
				onClick={onPay}
				loading={loading}
			>
				{t('bb.pay')}
			</Button>
		</Modal>
	)

}

export default PaymentModal;