import React from "react";
import { Popup as AdmPopup, PopupProps } from "antd-mobile";
import cn from 'classnames';
import './index.scss';

const Popup = (props: PopupProps) => {
  const { children, className, ...others } = props;

  return (
    <AdmPopup
      className={cn("bb-popup", className)}
      bodyClassName="bb-popup-body"
      maskClassName="bb-popup-mask"
      showCloseButton
     {...others}
     >
      <div className="blur-block">
        <div className="left" />
        <div className="center" />
        <div className="right" />
      </div>
      <div className="bb-popup-children">
        {children}
      </div>
     </AdmPopup>
  )

}

export default Popup;