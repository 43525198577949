import React from 'react';
import { Swiper as AdmSwiper, SwiperProps, Image } from 'antd-mobile';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { LinkTypeEnum } from "@/interfaces";

interface IProps extends SwiperProps {
  dataSource: any[]
}

const Swiper = (props: IProps) => {
  const { dataSource, ...others } = props;
  const navigate = useNavigate();

  if (!Array.isArray(dataSource) || dataSource.length === 0) {
    return null;
  }

  return (
    <div className="bb-swiper-container">
      <AdmSwiper
        className='bb-swiper'
        autoplay
        loop
        allowTouchMove={dataSource.length !== 1}
        {...others}
      >
        {
          dataSource.map((item, index) => (
            <AdmSwiper.Item key={index}>
              <Image
                onClick={() => {
                  if (item.url) {
                    if (item.linkType === LinkTypeEnum.inner) {
                      navigate(item.url);
                    } else {
                      window.open(item.url, "new_open")
                    }
                  }
                }}
                className='item-image'
                src={item.pic}
              />
            </AdmSwiper.Item>
          ))
        }
      </AdmSwiper>
    </div>
  )

}

export default Swiper;