import React from "react";
import Common from "./Common";
import { BoxStatusEnum } from "@/interfaces";
import { recycledBoxAdapt } from "@/utils/biz";
import Empty from "@/components/Empty";

interface IProps {
  dataSource: any[]
}

const Recycled = (props: IProps) => {
  const formattedData = recycledBoxAdapt(props.dataSource);

  return (
    <>
      {
        formattedData.length === 0 ?
          <Empty /> :
          <div className="recycled-wrapper">
            {
              formattedData.map(item => (
                <Common item={item} type={BoxStatusEnum.recycled} key={item.id} />
              ))
            }
          </div>
      }
    </>
  )

}

export default Recycled;