import {
	agreementContentESMX,
	privacyContentESMX, ruleContentESMX
} from "@/locales/largeContent";

export default {
	translation: {
		"bb.start-login": "Iniciar sesión",
		"bb.please-input-email-or-phone-number": "Por favor, introduce ele correo electrónico o número de cédula",
		"bb.email-or-phone-number-ten-digits": "Correo electrónico / Número de cédula (10 dígitos)",
		"bb.next-step": "El Siguiente",
		"bb.please-input-verification-code": "Por favor, introduce el código de verificación",
		"bb.please-input-password": "Por favor, introduce la contraseña",
		"bb.get-verification-code": "Obtener",
		"bb.resend": "Reenviar",
		"bb.verification-code-input": "Introducir código de verificación",
		"bb.password-input": "Introducir contraseña",
		"bb.agree-agreement-and-privacy": "Acepta los <0>{{agreement}}</0> y la <1>{{privacy}}</1>",
		"bb.agree": "Acepta los ",
		"bb.agreement": "\"Términos del Servicio\"",
		"bb.agreement-title": "Términos del Servicio",
		"bb.privacy": "\"Política de Privacidad\"",
		"bb.privacy-title": "Política de privacidad",
		"bb.set-password": "Establecer contraseña",
		"bb.set-password-six-digits": "Establecer contraseña de 6 dígitos",
		"bb.continue": "Continuar",
		"bb.skip": "Saltar",
		"bb.box": "Caja",
		"bb.mine": "Mis",
		"bb.all-boxes": "Todas las cajas",
		"bb.top-up": "Recargar",
		"bb.rules-of-the-game": "Reglas del juego",
		"bb.recommendation": "Recomendaciones",
		"bb.box-detail": "Detalles de la caja",
		"bb.buy": "Compra",
		"bb.try-it-for-free": "Probar sin costo",
		"bb.items-in-the-box": "Productos dentro de la caja",
		"bb.open-box-tip": "Nota: Después de abrir la caja, recibirás uno de los siguientes productos.",
		"bb.confirm-pay": "Confirmar pago",
		"bb.box-price": "Precio de la caja",
		"bb.the-order-closes-in-time": "El pedido se cerrará después de las <0>{{time}}</0>",
		"bb.freight": "Flete",
		"bb.pay": "Pagar",
		"bb.pay-success": "Pago exitoso",
		"bb.open-case": "Abrir la caja",
		"bb.open-it-later": "Abrir más tarde",
		"bb.open-it-now": "Abrir ahora mismo",
		"bb.if-later-open-in-my-box": "Seleccione \"Abrir más tarde\" y podrá abrirlo en \"Mis Cajas\"",
		"bb.deliver": "Enviar",
		"bb.recycle-some-money": "Reciclar(monto <0>{{amount}}</0>)",
		"bb.one-more-time": "Otra vez",
		"bb.value-of-item": "Valor de producto",
		"bb.confirm-order": "confirmar el pedido",
		"bb.pay-now": "Pago inmediato",
		"bb.recycle-some-items": "Reciclar {{number}} productos",
		"bb.recycle-price": "Monto del reciclaje",
		"bb.confirm-recycle": "confirmar el reciclaje",
		"bb.my-orders": "Mis pedidos",
		"bb.my-boxes": "Mis cajas",
		"bb.address": "Dirección de envío",
		"bb.about-us": "Acerca de nosotros",
		"bb.contact-customer-service": "Contactar al servicio al cliente",
		"bb.faq": "Preguntas frecuentes",
		"bb.customer-agreement": "Acuerdo del cliente",
		"bb.customer-service-feedback": "Comentarios al servicio al cliente",
		"bb.setting": "Configuración",
		"bb.transactions": "Historial de transacciones",
		"bb.pending-pay": "Pendiente de pago",
		"bb.done": "Completado",
		"bb.closed": "Cerrado",
		"bb.the-order-closes-in-minutes": "El pedido se cerrará en {{time}} minutos.",
		"bb.cancel-order": "Cancelar pedido",
		"bb.payment-now": "Pagar ahora",
		"bb.close": "Cerrar",
		"bb.transaction-done": "Transacción finalizada",
		"bb.pending-open-box": "Pendiente de abrir",
		"bb.pending-pick-up": "Pendiente de recoger",
		"bb.recycled": "Reciclado",
		"bb.pending-deliver": "Pendiente de envío",
		"bb.delivered": "Enviado",
		"bb.order-time": "Hora de pedido",
		"bb.recycle": "Reciclaje",
		"bb.open-box": "Abrir",
		"bb.price": "Precio",
		"bb.exchange-amount": "Monto de canje",
		"bb.your-items-prepare-deliver": "Tu producto está en proceso de envío...",
		"bb.your-items-delivered": "Tu producto ha sido enviado, por favor espera actualizar el estado de envío...",
		"bb.your-items-signed": "Tu producto ha sido entregado...",
		"bb.box-number": "codigo de caja",
		"bb.withdraw": "Retirar dinero",
		"bb.box-buy": "Compra de caja sorpresa",
		"bb.top-up-amount": "Monto de recarga",
		"bb.please-input-top-up-amount": "Por favor, ingresar la cantidad que deseas recargar.",
		"bb.contact-us": "Contáctenos",
		"bb.email": "Correo electrónico",
		"bb.email-require": "*Correo electrónico",
		"bb.work-time": "Horario de trabajo",
		"bb.problem-feedback": "sus comentarios",
		"bb.please-input-suggestion-or-problem": "Por favor, proporciona sugerencias o preguntas",
		"bb.upload-screenshot": "Cargar captura de pantalla",
		"bb.your-email-or-phone": "Tu correo electrónico/número de cédula",
		"bb.submit": "Enviar",
		"bb.add-address": "Agregar dirección de envío",
		"bb.confirm": "Confirmar",
		"bb.add-address-2": "Añadir dirección de envío",
		"bb.modify-address": "Modificar dirección de envío",
		"bb.full-name-require": "*Nombre completo",
		"bb.phone-number-require": "*Número de cédula",
		"bb.phone-number": "Número de cédula",
		"bb.full-address-require": "*Dirección detallada",
		"bb.postal-code": "Código postal",
		"bb.version": "Versión",
		"bb.logout": "Cerrar sesión",
		"bb.withdraw-record": "Historial de retiros",
		"bb.withdraw-amount-available": "Monto disponible para retiro actualmente",
		"bb.withdraw-amount": "Monto de retiro",
		"bb.your-phone-number": "Número de cédula",
		"bb.your-email": "correo",
		"bb.apply-withdraw": "Solicitar retiro",
		"bb.withdraw-all-amount": "Retirar todo el saldo",
		"bb.withdraw-result": "Resultado del retiro",
		"bb.withdraw-apply-success": "Solicitud de retiro exitosa, se espera que el servicio al cliente se comunique para procesarla.",
		"bb.apply-time": "Fecha de solicitud",
		"bb.applied": "Solicitado",
		"bb.processing": "En proceso",
		"bb.withdraw-success": "Retiro exitoso",
		"bb.withdraw-failed": "Retiro fallido",
		"bb.process-time": "Tiempo de procesamiento",
		"bb.home": "Página de inicio",
		"bb.address-detail": "Detalles de la dirección de envío",
		"bb.order-detail": "Detalles del pedido",
		"bb.login": "Iniciar sesión",
		"bb.phone-or-email": "Correo electrónico / Número de cédula",
		"bb.consignee": "Destinatario",
		"bb.order-number": "Número de orden",
		"bb.selected-delivery": "Seleccionado para enviar",
		"bb.signed": "Ha sido recibido",
		"bb.set-default-address": "Establecer como dirección predeterminada",
		"bb.system-busy": "El sistema está ocupado",
		"bb.system-error": "Error del sistema",
		"bb.balance-insufficient": "Saldo insuficiente",
		"bb.login-expired": "La sesión ha expirado, por favor vuelva a iniciar sesión",
		"bb.user-not-exist": "Usuario no encontrado",
		"bb.password-error": "Contraseña incorrecta",
		"bb.code-expired": "El código de verificación ha expirado",
		"bb.email-send-failed": "Error al enviar el correo electrónico, por favor inténtelo de nuevo",
		"bb.sms-send-failed": "Error al enviar el mensaje de texto, por favor inténtelo de nuevo",
		"bb.select-country-title": "Por favor, seleccione su país e idioma",
		"bb.privacy-content": privacyContentESMX,
		"bb.agreement-content": agreementContentESMX,
		"bb.change-lang": "Cambiar idioma",
		"bb.cancel-order-alert-title": "¿Estás seguro/a de que deseas cancelar esta orden?",
		"bb.rule-content": ruleContentESMX,
		"bb.input-amount-wrong": "El monto ingresado es incorrecto.",
		"bb.input-format-wrong": "Error de formato.",
		"bb.please-check-agreement": "Por favor, marque la casilla de acuerdo con los términos y condiciones.",
		"bb.set-successfully": "Configuración exitosa.",
		"bb.add-successfully": "Agregado exitosamente.",
		"bb.deleted-successfully": "Eliminado exitosamente.",
		"bb.update-successfully": "Actualización exitosa.",
		"bb.payment-successfully": "Pago exitoso.",
		"bb.recover-successfully": "Recuperación exitosa.",
		"bb.cancel-successfully": "Cancelación exitosa.",
		"bb.send-successfully": "Envío exitoso.",
		"bb.the-picture-is-too-large": "La imagen es demasiado grande.",
		"bb.feedback-successfully": "Comentarios enviados exitosamente.",
		"bb.password-format-wrong": "El formato de la contraseña es incorrecto.",
		"bb.request-is-timeout": "La operación ha excedido el tiempo permitido.",
		"bb.yes": "Sí",
		"bb.no": "No",
		"bb.number-of-products": " productos",
		"bb.pay-with": "Pagar por {{type}}",
		"bb.minimum-recharge": `La recarga mínima es de $`
	}
}