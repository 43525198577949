import React from "react";
import { Input as AdmInput, InputProps } from "antd-mobile";
import cn from 'classnames';

import './index.scss';

const Input = (props: InputProps) => {
  const { className, ...others } = props;
  return (
    <AdmInput
      className={cn('bb-input', className)}
      {...others}
    />
  )
}

export default Input;