import React from "react";
import ItemCard from "@/bizComponents/ItemCard";
import cn from "classnames";
import './product-items.scss';
import useDynamicItemHooks from "@/hooks/use-dynamic-item-hooks";

interface IProps {
	dataSource: any[]
}

const ProductItems = (props: IProps) => {
	const { dataSource } = props;
	const { margin, number } = useDynamicItemHooks();
	const padding = (v) => dataSource.length <= number ? v : margin;

	return (
		<div
			className="box-detail-product-items-wrapper"
			style={{
				paddingLeft: padding(12),
				paddingRight:  padding(12)
			}}
		>
			{
				dataSource.map((item, idx) => (
					<ItemCard
						size="normal"
						className={cn('card', {
							'margin-none': (idx + 1) % number === 0
						})}
						dataInfo={item}
						key={idx}
						style={{
							marginRight:  padding(10),
						}}
					/>
				))
			}
		</div>
	)
}

export default ProductItems;