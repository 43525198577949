import React from "react";
import { EmptyIcon } from "@/components/SvgIcon";

import './index.scss';

const Empty = () => {
	return (
		<div className="bb-empty">
			<EmptyIcon />
		</div>
	)
}

export default Empty;