import React from "react";
import Loading from "@/components/Loading";
import Empty from "@/components/Empty";

interface IProps {
	loading?: boolean
	data?: any[]
	children?: React.ReactElement | React.ReactNode
}

const PageContainer = (props: IProps) => {
	const { loading, data, children } = props;
	return (
		<>
			{
				loading ? <Loading /> :
					<>
						{
							data.length === 0 ? <Empty /> : children
						}
					</>
			}
		</>
	)
}

export default PageContainer;