import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import './index.scss';
import { Checkbox } from "antd-mobile";
import Button from "@/components/Button";
import { CHECKED_LANG_TYPE_KEY, LangType } from "@/interfaces";
import i18n from "@/i18n";
import { useNavigate } from "react-router-dom";
import { setDefaultConfig } from 'antd-mobile'
import ZH_CN from "@/locales/antd/ZH_CN.json";
import EN from "@/locales/antd/EN.json";
import PT_BR from "@/locales/antd/PT_BR.json";
import ES_MX from "@/locales/antd/ES_MX.json";
import { getJsonData } from "@/utils/util";

const SelectCountry = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const lang = getJsonData(localStorage.getItem(CHECKED_LANG_TYPE_KEY));
	const [checkKey, setCheckKey] = useState(lang);

	const langMap = {
		'ZH_CN': ZH_CN,
		'EN': EN,
		'PT_BR': PT_BR,
		'ES_MX': ES_MX
	}

	const items = [
		{
			key: LangType.ES_MX,
			title: `México--Español`
		},
		{
			key: LangType.EN,
			title: `India--English`
		},
		{
			key: LangType.PT_BR,
			title: `Brasil--Português`
		},
		{
			key: LangType.ZH_CN,
			title: `中国--简体中文`
		},
	]

	const handleChecked = (item) => {
		setCheckKey(item.key);
	}

	const onConfirm = () => {
		if (i18n.language !== checkKey) {
			i18n.changeLanguage(checkKey as string);
			localStorage.setItem(CHECKED_LANG_TYPE_KEY, JSON.stringify(checkKey));
			setDefaultConfig({ locale: langMap[checkKey as string] });
		}
		navigate(-1)
	}

	return (
		<div className="select-country-wrapper">
			<p className="select-country-title">{t('bb.select-country-title')}</p>
			<div className="select-country-items">
				{
					items.map(item => (
						<Checkbox
							key={item.key}
							onChange={() => handleChecked(item)}
							checked={checkKey === item.key}
							className="item-checkbox"
						>
							{item.title}
						</Checkbox>
					))
				}
			</div>
			<Button size="large" wrapperPadding="0 24px" onClick={onConfirm}>OK</Button>
		</div>
	)

}

export default SelectCountry;