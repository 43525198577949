import React, { useEffect } from "react";
import Tabs from "@/components/Tabs";
import { BoxStatusEnum, IState } from "@/interfaces";
import WaitPickup from "./components/WaitPickup";
import WaitOpen from "./components/WaitOpen";
import Recycled from "./components/Recycled";
import Delivered from "./components/Delivered";
import { useTranslation } from "react-i18next";
import { getMyBoxList } from "@/service/user";
import { calcBuyBtnAmount, myBoxListAdapt, waitOpenBoxAdapt } from "@/utils/biz";
import { useSetState } from "ahooks";
import Loading from "@/components/Loading";
import { InfiniteScroll } from "antd-mobile";
import WaitDeliver from "@/pages/MyBoxes/components/WaitDeliver";
import './index.scss';
import BoxFromPayToOpen from "@/bizComponents/BoxFromPayToOpen";
import OpenBox from "@/bizComponents/OpenBox";
import { createOrder } from "@/service/order";
import { BalanceState, useBalanceStore } from "@/store";

const MyBox = () => {
  const { t } = useTranslation();
  const balance = useBalanceStore((state: BalanceState) => state.balance);
  const fetch = useBalanceStore((state: BalanceState) => state.fetch);
  const [state, setState] = useSetState<IState>({
    data: [],
    activeKey: String(BoxStatusEnum.waitOpen),
    loading: false,
    pageNum: 1,
    pageSize: 10,
    openBoxVisible: false,
    item: {},
    orderNo: '',
    leftTime: 0,
  })

  const boxStatusList = [
    {
      key: BoxStatusEnum.waitOpen,
      title: t('bb.pending-open-box')
    },
    {
      key: BoxStatusEnum.waitPickup,
      title: t('bb.pending-pick-up')
    },
    {
      key: BoxStatusEnum.recycled,
      title: t('bb.recycled')
    },
    {
      key: BoxStatusEnum.waitDeliver,
      title: t('bb.pending-deliver')
    },
    {
      key: BoxStatusEnum.delivered,
      title: t('bb.delivered')
    }
  ]

  const onTabChange = (v) => {
    setState({
      pageNum: 1,
      activeKey: String(v),
    })
    getBoxList(String(v), 1)
  }

  const renderContent = () => {
    switch (state.activeKey) {
      case String(BoxStatusEnum.waitOpen):
        return (
          <WaitOpen
            dataSource={waitOpenBoxAdapt(state.data)}
            onOpenBox={onOpenBox}
          />
        )
      case String(BoxStatusEnum.waitDeliver):
        return <WaitDeliver dataSource={state.data} />
      case String(BoxStatusEnum.recycled):
        return <Recycled dataSource={state.data} />
      case String(BoxStatusEnum.waitPickup):
        return <WaitPickup dataSource={state.data} afterRecycled={() => getInitData(String(BoxStatusEnum.waitPickup))}/>
      case String(BoxStatusEnum.delivered):
        return <Delivered dataSource={state.data} />
    }
  }

  const getBoxList = async (activeKey, pageNum) => {
    setState({
      loading: true
    })
    const res = await getMyBoxList({
      userBoxStatus: +activeKey,
      pageNum: pageNum,
      pageSize: state.pageSize
    });
    if (res?.data?.list) {
      const data = myBoxListAdapt(res?.data?.list);
      setState({
        data,
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
    setState({
      loading: false
    })
  }

  const loadMore = async () => {
    const res = await getMyBoxList({
      userBoxStatus: +state.activeKey,
      pageNum: state.pageNum,
      pageSize: state.pageSize
    });
    if (res?.data?.list) {
      const append = myBoxListAdapt(res?.data?.list);
      setState({
        data: [...state.data, ...append],
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
  }

  const getInitData = (key) => {
    getBoxList(key, 1);
  }

  const onOpenBox = (item: any) => {
    setState({
      item,
      openBoxVisible: true
    })
  }

  const onOneMoreTime = async () => {
    const res = await createOrder(state.item.boxId);
    if (res?.data) {
      const { data } = res;
      setState({
        openBoxVisible: false,
        payVisible: true,
        orderNo: data.orderNo,
        leftTime: data.expireTime
      })
    }
  }

  const setOpenBox = (bool: boolean) => {
    setState({ openBoxVisible: bool });
  }

  const hideBoxAndReloadData = () => {
    setOpenBox(false);
    getInitData(state.activeKey);
  }

  const hidePayModalAndReloadData = () => {
    setState({ payVisible: false })
    getInitData(state.activeKey);
  }

  useEffect(() => {
    getInitData(state.activeKey);
  }, []);

  useEffect(() => {
    if (balance === null) {
      fetch();
    }
  }, [balance])

  return (
    <div className="my-box-wrapper">
      <Tabs
        dataSource={boxStatusList}
        onChange={onTabChange}
        activeKey={state.activeKey}
        fixed
      />
      {
        state.loading ? <Loading /> :
          <div className="my-box-content">
            { renderContent() }
            <InfiniteScroll loadMore={loadMore} hasMore={state.hasMore} threshold={50}/>
          </div>
      }
      {
        state.openBoxVisible &&
        <OpenBox
          boxImage={state.item.pic2}
          orderNo={state.item.orderNo}
          onOpenItLater={() => setOpenBox(false)}
          afterRecycled={hideBoxAndReloadData}
          onOpenedClose={hideBoxAndReloadData}
          onOneMoreTime={onOneMoreTime}
        />
      }
      <BoxFromPayToOpen
        boxImage={state.item.pic2}
        boxName={state.item.name}
        visible={state.payVisible}
        onClosePayModal={hidePayModalAndReloadData}
        orderNo={state.orderNo}
        boxPrice={state.item.price}
        leftTime={state.leftTime}
        differencePrice={calcBuyBtnAmount(balance, state.item.price)}
        onOpenAgain={onOneMoreTime}
        onPaySuccess={hidePayModalAndReloadData}
        afterRecycled={hidePayModalAndReloadData}
      />
    </div>
  )

}

export default MyBox;