import React, { useEffect } from "react";
import Input from "@/components/Input";
import Button from "@/components/Button";
import WithdrawResult from "@/bizComponents/WithdrawResult";
import { useTranslation } from "react-i18next";
import { applyWithdraw } from "@/service/account";
import { useSetState } from "ahooks";
import './index.scss';
import { Toast } from "antd-mobile";
import { emailPattern, phonePattern, twoDecimalNumberPattern } from "@/interfaces";
import Coin from "@/components/Coin";
import { BalanceState, useBalanceStore } from "@/store";

const defaultState = {
  totalAmount: '0',
  amount: '',
  mobile: '',
  email: '',
  resultVisible: false
}

const Withdraw = () => {
  const { t } = useTranslation();
  const [state, setState] = useSetState(defaultState);
  const fetch = useBalanceStore((state: BalanceState) => state.fetch);
  const balance = useBalanceStore((state: BalanceState) => state.balance);

  const onConfirm = async () => {
    if (!phonePattern.test(state.mobile)) {
      Toast.show({
        content: t('bb.input-format-wrong'),
        duration: 2000
      });
      return;
    }
    if (!emailPattern.test(state.email)) {
      Toast.show({
        content:t('bb.input-format-wrong'),
        duration: 2000
      });
      return;
    }
    const res = await applyWithdraw({
      mobile: state.mobile,
      email: state.email,
      withdrawalAmount: state.amount,
    });
    if (res?.code === 0) {
      setState(defaultState);
      fetch();
      setState({
        resultVisible: true
      })
    }
  }

  const onClearTotal = () => {
    setState({ amount: '' })
  }

  const onAmountChange = (v) => {
    if (twoDecimalNumberPattern.test(v) || !v) {
      setState({
        amount: v
      })
    }
  }

  useEffect(() => {
    if (balance === null) {
      fetch();
    }
  }, [balance])

  return (
    <div className="withdraw-wrapper">
      <div className="card">
        <div className="blur-block">
          <div className="left"/>
          <div className="center"/>
          <div className="right"/>
        </div>
        <div className="content">
          <p className="title">{t('bb.withdraw-amount-available')}</p>
          <Coin amount={balance} size="large" className="amount-wrapper"/>
        </div>
      </div>
      <div className="withdraw-content">
        <p className="input-title">{t('bb.withdraw-amount')}</p>
        <div className="amount-input-wrapper">
          <div className="amount-input">
            <Input
              placeholder={t('bb.withdraw-amount')}
              value={state.amount}
              onChange={onAmountChange}
            />
            {
              state.amount ?
                <span className="total-close-btn" onClick={onClearTotal}>×</span> :
                <span
                  className="total-btn"
                  onClick={() => setState({ amount: String(balance) })}
                >
                  {t('bb.withdraw-all-amount')}
                </span>
            }
          </div>
          {
            state.amount && <p className="extra-tips">=${state.amount}</p>
          }
        </div>
        <p className="input-title">{t('bb.your-phone-number')}</p>
        <Input
          placeholder={t('bb.your-phone-number')}
          value={state.mobile}
          onChange={(v) => setState({mobile: v})}
        />
        <p className="input-title">{t('bb.your-email')}</p>
        <Input
          placeholder={t('bb.your-email')}
          value={state.email}
          onChange={(v) => setState({email: v})}
        />
      </div>
      <Button
        className="confirm-btn"
        disabled={!(+state.amount) || !(state.mobile && state.email)}
        onClick={onConfirm}
        size="large"
        color="warning"
      >
        {t('bb.confirm')}
      </Button>
      {
        state.resultVisible && <WithdrawResult onOk={() => setState({ resultVisible: false })}/>
      }
    </div>
  )

}

export default Withdraw;