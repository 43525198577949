import React from "react";
import { JumboTabs, Image } from "antd-mobile";
import { LinkTypeEnum } from "@/interfaces";
import './categoryType.scss';

interface IData {
  logoUrl: string
  logoText: string
  logoLink: string
  linkType: LinkTypeEnum
}

interface IProps {
  dataSource: IData[]
  onChange?: (args: any) => void
}

const CategoryType = (props: IProps) => {
  const { dataSource, onChange } = props;

  const handleChange = (key: string) => {
    const target = dataSource[key];
    onChange && onChange(target);
  }

  const title = (src) => {
    return <Image src={src} fallback={<div className="fallback-img" />}/>
  }

  return (
    <JumboTabs defaultActiveKey={'fake-key'} className='bb-category-type' onChange={handleChange}>
      {
        dataSource.map((item, idx) => (
          <JumboTabs.Tab title={title(item.logoUrl)} key={idx} description={item.logoText} />
        ))
      }
    </JumboTabs>
  )

}

export default CategoryType;
