import React, { useEffect } from "react";
import Tabs from "@/components/Tabs";
import { OrderStatusEnum } from "@/interfaces";
import WaitPay from "./components/WaitPay";
import Done from './components/Done';
import Closed from './components/Closed';
import { useTranslation } from "react-i18next";
import { cancelOrder, createOrder, getOrderList } from "@/service/order";
import { useSetState } from "ahooks";
import { calcBuyBtnAmount, myOrderListAdapt } from "@/utils/biz";
import { Toast, InfiniteScroll } from "antd-mobile";
import Loading from "@/components/Loading";
import BoxFromPayToOpen from "@/bizComponents/BoxFromPayToOpen";
import './index.scss';
import { BalanceState, useBalanceStore } from "@/store";
import CancelOrderAlert from "./components/CancelOrderAlert";

const MyOrders = () => {
  const { t } = useTranslation();
  const balance = useBalanceStore((state: BalanceState) => state.balance);
  const fetch = useBalanceStore((state: BalanceState) => state.fetch);
  const [state, setState] = useSetState({
    data: [],
    activeKey: String(OrderStatusEnum.waitPay),
    loading: false,
    payVisible: false,
    orderNo: '',
    boxPrice: 0,
    hasMore: false,
    pageNum: 1,
    pageSize: 10,
    leftTime: 0,
    boxId: null,
    cancelOrderLoading: {},
    boxName: '',
    alertVisible:  false,
    orderId: null,
    boxImage: ''
  })

  const orderStatusList = [
    {
      key: OrderStatusEnum.waitPay,
      title: t('bb.pending-pay')
    },
    {
      key: OrderStatusEnum.done,
      title: t('bb.done')
    },
    {
      key: OrderStatusEnum.closed,
      title: t('bb.closed')
    },
  ]

  const onTabChange = (v) => {
    setState({
      pageNum: 1,
      activeKey: String(v),
    })
    getOrder(String(v), 1);
  }

  const renderContent = () => {
    switch (state.activeKey) {
      case String(OrderStatusEnum.waitPay):
        return (
          <WaitPay
            dataSource={state.data}
            onCancelOrder={beforeCancelOrder}
            onPayOrder={onPayOrder}
            onTimeOut={() => getOrder(String(OrderStatusEnum.waitPay), state.pageNum)}
            cancelOrderLoading={state.cancelOrderLoading}
        />
        );
      case String(OrderStatusEnum.done):
        return <Done dataSource={state.data} />;
      case String(OrderStatusEnum.closed):
        return <Closed dataSource={state.data} />;
      default:
        return null;
    }
  }

  const beforeCancelOrder = async (orderId) => {
    setState({
      alertVisible: true,
      orderId
    })
  }

  const onCancelOrder = async () => {
    const { orderId: id } = state;
    setState({
      cancelOrderLoading: {
        [id]: true
      }
    })
    const res = await cancelOrder(id);
    if (res?.code === 0) {
      Toast.show({
        content: t('bb.cancel-successfully'),
        duration: 1000,
        afterClose: () => {
          setState({
            cancelOrderLoading: {
              [id]: false
            },
            alertVisible: false
          })
          getOrder(String(OrderStatusEnum.waitPay), 1)
        }
      })
    } else {
      setState({
        cancelOrderLoading: {
          [id]: false
        },
        alertVisible: false
      })
    }
  }

  const onPayOrder = (item) => {
    setState({
      boxId: item.boxId,
      orderNo: item.orderNo,
      boxPrice: item.price,
      payVisible: true,
      leftTime: item.expireTime,
      boxName: item.name,
      boxImage: item.detailPic
    })
  }

  const getOrder = async (activeKey, pageNum) => {
    setState({
      loading: true
    })
    const res = await getOrderList({
      orderStatus: activeKey,
      pageNum: pageNum,
      pageSize: state.pageSize
    });
    if (res?.data?.list) {
      const data = myOrderListAdapt(res);
      setState({
        data,
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
    setState({
      loading: false
    })
  }

  const loadMore = async () => {
    const res = await getOrderList({
      orderStatus: state.activeKey,
      pageNum: state.pageNum,
      pageSize: state.pageSize
    });
    if (res?.data?.list) {
      const append = myOrderListAdapt(res);
      setState({
        data: [...state.data, ...append],
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
  }

  const onOpenItLater = () => {
    setState({
      payVisible: false
    })
    getInitData();
  }

  const getInitData = () => {
    getOrder(state.activeKey, 1);
  }

  const onOneMoreTime = async () => {
    const res = await createOrder(state.boxId);
    if (res?.data) {
      const { data } = res;
      setState({
        payVisible: true,
        orderNo: data.orderNo,
        leftTime: data.expireTime
      })
    }
  }

  useEffect(() => {
    getInitData();
  }, [])

  useEffect(() => {
    if (balance === null) {
      fetch();
    }
  }, [balance])

  return (
    <div className="my-orders-wrapper">
      <Tabs
        dataSource={orderStatusList}
        onChange={onTabChange}
        activeKey={state.activeKey}
        fixed
      />
      {
        state.loading ? <Loading /> :
          <div className="my-orders-content">
            { renderContent() }
            <InfiniteScroll loadMore={loadMore} hasMore={state.hasMore} threshold={50}/>
          </div>
      }
      <BoxFromPayToOpen
        boxImage={state.boxImage}
        boxName={state.boxName}
        visible={state.payVisible}
        onClosePayModal={() => setState({ payVisible: false })}
        onPaySuccess={() => {setState({ payVisible: false }); getInitData()}}
        orderNo={state.orderNo}
        boxPrice={state.boxPrice}
        differencePrice={calcBuyBtnAmount(balance, state.boxPrice)}
        afterRecycled={() => getInitData()}
        leftTime={state.leftTime}
        onOpenItLater={onOpenItLater}
        onCloseOpenBox={() => getInitData()}
        onOpenAgain={onOneMoreTime}
      />
      <CancelOrderAlert
        visible={state.alertVisible}
        title={t('bb.cancel-order-alert-title')}
        onOk={onCancelOrder}
        onCancel={() => {
          setState({
            alertVisible: false
          })
        }}
      />
    </div>
  )

}

export default MyOrders;