import React, { useEffect } from "react";
import Button from "@/components/Button";
import FullScreenContainer from "@/components/FullScreenContainer";
import { Image } from "antd-mobile";
import ItemRecycler from "@/bizComponents/ItemRecycler";
import { Trans, useTranslation } from "react-i18next";
import { openBox } from '@/service/box';
import { useSetState } from 'ahooks';
import { CHECKED_PRODUCT_LIST_KEY, IState } from '@/interfaces';
import { boxRecycleCount, tryItForFree } from "@/service/user";
import { useNavigate, useParams } from 'react-router-dom';
import { tsToTime } from "@/utils/time";
import './index.scss';
import Coin from "@/components/Coin";
import OpenBoxAnimate from "@/components/OpenBoxAnimate";
import { encrypt } from "@/utils/util";

interface IProps {
	onOpenedClose?: () => void
	orderNo: string | number
	onOneMoreTime?: () => void
	afterRecycled?: () => void
	onOpenItLater?: () => void
	ifTryFree?: boolean
	boxName?: string
	boxImage: string
}

const OpenBox = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();
	const {
		onOpenedClose, orderNo,
		onOneMoreTime, afterRecycled, onOpenItLater,
		ifTryFree, boxName, boxImage
	} = props;
	const [state, setState] = useSetState<IState>({
		opened: false,
		productAmount: null,
		productImg1: null,
		detailImg: null,
		boxId: null,
		recycleVisible: false,
		recycleData: [],
		recycleTotalAmount: 0,
		payVisible: false,
		freightPrice: 0,
		deliverLoading: false,
		orderInfo: {},
		openBoxLoading: false,
		animateFinished: false,
		productId: null
	})

	const setRecycleVisible = (bool: boolean) => {
		setState({ recycleVisible: bool })
	}

	const setDataWhenOpenBox = (res: any) => {
		if (res?.data) {
			const { productAmount, productImg1, productImg2, productName, id: productId } = res?.data?.productInfo;
			const { id, createTime } = res?.data?.userBoxInfo;
			setState({
				productAmount,
				detailImg: productImg2,
				boxId: id,
				productId,
				recycleData: [
					{
						id,
						pic: productImg1,
						price: productAmount,
						name: productName
					}
				],
				orderInfo: {
					id,
					name: productName,
					pic: productImg1,
					orderNo,
					orderTime: tsToTime(createTime),
					price: productAmount
				}
			})
		}
	}

	const onOpenBox = async () => {
		if (!ifTryFree) {
			setState({
				openBoxLoading: true
			})
			const res = await openBox(orderNo);
			setDataWhenOpenBox(res);
		} else {
			setState({
				openBoxLoading: true
			})
			const res = await tryItForFree(id)
			setDataWhenOpenBox(res);
		}
	}

	const onRecycle = () => {
		setState({
			recycleVisible: true,
		})
	}

	const onRecycleClose = () => {
		setRecycleVisible(false);
	}

	const onDeliver = async () => {
		sessionStorage.setItem(CHECKED_PRODUCT_LIST_KEY, encrypt([state.orderInfo]));
		navigate(`/select-address`);
	}

	const onAfterRecycled = () => {
		setRecycleVisible(false);
		afterRecycled && afterRecycled();
	}

	const onAnimateFinished = () => {
		setState({
			animateFinished: true,
		})
	}

	useEffect(() => {
		if (!!state.productId) {
			if (state.animateFinished) {
				if (!ifTryFree) {
					const idList = [state.boxId];

					boxRecycleCount(idList).then(res => {
						if (res?.data) {
							setState({
								recycleTotalAmount: res?.data?.recycleTotalAmount
							})
						}
					})
				}
				setState({
					opened: true,
					openBoxLoading: false,
					animateFinished: false
				})
			}
		} else {
			setState({
				openBoxLoading: false,
				animateFinished: false,
			})
		}
	}, [state.productId, state.animateFinished])

	return (
		<FullScreenContainer className="bb-open-box">
			{
				state.opened ?
					<div className="opened-box-container">
						<div className="close-btn-wrapper">
							<div onClick={onOpenedClose} className="close-btn">×</div>
						</div>
						<p className="title">{t('bb.value-of-item')}:&nbsp;<Coin amount={state.productAmount} /></p>
						<Image src={state.detailImg} width={207} height={178}/>
						<p className="opened-box-product-name">{state.orderInfo.name}</p>
						{
							!ifTryFree &&
							<div className="opened-btn-group">
								<Button fill="outline" onClick={onDeliver}>{t('bb.deliver')}</Button>
								{
									onOneMoreTime &&
									<Button onClick={onOneMoreTime}>{t('bb.one-more-time')}</Button>
								}
							</div>
						}
						{
							!ifTryFree &&
							<Button
								fixed
								size="large"
								wrapperPadding="0 24px"
								onClick={onRecycle}
								className="open-box-recycle-btn"
							>
								<Trans i18nKey="bb.recycle-some-money">
									<Coin amount={state.recycleTotalAmount} />
								</Trans>
							</Button>
						}
					</div>
					:
					<div className="wait-open-box-container">
						<p className="wait-open-box-name">{boxName}</p>
						<OpenBoxAnimate
							play={state.openBoxLoading}
							onFinished={onAnimateFinished}
							boxImage={boxImage}
						/>
						<div className="btn-group">
							{
								!ifTryFree &&
								<Button fill="outline" onClick={onOpenItLater} disabled={state.openBoxLoading}>{t('bb.open-it-later')}</Button>
							}
							<Button onClick={onOpenBox} loading={state.openBoxLoading} disabled={state.opened}>{t('bb.open-box')}</Button>
						</div>
						{
							!ifTryFree &&
							<p className="tips">{t('bb.if-later-open-in-my-box')}</p>
						}
					</div>
			}
			<ItemRecycler
				recycleTotalAmount={state.recycleTotalAmount}
				dataSource={state.recycleData}
				visible={state.recycleVisible}
				onClose={onRecycleClose}
				onAgreementSwitch={setRecycleVisible}
				afterRecycled={onAfterRecycled}
			/>
		</FullScreenContainer>
	)

}

export default OpenBox;