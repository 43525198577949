import React, { useEffect, useRef } from "react";
import { Image } from "antd-mobile";

// @ts-ignore
import openBoxIcon from '../../assets/open.svga';

import './index.scss';

interface IProps {
	play: boolean
	onFinished: () => void
	boxImage: string
}

interface IPlayer {
	loops: number
	setContentMode: (arg: any) => void
	setVideoItem: (arg: any) => void
	onFinished?: (arg: any) => void
	startAnimation?: () => void
}

const OpenBoxAnimate = (props: IProps) => {
	const playerRef = useRef<IPlayer>(null);

	const init = () => {
		const player: IPlayer = new SVGA.Player('#open-box-canvas');
		const parser = new SVGA.Parser('#open-box-canvas'); // Must Provide same selector eg:#demoCanvas IF support IE6+
		parser.load(openBoxIcon, function(videoItem) {
			player.loops = 1;
			player.setContentMode("Fill");
			player.setVideoItem(videoItem);
			playerRef.current = player;
		})
	}

	useEffect(() => {
		init()
	}, [])

	useEffect(() => {
		if (props.play && playerRef.current) {
			playerRef.current.startAnimation();
			playerRef.current.onFinished(() => {
				props.onFinished();
			})
		}
	}, [props.play])

	return (
		<div className="open-box-container">
			<Image src={props.boxImage} className={`open-box-image ${props.play ? 'hide' : ''}`} />
			<canvas id="open-box-canvas" width={216} height={180} className={props.play ? 'show-animate' : 'hide-animate'} />
		</div>
	);
}

export default OpenBoxAnimate;