import React from "react";
import { Ellipsis, Image } from "antd-mobile";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import { useCountDown } from "ahooks";
import './common.scss';
import Coin from "@/components/Coin";

const btnStyle = {
  height: 30,
  minWidth: 81,
  fontSize: 12,
  fontWeight: 'normal',
  '--border-width': '1px'
}

interface IProps {
  item: any
  type: 'wait-pay' | 'done' | 'closed'
  onCancelOrder?: (arg: string) => void
  onPayOrder?: (arg: string) => void
  onTimeOut?: () => void
  cancelOrderLoading?: any
}

const Common = (props: IProps) => {
  const { t } = useTranslation();
  const { item, type, onCancelOrder, onPayOrder, onTimeOut, cancelOrderLoading } = props;

  const leftTime = type === "wait-pay" ? item.expireTime - item.ts : 0;
  const [, formattedRes] = useCountDown({ leftTime, onEnd: onTimeOut });
  const { hours, minutes, seconds } = formattedRes;

  const renderTitle = () => {
    switch (type) {
      case 'wait-pay':
        return t("bb.pending-pay");
      case 'closed':
        return t('bb.closed');
      case 'done':
        return t('bb.done')
    }
  }

  const onCancel = () => {
    onCancelOrder && onCancelOrder(item.id)
  }

  const onPay = () => {
    onPayOrder && onPayOrder(item)
  }

  return (
    <div className="common-order-items">
      <div className="order-items-header">
        <span>{item.date}</span>
        <span>{renderTitle()}</span>
      </div>
      <div className="order-items-wrapper">
        <div className="order-items-info">
          <div className="order-items-image">
            <Image src={item.pic} width={80} height={80}/>
            <Ellipsis className="item-name mt11" content={item.name} />
          </div>
          <Coin amount={item.price} className="order-items-price mt11"/>
        </div>
        {
          type === 'wait-pay' &&
          <div className="btn-group">
            <Button
              fill="outline"
              color="warning"
              style={{...btnStyle}}
              onClick={onCancel}
              loading={cancelOrderLoading[item.id]}
            >
              {t('bb.cancel-order')}
            </Button>
            <Button
              color="warning"
              style={{...btnStyle}}
              onClick={onPay}
            >
              {t('bb.pay')}
            </Button>
          </div>
        }
      </div>
      {
        type === 'wait-pay' && leftTime > 0 &&
        <p>
          {t('bb.the-order-closes-in-minutes', {time: `${hours}:${minutes}:${seconds}`})}
        </p>
      }
      <p>{t('bb.order-number')}: {item.orderNo}</p>
    </div>
  )

}

export default Common;