import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

export type loginType = "password" | "code"

interface ILoginData {
	loginType: loginType
	codeType: "email" | "mobile"
}

const p = '/api/login/';

// 登出
export const logout = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'logOut',
		method: 'post',
	}))
}

// 登入
export const login = async (data: ILoginData): Promise<any> => {
	const loginByPwd = 'loginByPwd'; // 手机号/邮箱 密码登录
	const mobileLoginByCode = 'mobile/loginByCode'; // 手机验证码登录
	const emailLoginByCode = 'email/loginByCode'; // 邮箱验证码登录

	let url: string;

	if (data.loginType === "password") {
		url = p + loginByPwd
	} else {
		if (data.codeType === "mobile") {
			url = p + mobileLoginByCode
		} else {
			url = p + emailLoginByCode
		}
	}

	return tryCatch(request({
		url,
		method: 'post',
		data: {
			...data,
			loginDeviceExtJson: window.navigator.userAgent
		}
	}));
}
