import React from "react";
import { useNavigate } from "react-router-dom";
import { 
  LinkIcon, AboutIcon, OrderIcon,
  BoxIcon, AddressIcon, ServiceIcon,
  SettingIcon, FaqIcon, FeedbackIcon,
  AgreementIcon, PrivacyIcon, LangIcon
 } from "@/components/SvgIcon";
import './subNav.scss';
import { useTranslation } from "react-i18next";

const itemList = [
  {
    icon: <OrderIcon />,
    title: 'bb.my-orders',
    path: '/my-orders',
    needLogin: true
  },
  {
    icon: <BoxIcon />,
    title: 'bb.my-boxes',
    path: '/my-boxes',
    needLogin: true
  },
  {
    icon: <AddressIcon />,
    title: 'bb.address',
    path: '/address',
    needLogin: true
  },
  // {
  //   icon: <AboutIcon />,
  //   title: 'bb.about-us',
  //   path: '/about'
  // },
  {
    icon: <ServiceIcon />,
    title: 'bb.contact-customer-service',
    path: '/contact'
  },
  // {
  //   icon: <FaqIcon />,
  //   title: 'bb.faq',
  //   path: '/faq'
  // },
  {
    icon: <AgreementIcon />,
    title: 'bb.agreement-title',
    path: '/agreement'
  },
  {
    icon: <FeedbackIcon />,
    title: 'bb.problem-feedback',
    path: '/feedback',
    needLogin: true
  },
  {
    icon: <SettingIcon />,
    title: 'bb.setting',
    path: '/setting',
    needLogin: true
  },
  {
    icon: <PrivacyIcon />,
    title: 'bb.privacy-title',
    path: '/privacy',
  },
  {
    icon: <LangIcon />,
    title: 'bb.change-lang',
    path: '/select-country',
  },
]

interface IProps {
  isLogin: boolean
}

const SubNav = (props: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = (item: any) => {
    if (!props.isLogin && item.needLogin) {
      navigate('/login');
      return;
    }
    navigate(item.path);
  }

  return (
    <div className="mine-sub-nav">
      {
        itemList.map(item => (
          <div className="sub-nav-item" key={item.path} onClick={() => handleClick(item)}>
            <div className="left">
              <span className="icon">{item.icon}</span>
              <span>{t(item.title)}</span>
            </div>
            <LinkIcon />
          </div>
        ))
      }
    </div>
  )
}

export default SubNav;