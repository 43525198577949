import React from "react";
import Common from "./Common";
import { BoxStatusEnum } from "@/interfaces";
import Empty from "@/components/Empty";
import { waitDeliverAdapt } from "@/utils/biz";

interface IProps {
  dataSource: any[]
}

const Delivered = (props: IProps) => {
  const formattedData = waitDeliverAdapt(props.dataSource);

  return (
    <>
      {
        formattedData.length === 0 ?
          <Empty /> :
          <div className="delivered-wrapper">
            {
              formattedData.map(item => (
                <Common item={item} type={BoxStatusEnum.delivered} key={item.id} />
              ))
            }
          </div>
      }
    </>
  )

}

export default Delivered;