import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

const p = '/api/box/';

// 开盒
export const openBox = (orderNo): Promise<any> => {
	return tryCatch(request({
		url: p + 'openMybox',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			orderNo
		}
	}))
}

// 获取盲盒详细信息-包含盒子商品明细
export const getBoxInfo = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryInfo',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 获取推荐盒子列表
export const getRecommendBoxList = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'recommend/queryPage',
		method: 'post',
		data
	}))
}

// 根据分类获取盒子列表
export const getBoxListByType = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryPage',
		method: 'post',
		data
	}))
}



