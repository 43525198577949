import React from "react";
import Modal from "@/components/Modal";
import Button from '@/components/Button';

import './alert.scss';
import { useTranslation } from "react-i18next";

interface IProps {
	visible?: boolean
	title: string
	onOk?: () => void
	onCancel: () => void
}

const CancelOrderAlert = (props: IProps) => {
	const { visible, title, onOk, onCancel } = props;
	const { t } = useTranslation();

	return (
		<Modal visible={visible} className="cancel-order-alert-modal">
			<p className="cancel-order-alert-title">{title}</p>
			<div className="btn-group">
				<Button onClick={onOk} fill="outline">{t('bb.yes')}</Button>
				<Button onClick={onCancel}>{t('bb.no')}</Button>
			</div>
		</Modal>
	)

}
export default CancelOrderAlert;