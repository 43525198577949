import React from "react";
import { BalanceIcon, TopUpIcon, TransactionsIcon } from "@/components/SvgIcon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './accountCard.scss';
import Coin from "@/components/Coin";

interface IProps {
  balance: number
  isLogin: boolean
}

const AccountCard = (props: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const onItemClick = (type?: string) => {
    if(!props.isLogin) {
      navigate('/login')
    } else {
      type && navigate(type);
    }
  }

  return (
    <div className="mine-account-card">
      <div className="blur-block">
        <div className="left" />
        <div className="center" />
        <div className="right" />
      </div>
      <div className="item-wrapper">
        <div className="item" onClick={() => onItemClick()}>
          <BalanceIcon />
          <Coin amount={props.balance || 0} className="text"/>
        </div>
        <div className="item" onClick={() => onItemClick('/transactions')}>
          <TransactionsIcon />
          <p className="text">{t('bb.transactions')}</p>
        </div>
        <div className="item" onClick={() => onItemClick('/top-up')}>
          <TopUpIcon />
          <p className="text">{t('bb.top-up')}</p>
        </div>
      </div>
    </div>
  )
}

export default AccountCard;