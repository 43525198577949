import React from "react";
import { TextArea as AdmTextArea, TextAreaProps } from "antd-mobile";
import cn from 'classnames';

import './index.scss';

interface IProps extends TextAreaProps {
	type?: 'normal' | 'block'
}

const TextArea = (props: IProps) => {
	const { className, type = 'normal', ...others } = props;
	return (
		<AdmTextArea
			className={cn('bb-text-area', className, type)}
			{...others}
		/>
	)
}

export default TextArea;