import React from "react";
import { NavBar, NavBarProps } from "antd-mobile";
import { useNavigate, useLocation, matchRoutes } from "react-router-dom";
import { BackIcon } from "@/components/SvgIcon";
import { useTranslation } from "react-i18next";

import './nav.scss';
import { PIXI_UA } from "@/interfaces";

interface IProps extends NavBarProps {
  routes: any
}

const Nav = (props: IProps) => {
  const { routes } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentRoute = matchRoutes(routes, location) || [];

  // @ts-ignore
  const { title, NavRight, hideNav } = currentRoute[0]?.route || {};

  const ua = window.navigator.userAgent;

  if (hideNav || ua.includes(PIXI_UA)) {
    return null;
  }

  if (NavRight) {
    return (
      <NavBar
        className="bb-nav-bar bb-nav-bar-id"
        onBack={() => navigate(-1)}
        right={<NavRight />}
        backArrow={<BackIcon />}
      >
        {t(title)}
      </NavBar>
    )
  }

  return (
    <NavBar className="bb-nav-bar bb-nav-bar-id" onBack={() => navigate(-1)}  backArrow={<BackIcon />}>
      {t(title)}
    </NavBar>
  )

}

export default Nav;