import React, { useEffect } from "react";
import Button from "@/components/Button";
import './index.scss';
import { useTranslation } from "react-i18next";
import { logout } from "@/service/login";
import { useNavigate } from "react-router-dom";
import {
  useBalanceStore, BalanceState, UserState,
  useUserInfoStore, useContactStore, ContactState, useIndexPopupStore, IndexPopupState
} from "@/store";
import { ACCESS_TOKEN } from "@/interfaces";

const Setting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfo = useUserInfoStore((state: UserState) => state.userInfo);
  const fetch = useUserInfoStore((state: UserState) => state.fetch);
  const resetBalance = useBalanceStore((state: BalanceState) => state.reset);
  const resetUserInfo = useUserInfoStore((state: UserState) => state.reset);
  const resetContact = useContactStore((state: ContactState) => state.reset);
  const indexPopup = useIndexPopupStore((state: IndexPopupState) => state.indexPopup);

  const handleLogout = async () => {
    try {
      await logout();
    } catch {}
    resetBalance();
    resetUserInfo();
    resetContact();
    sessionStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    navigate('/');
  }

  useEffect(() => {
    if (!userInfo.isLogin) {
      fetch();
    }
  }, [userInfo.isLogin])

  return (
    <div className="setting-wrapper">
      <div className="avatar">
        <div className="icon"/>
      </div>
      <div className="setting-info">
        <div>{t('bb.phone-or-email')}: {userInfo.mobile || userInfo.email}</div>
        <div>{t('bb.version')}: {indexPopup.version}</div>
      </div>
      <Button
        fixed
        wrapperPadding={"0 24px"}
        size="large"
        fill="outline"
        onClick={handleLogout}
      >
        {t('bb.logout')}
      </Button>
    </div>
  )
}

export default Setting;