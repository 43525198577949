import React, { useState } from "react";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { InputProps } from "antd-mobile";
import { useCountDown } from 'ahooks';
import { useTranslation } from "react-i18next";

import './index.scss';

interface IProps extends InputProps {
  onSend: (arg: any) => void
  btnDisabled?: boolean
  style?: React.CSSProperties
}

const VerifyCodeInput = (props: IProps) => {
  const { t } = useTranslation();
  const { onSend, btnDisabled, style, ...others } = props;
  const [targetDate, setTargetDate] = useState(0)

  const [countdown] = useCountDown({
    targetDate,
  });

  const handleSendCode = () => {
    onSend(() => {
      setTargetDate(Date.now() + 60000)
    });
  }

  return (
    <div className="bb-verify-code-input" style={style}>
      <Input {...others} />
      <Button onClick={handleSendCode} disabled={countdown !== 0 || btnDisabled} size="mini">
        {countdown === 0 ? t('bb.get-verification-code') : `${Math.round(countdown / 1000)}s`}
      </Button>
    </div>
  )

}

export default VerifyCodeInput;