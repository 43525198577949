import React from "react";
import { useTranslation } from "react-i18next";

const Privacy = (props) => {
	const { t } = useTranslation();
	const styles = {
		padding: "0 18px",
		fontSize: 16,
		...props.style
	}

	return (
		<div style={styles}>
			<div dangerouslySetInnerHTML={{__html: t('bb.privacy-content')}} />
		</div>
	)
}

export default Privacy;