import Contact from "@/pages/Contact";
import Home from "@/pages/Home";
import List from "@/pages/List";
import Mine from "@/pages/Mine";
import Setting from "@/pages/Setting";
import TopUp from "@/pages/TopUp";
import BalanceRight from "@/bizComponents/NavRight/Balance";
import BoxDetail from "@/pages/BoxDetail";
import Address from "@/pages/Address";
import AddressInfo from "@/pages/AddressInfo";
import Transactions from "@/pages/Transactions";
import TransactionsRight from "@/bizComponents/NavRight/Transcation";
import Login from "@/pages/Login";
import WithdrawRight from "@/bizComponents/NavRight/Withdraw";
import Withdraw from "@/pages/Withdraw";
import WithdrawRecord from "@/pages/WithdrawRecord";
import NotFount from "@/components/NotFount";
import Feedback from "@/pages/Feedback";
import MyBoxes from "@/pages/MyBoxes";
import MyOrders from "@/pages/MyOrders";
import SelectAddress from "@/pages/SelectAddress";
import Rule from "@/pages/Rule";
import Contract from "@/pages/Agreement";
import SelectCountry from "@/pages/SelectCountry";
import Privacy from "@/pages/Privacy";
import ActivityA from "@/pages/ActivityA";
import ActivityB from "@/pages/ActivityB";
import ActivityC from "@/pages/ActivityC";

interface IRoutes {
  path: string,
  element: any,
  title?: string
  NavRight?: any
  hideNav?: boolean
}

const routes: IRoutes[] = [
  {
    path: '*',
    element: NotFount,
  },
  {
    path: '/',
    element: Home,
    hideNav: true,
  },
  {
    path: '/login',
    element: Login,
    hideNav: true,
  },
  {
    path: '/mine',
    title: 'bb.mine',
    element: Mine,
    hideNav: true,
  },
  {
    path: '/list',
    element: List,
  },
  {
    path: '/setting',
    title: 'bb.setting',
    element: Setting,
  },
  {
    path: '/contact',
    title: 'bb.contact-customer-service',
    element: Contact,
  },
  {
    path: '/top-up',
    element: TopUp,
    NavRight: BalanceRight
  },
  {
    path: '/detail/:id',
    element: BoxDetail,
    title: 'bb.box-detail',
    NavRight: BalanceRight
  },
  {
    path: '/address',
    element: Address,
    title: 'bb.address'
  },
  {
    path: '/address-box',
    element: Address,
    title: 'bb.address'
  },
  {
    path: '/address/add',
    element: AddressInfo,
    title: 'bb.add-address-2'
  },
  {
    path: '/address/:id',
    element: AddressInfo,
    title: 'bb.address-detail',
  },
  {
    path: '/transactions',
    element: Transactions,
    title: 'bb.transactions',
    NavRight: TransactionsRight,
  },
  {
    path: '/withdraw',
    element: Withdraw,
    title: 'bb.withdraw',
    NavRight: WithdrawRight
  },
  {
    path: '/withdraw-record',
    element: WithdrawRecord,
    title: 'bb.withdraw-record',
  },
  {
    path: '/feedback',
    element: Feedback,
    title: 'bb.problem-feedback'
  },
  {
    path: '/my-boxes',
    element: MyBoxes,
    title: 'bb.my-boxes'
  },
  {
    path: '/my-orders',
    element: MyOrders,
    title: 'bb.my-orders'
  },
  {
    path: '/select-address',
    element: SelectAddress,
    title: 'bb.order-detail'
  },
  {
    path: '/rule',
    element: Rule,
    title: 'bb.rules-of-the-game'
  },
  {
    path: '/agreement',
    element: Contract,
    title: 'bb.agreement-title'
  },
  {
    path: '/select-country',
    element: SelectCountry,
    hideNav: true,
  },
  {
    path: '/privacy',
    element: Privacy,
    title: 'bb.privacy-title'
  },
  {
    path: '/activity-a',
    element: ActivityA,
  },
  {
    path: '/activity-b',
    element: ActivityB,
  },
  {
    path: '/activity-c',
    element: ActivityC,
  },

]

export default routes;