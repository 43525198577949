import { create } from 'zustand';
import { getContact } from "@/service/dict";

interface ContactState {
	info: any
	fetch: () => void
	reset: () => void
}

const useContactStore = create((set): ContactState => ({
	info: {},
	fetch: async () => {
		const res = await getContact();
		let parsedData = {
			email: "",
			timeRange: "",
			whatsApp: "",
			whatsApp_link: ""
		};
		if (res?.data) {
			try {
				parsedData = JSON.parse(res?.data || '{}');
			} catch {}
		}
		set({
			info: parsedData
		})
	},
	reset: () => {
		set({
			info: {}
		})
	}
}))

export {
	useContactStore,
	ContactState,
};