import { create } from 'zustand';
import { getUserInfo } from "@/service/user";

interface UserState {
	userInfo: any
	fetch: () => void
	reset: () => void
}

const useUserInfoStore = create((set): UserState => ({
	userInfo: {},
	fetch: async () => {
		try {
			const res = await getUserInfo();
			set({
				userInfo: {
					mobile: res?.data?.mobile,
					email: res?.data?.email,
					isLogin: true
				}
			})
		} catch {
			set({
				userInfo: {
					isLogin: false
				}
			})
		}
	},
	reset: () => {
		set({
			userInfo: {
				isLogin: false
			}
		})
	}
}))

export {
	useUserInfoStore,
	UserState
};