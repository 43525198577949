import React from "react";
import { NotFoundIcon } from "../SvgIcon";
import './index.scss';

const NotFount = () => {

  return (
    <div className="bb-not-found">
      <NotFoundIcon />
    </div>
  )

}

export default NotFount;