import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, NavBar } from "antd-mobile";
import FullScreenContainer from "@/components/FullScreenContainer";
import Agreement from "@/pages/Agreement";
import Privacy from "@/pages/Privacy";
import './index.scss';


interface IProps {
	onChecked?: (args: boolean) => void
	onAgreementSwitch?: (args: boolean) => void
	showPrivacy?: boolean
}

const AgreementModal = (props: IProps) => {
	const { t } = useTranslation();
	const [checked, setChecked] = useState(false);
	const [visible, setVisible] = useState(false);
	const [type, setType] = useState('agreement');
	const { onChecked, onAgreementSwitch, showPrivacy = true } = props;

	const privacy = t('bb.privacy');
	const agreement = t('bb.agreement');

	const handleChange = () => {
		setChecked(!checked);
		onChecked && onChecked(!checked);
	}
	const onBack = () => {
		setVisible(false);
		onAgreementSwitch && onAgreementSwitch(true);
	}

	const onClickLink = (e, type) => {
		e.preventDefault();
		setVisible(true);
		setType(type);
		onAgreementSwitch && onAgreementSwitch(false);
	}

	return (
		<>
			<Checkbox
				className="bb-agree-checkbox"
				onChange={handleChange}
				checked={checked}
			>
				{
					showPrivacy ?
					<Trans i18nKey="bb.agree-agreement-and-privacy" shouldUnescape>
						<span
							onClick={(e) => onClickLink(e, 'agreement')}
							className="link"
						>
							{{agreement}}
						</span>
						<span
							onClick={(e) => onClickLink(e, 'privacy')}
							className="link"
						>
							{{privacy}}
						</span>
					</Trans> :
					<div>
						{t('bb.agree')}
						<span
							onClick={(e) => onClickLink(e, 'agreement')}
							className="link"
						>
							{agreement}
						</span>
					</div>
				}

			</Checkbox>
			{
				visible &&
				<FullScreenContainer
					lock={false}
					className="contract-fake-container"
					style={{
						height: 'auto'
					}}
				>
					<NavBar
						className="contract-fake-nav-bar"
						onBack={onBack}
					>
						{type === 'agreement' ? t('bb.agreement-title') : t('bb.privacy-title')}
					</NavBar>
					{
						type === 'agreement' ?
						<Agreement style={{marginTop: 45}} />:
						<Privacy style={{marginTop: 45}} />
					}
				</FullScreenContainer>
			}
		</>
	)
}

export default AgreementModal;