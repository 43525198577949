import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ES_MX, ZH_CN, EN, PT_BR } from "@/locales";
import { LangType } from "@/interfaces";
import { setDefaultLang } from "@/utils/biz";

const lang = setDefaultLang();

const options = {
	resources: {
		ES_MX,
		ZH_CN,
		EN,
		PT_BR
	},
	lng: lang || LangType.ES_MX,
	fallbackLng: lang || LangType.ES_MX,
}

i18n
	.use(initReactI18next)
	.init(options as any);

export default i18n;