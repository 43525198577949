import React, { useState } from "react";
import Input from "@/components/Input";
import TextArea from "@/components/TextArea";
import Button from "@/components/Button";
import { ImageUploader, Toast } from 'antd-mobile';
import { useTranslation } from "react-i18next";
import { uploadFile } from "@/service/common";
import { submitFeedback } from "@/service/feedback";
import './index.scss';
import { emailPattern, phonePattern } from "@/interfaces";

const Feedback = () => {
  const { t } = useTranslation();
  const [imageList, setImageList] = useState([]);
  const [feedbackContent, setFeedbackContent] = useState('');
  const [contactInfo, setAccountValue] = useState('');

  const maxCount = 6;
  const FILE_TYPE = 3;
  const MAX_SIZE = 10 * 1024 * 1024;

  const onBeforeUpload = (file: File) => {
    if (file.size > MAX_SIZE) {
      Toast.show({
        content: t('bb.the-picture-is-too-large'),
        duration: 1000
      });
      return ;
    }
    return file;
  }

  const onUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('fileParam', file);
    formData.append("fileType", JSON.stringify(FILE_TYPE));

    const res = await uploadFile(formData);

    return {
      url: res.data,
    }
  }

  const onConfirm = () => {
    if (contactInfo.includes('@')) {
      if (!emailPattern.test(contactInfo)) {
        Toast.show({
          content: t('bb.input-format-wrong'),
          duration: 2000
        });
        return;
      }
    } else {
      if (!phonePattern.test(contactInfo)) {
        Toast.show({
          content: t('bb.input-format-wrong'),
          duration: 2000
        });
        return;
      }
    }
    submitFeedback({
      imageList: imageList.map(d => d.url),
      feedbackContent,
      contactInfo
    }).then(res => {
      if (res?.code === 0) {
        Toast.show({
          content: t('bb.feedback-successfully'),
          duration: 1000,
          afterClose: () => {
            setImageList([]);
            setFeedbackContent('');
            setAccountValue('')
          }
        })
      }
    })
  }

  return (
    <div className="feedback-wrapper">
      <p className="input-title">{t('bb.please-input-suggestion-or-problem')}</p>
      <TextArea
        type="block"
        className="feedback-input"
        value={feedbackContent}
        onChange={setFeedbackContent}
        maxLength={500}
        showCount
        autoSize={{
          minRows: 2,
          maxRows: 6
        }}
      />
      <p className="upload-title">{t('bb.upload-screenshot')}</p>
      <ImageUploader
        className="feedback-img-upload"
        beforeUpload={onBeforeUpload}
        value={imageList}
        onChange={setImageList}
        upload={onUpload}
        multiple
        maxCount={maxCount}
        showUpload={imageList.length < maxCount}
      />
      <p className="account-title">{t('bb.your-email-or-phone')}</p>
      <Input
        className="account-input"
        value={contactInfo}
        onChange={setAccountValue}
      />
      <Button
        className="confirm-btn"
        onClick={onConfirm}
        size="large"
        disabled={!(feedbackContent && contactInfo)}
      >
        {t('bb.submit')}
      </Button>
    </div>
  )

}

export default Feedback;