import { LangType } from "@/interfaces";
import i18n from "@/i18n";

const dbDigit = (d: number) => d < 10 ? `0${d}` : d;

export const tsToTime = (ts: number) => {
	const date = new Date(ts);
	const year = date.getFullYear();
	const month = dbDigit(date.getMonth() + 1);
	const day = dbDigit(date.getDate());
	const hour = dbDigit(date.getHours());
	const minutes = dbDigit(date.getMinutes());
	const seconds = dbDigit(date.getSeconds());

	if (i18n.language === LangType.ZH_CN || i18n.language === LangType.EN) {
		return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
	}

	return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`
}