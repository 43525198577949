import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

const p = '/api/dict/';

// 首页跑马灯
export const getHorseRaceLamp = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'horseRaceLamp',
		method: 'post',
		headers: {
			'access-token': true
		}
	}))
}

// 首页盒子分类
export const getIndexBoxType = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'indexBoxType',
		method: 'post',
	}))
}

// 所有盒子分类
export const getAllBoxType = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'allBoxType',
		method: 'post',
	}))
}
// 联系客服
export const getContact = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'contact',
		method: 'post',
	}))
}

// 首页导航
export const getNavigation = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'navigation',
		method: 'post',
		headers: {
			'access-token': true
		}
	}))
}

// 首页弹层
export const getIndexPopup = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'indexPopup',
		method: 'post',
	}))
}

// 获取充值配置
export const getRechargeConfig = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'rechargeConfig',
		method: 'post',
	}))
}

// 获取充值推荐
export const getRechargeAmountLimit = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'rechargeAmountLimit',
		method: 'post',
	}))
}