import React, { useEffect, useState } from "react";
import { SwipeAction as AdmSwipeAction, Checkbox } from "antd-mobile";
import { DeleteIcon, EditIcon } from "../../components/SvgIcon";
import './index.scss';
import { CHECKED_ADDRESS_ITEM_KEY } from "@/interfaces";
import { decrypt } from "@/utils/util";

interface IProps {
  dataSource: any[]
  onDelete: (args: any) => void
  onEdit?: (args: any) => void
  showCheckbox?: boolean
  onChecked?: (args: any) => void
}

const SwipeAction = (props: IProps) => {
  const { dataSource, onDelete, onEdit, showCheckbox = false, onChecked } = props;
  const [checkId, setCheckedId] = useState(null);

  const handleChecked = (item: any) => {
    setCheckedId(item.id);
    onChecked && onChecked(item);
  }

  const handleEdit = (item: any) => {
    onEdit && onEdit(item);
  }

  const CheckboxChildren = (item) => {
    return (
      <div style={{marginRight: 10}}>
        <p className="name-info">
          <span>{item.receiveName}</span>
          <span>{item.receiveMobile}</span>
        </p>
        <p className="address-info">{item.receiveAddress}</p>
      </div>
    )
  }

  useEffect(() => {
    const selectedAddress = decrypt(sessionStorage.getItem(CHECKED_ADDRESS_ITEM_KEY), {});
    handleChecked(selectedAddress);
  }, [])

  return (
    <>
      {
        dataSource.map(item => (
          <AdmSwipeAction
            className="bb-swipe-action"
            key={item.id}
            rightActions={[
              {
                key: 'null',
                text: '',
              },
              {
                key: 'delete',
                text: <DeleteIcon />,
                color: 'danger',
                onClick: () => onDelete(item)
              },
            ]}
          >
            <div className="bb-swipe-action-item">
              {
                showCheckbox ?
                  <Checkbox onChange={() => handleChecked(item)} checked={checkId === item.id}>
                    {CheckboxChildren(item)}
                  </Checkbox> : CheckboxChildren(item)
              }
              <EditIcon onClick={() => handleEdit(item)} style={{flex: 'none'}}/>
            </div>
          </AdmSwipeAction>
        ))
      }
    </>
  )
}

export default SwipeAction;