import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getWithdrawList } from "@/service/account";
import { useSetState } from "ahooks";
import { withdrawRecordAdapt } from "@/utils/biz";
import { WithdrawStatus } from "@/interfaces";
import './index.scss';
import PageContainer from "@/bizComponents/PageContainer";
import { InfiniteScroll } from "antd-mobile";
import { removeRepeat } from "@/utils/util";
import Coin from "@/components/Coin";

interface IProps {
  applyTime: string
  tradeAmount: number
  status: string
  processTime: string
  id: number
}

const WithdrawRecord = () => {
  const { t } = useTranslation();
  const [state, setState] = useSetState({
    dataSource: [],
    loading: false,
    hasMore: false,
    pageNum: 1,
    pageSize: 10,
  })

  const withdrawStatusMap = {
    [WithdrawStatus.applied]: t('bb.applied'),
    [WithdrawStatus.processing]: t('bb.processing'),
    [WithdrawStatus.succeed]: t('bb.withdraw-success'),
    [WithdrawStatus.failed]: t('bb.withdraw-failed'),
  }

  const loadMore = async () => {
    const res = await getWithdrawList({
      pageNum: state.pageNum,
      pageSize: state.pageSize
    });
    if (res?.data?.list) {
      const append = withdrawRecordAdapt(res?.data?.list);
      setState({
        dataSource: removeRepeat([...state.dataSource, ...append]),
        hasMore: !res?.data?.isLastPage,
        pageNum: res?.data?.nextPage
      })
    }
  }

  const getData = async () => {
    setState({
      loading: true
    })
    const res = await getWithdrawList({
      pageNum: 1,
      pageSize: state.pageSize
    });
    const dataSource = withdrawRecordAdapt(res?.data?.list);

    setState({
      dataSource,
      loading: false,
      hasMore: !res?.data?.isLastPage,
      pageNum: res?.data?.nextPage
    })
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <PageContainer loading={state.loading} data={state.dataSource}>
      <div className="withdraw-record-wrapper">
        {
          (state.dataSource as IProps[]).map(item => (
            <div className="record-item-card" key={item.id}>
              <p className="apply-time">{t('bb.apply-time')}: {item.applyTime}</p>
              <div className="border-line" />
              <div className="amount-wrapper">
                <Coin amount={item.tradeAmount} size="large" className="withdraw-record-coin"/>
                <p className="status">{withdrawStatusMap[item.status]}</p>
              </div>
              <div className="extra-info">
                <p className="title">{t('bb.withdraw-amount')}</p>
                {
                  item.processTime &&
                  <p className="process-time">{t('bb.process-time')}: {item.processTime}</p>
                }
              </div>
            </div>
          ))
        }
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={state.hasMore} threshold={0} />
    </PageContainer>
  )

}

export default WithdrawRecord;