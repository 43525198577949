import React, { useEffect } from "react";

// @ts-ignore
import loadingIcon from '../../assets/loading.svga';

import './index.scss';

interface IProps {
	fullscreen?: boolean
}
interface IPlayer {
	loops: number
	setContentMode: (arg: any) => void
	setVideoItem: (arg: any) => void
	onFinished?: (arg: any) => void
	startAnimation?: () => void
}

const Loading = (props: IProps) => {
	const { fullscreen } = props;

	const init = () => {
		const player: IPlayer = new SVGA.Player('#loading-canvas');
		const parser = new SVGA.Parser('#loading-canvas'); // Must Provide same selector eg:#demoCanvas IF support IE6+
		parser.load(loadingIcon, function(videoItem) {
			player.setContentMode("AspectFit");
			player.setVideoItem(videoItem);
			player.startAnimation();
		})
	}

	useEffect(() => {
		init()
	}, [])

	return (
		<div
			id="loading-container"
			className={`bb-loading-container ${fullscreen ? 'fullscreen' : ''}`}
		>
			<canvas id="loading-canvas" width={54} height={54}/>
		</div>
	);
}

export default Loading;