import React, { useEffect } from "react";
import Swiper from "@/components/Swiper";
import AccountCard from "./components/AccountCard";
import SubNav from "./components/SubNav";
import { useTranslation } from "react-i18next";
import { TouristAvatarIcon, UserAvatarIcon } from "@/components/SvgIcon";
import { getBanner } from "@/service/common";
import { BannerPositionEnum, IState } from "@/interfaces";
import { useSetState } from "ahooks";
import { bannerListAdapt } from "@/utils/biz";
import { useNavigate } from "react-router-dom";
import './index.scss';
import { useUserInfoStore, UserState, BalanceState, useBalanceStore } from "@/store";
import { Ellipsis } from "antd-mobile";

const Mine = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const balance = useBalanceStore((state: BalanceState) => state.balance);
  const fetch = useBalanceStore((state: BalanceState) => state.fetch);
  const userInfo = useUserInfoStore((state: UserState) => state.userInfo);
  const fetchUser = useUserInfoStore((state: UserState) => state.fetch);
  const [state, setState] = useSetState<IState>({
    banner: [],
    loading: false
  })

  const handleLogin = () => {
    if (!userInfo.isLogin) {
      navigate('/login')
    }
  }

  const getBannerList = async () => {
    const res = await getBanner(BannerPositionEnum.mine);
    if (res?.data) {
      setState({
        banner: bannerListAdapt(res?.data)
      })
    }
  }

  const loginAndContinue = async () => {
    setState({
      loading: true
    })
    fetchUser();
    if (balance === null) {
      fetch();
    }
    await getBannerList();
    setState({
      loading: false
    })
  }

  useEffect(() => {
    loginAndContinue()
  }, [])

  return (
    <div className="mine-wrapper">
      <div className="login-info" onClick={handleLogin}>
        {
          userInfo.isLogin ?
            <UserAvatarIcon /> : <TouristAvatarIcon />
        }
        <Ellipsis className="content" content={userInfo.mobile || userInfo.email || t('bb.login')} />
      </div>
      <AccountCard balance={balance} isLogin={userInfo.isLogin} />
      <Swiper dataSource={state.banner} />
      <SubNav isLogin={userInfo.isLogin}/>
    </div>
  )

}

export default Mine;