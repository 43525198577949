import React from "react";
import RFM from "react-fast-marquee";

import './index.scss';

interface IProps {
	dataSource: any[]
}

const Marquee = (props: IProps) => {
	return (
		<RFM className="bb-marquee-container">
			{
				props.dataSource.map((item, idx) => (
					<div key={idx} className="bb-marquee-item">
						{item.text}
					</div>
				))
			}
		</RFM>
	)

}
export default Marquee;