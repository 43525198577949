export const privacyContentEN = `
<p>At Pixibox, we recognize that your privacy is important. This Privacy Policy discloses the privacy practices for Pixibox as well as related products and services we may offer to you(collectively referred to as the “Services”).This Policy also covers how personal and other information that we receive or collect about you is treated.Please read the information below to learn the following regarding your use of this APP.</p>
<p>You acknowledge that this Privacy Policy is designed to be read in connection with the APP Terms and Conditions of Use, which is available at here, and that by accessing or using our APP, you agree to be bound by the APP’s terms and conditions, as well as this Policy.</p>
<p>We reserve the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our APP, and by updating any privacy information on this page.Your continued use of the APP and or Services available through this APP after such modifications will constitute your: (a) acknowledgment of the modified Policy; and(b) your agreement to abide and be bound by that Policy.</p>
<p>1. Types of Information We Collect</p>
<p>In order to better provide you with our services, we collect various types of information about our users identified below.</p>
<p>Personally Identifiable Information (PII). This refers to information that lets us know the specifics of “who you are”.When you engage in certain activities on this APP, such as register for an account, purchase points, product or service, post content in the user forums, sign up for our newsletters or email lists, or send us feedback, we, or our third- party payment processor may ask you to provide certain information about yourself.</p>
<p>Examples of PII may include your first and last name, email address, mailing address, billing address, telephone numbers  and other identifying information.</p>
<p>Aggregate Information.</p>
<p>This refers to information that does not by itself identify a specific individual. We gather certain information about you based upon where you visit on our APP and what other APP may have directed you to us.This information, which is collected in a variety of different ways, is compiled and analyzed on both a personal and an aggregated basis.This information may include the APP’s Uniform Resource Locator(“URL”) that points to the APP you just came from, which URL you go to after visiting our APP, what browser you are using, and your Internet Protocol(“IP”) address.</p>
<p>2. How We Collect and Use Information</p>
<p>Pixibox primary goals in collecting and using information is to create your account, provide the Services to you, improve the Service, contact you, and to create reports for internal use. We store the information on our servers that are located in Mexico.</p>
<p>We do not collect any PII about you unless you voluntarily provide it to us. However, you will be required to provide certain PII to us when you elect to use certain products or services available on the APP. These may include:</p>
<p>Registering for an account on our APP</p>
<p>Purchasing points on our app</p>
<p>Signing up for special offers from selected third parties</p>
<p>Sending us an email message or contacting us through other electronic means available through our app</p>
<p>Submitting a form or transmitting other information by telephone or letter; or</p>
<p>Ordering and purchasing products and services on our app.</p>
<p>When processing certain information, such as payment information with affiliated banking institutions or payment processors, we encrypt the transaction, using secure socket layer (ssl) encryption technology, in order to prevent your information from being stolen or intercepted.</p>
<p>We will primarily use your PII to provide product or service offerings to you. We will also use certain forms of PII to enhance the operation of our APP, improve our internal marketing and promotional efforts, statistically analyze APP use, improve our product and service offerings, and customize the content, layout, and services of our APP. We may use PII to deliver information to you and to contact you regarding administrative notices. Finally, we may use your PII to resolve disputes, troubleshoot problems and enforce our agreements with you, including our APP Terms of Use, and this Privacy Policy.</p>
<p>We may also collect, or our third party advertising partners may collect, certain Aggregate Information.For example, we may use your IP address to diagnose problems with our servers or software, to administer our APP and to gather demographic information.Our third party advertising partners may also provide us with aggregate, but not individual, reports that will tell us how many ads were presented and clicked upon at our APP, or certain behaviour patterns that occur when browsing through our APP.</p>
<p>3. Your Rights and Options</p>
<p>Right to delete personal data ("right to be forgotten"). In certain situations, such as if your personal data has been processed unlawfully or you have withdrawn your consent (if the processing of your personal data is based on consent), you have the right to request and obtain the deletion of your personal data from us, subject to certain exceptions. Please also note that any removal request does not ensure complete or complete removal of any content or information from the Service. For example, content you post may be republished or reposted by other users or third parties. Please contact us via email at pixiboxservicio@gmail.com to make a request regarding your rights. We will use reasonable efforts to respond to your request within 60 days of receiving the request. If we are unable to fulfill your request within 60 days, we will let you know the reason why and when we hope to fulfill your request.</p>
<p>4. Release of Information</p>
<p>We will not sell, trade, or rent your PII to others. We do provide some of our services and products through contractual arrangements made with affiliates, service providers, partners and other third parties (“Service Partners”).We and our Service Partners may need to use some PII in order to perform tasks between our respective APPs, or to deliver products or services to you.For example, our payment processor must collect your billing information; we must release your address information to the delivery service to deliver products that you’ve won successfully; and provide your order information to any third parties that help us provide customer service.</p>
<p>We will encourage our Service Partners to adopt and promote strong privacy policies. However, the use of your PII by our Service Partners is governed by the respective privacy policies of those providers, and is not subject to our control. Except as otherwise discussed in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. Other APPs accessible through this APP, including our Advertising and Service Partners, have their own privacy policies and data collection, use and disclosure practices. Please consult each APP’s privacy policy. We are not responsible for the policies or practices of third parties.</p>
<p>Occasionally, we may be required by law enforcement or judicial authorities to provide PII to the appropriate governmental authorities. In such cases, we will disclose PII upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we, in good faith, believe to be unlawful. We may also provide Aggregate Information about our customers’ sales, traffic patterns, and related APP information to third party advertisers.</p>
<p>5. User Choices on Collection and Use of Information</p>
<p>We may, from time to time, send you email regarding new products and services that we feel may interest you. In addition, if you indicated upon registration that you are interested in receiving information from us and our partners, we may occasionally call or send you direct mail about products and services that may be of interest to you. Only Pixibox (or agents working on behalf of Pixibox and under confidentiality agreements) will send you these solicitations, and only if you have previously indicated that you wish to receive them. If you do not want to receive solicitations from us, you can “opt-out” by accessing your account online editing your account information to no longer receive such offers and mailings.</p>
<p>You also have choices with respect to cookies, as described above. By modifying your browser preferences, you have the choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies. If you choose to reject all cookies, some parts of our APP may not work properly in your case.</p>
<p>5. Security of Your PII</p>
<p>On our APP, you can be assured that your PII is secure, consistent with current industry standards. We strive to take appropriate security measures to protect against unauthorized access, unauthorized alteration, disclosure or destruction of your PII.For example:</p>
<p>We work hard to ensure that the data we collect is reliable, accurate, complete and current. We encrypt all PII, in order to prevent unauthorized parties from viewing such information when it is transmitted to us. We also only keep collected information only for as long as reasonably necessary and use it only for the purposes for which it was collected or to comply with any applicable legal or ethical reporting or document retention requirements.</p>
<p>We limit access to PII only to specific employees, contractors and agents who have a reasonable need to come into contact with your information.For example, we may provide members of our technical support team with limited access to your account in order to allow them to troubleshoot problems you may be having with the APP.</p>
<p>Additionally, we also employ a number of physical, electronic, and procedural safeguards to protect PII.Our secure servers and our data centers are protected by industry- standard encryption, and our servers reside behind firewalls and employ high- level password protection.</p>
<p>Finally, access by you to your PII is available through a password and unique customer ID selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone.</p>
<p>In order to most effectively serve you, debit card transactions are handled by established third party banking institutions and payment processors. They receive the information needed to verify and authorize your debit card or other payment information.</p>
<p>Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your PII, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) the security, integrity and privacy of any and all information and data exchanged between you and us through this APP cannot be guaranteed.</p>
<p>7. Miscellaneous</p>
<p>You must be at least 18 years old to have our permission to use this APP. Our policy is that we do not knowingly collect, use or disclose PII about minor visitors.</p>
<p>You should also be aware that when Personally Identifiable Information is voluntarily disclosed (i.e.your name, email address, etc.) in the discussion forums or other public areas on this APP, that information, along with any information disclosed in your communication, can be collected and used by third parties and may result in unsolicited messages(including unwanted spam messages) from third parties.Such activities are beyond our control and this Policy does not apply to such information.To learn about our APP conduct policies, please see our APP Terms and Conditions of Use here.</p>
`;

export const privacyContentESMX = `
<p>En Pixibox, reconocemos que tu privacidad es importante. Esta Política de Privacidad revela las prácticas de privacidad de Pixibox, así como los productos y servicios relacionados que podemos ofrecerte (en conjunto referidos como los "Servicios"). Esta Política también cubre cómo se trata la información personal y otra información que recibimos o recopilamos sobre ti. Por favor, lee la siguiente información para aprender lo siguiente en relación con tu uso de esta APLICACIÓN. Reconoces que esta Política de Privacidad está diseñada para ser leída en conexión con los Términos y Condiciones de Uso de la APLICACIÓN, disponibles aquí, y que al acceder o utilizar nuestra APLICACIÓN, aceptas quedar obligado por los términos y condiciones de la APLICACIÓN, así como por esta Política. Nos reservamos el derecho de cambiar esta Política de Privacidad de vez en cuando. Te notificaremos sobre cambios significativos en la forma en que tratamos la información personal enviándote un aviso a la dirección de correo electrónico principal especificada en tu cuenta, colocando un aviso destacado en nuestra APLICACIÓN y actualizando cualquier información de privacidad en esta página. Tu uso continuado de la APLICACIÓN y/o de los Servicios disponibles a través de esta APLICACIÓN después de dichas modificaciones constituirá: (a) tu reconocimiento de la Política modificada; y (b) tu acuerdo de cumplir y quedar obligado por esa Política.</p>
<p>1.Tipos de información que recopilamos</p>
<p>Con el fin de brindarte mejores servicios, recopilamos varios tipos de información sobre nuestros usuarios, que se detallan a continuación. Información de identificación personal: Esto se refiere a información que nos permite conocer los detalles de "quién eres". Cuando participas en ciertas actividades en esta APLICACIÓN, como registrarte para obtener una cuenta, comprar puntos, productos o servicios, publicar contenido en los foros de usuarios, suscribirte a nuestros boletines informativos o listas de correo electrónico, o enviarnos comentarios, nosotros, o nuestro procesador de pagos de terceros, podemos solicitar que proporciones cierta información sobre ti. </p>
<p>Ejemplos de PII pueden incluir tu nombre y apellido, dirección de correo electrónico, dirección postal, dirección de facturación, números de teléfono y otra información de identificación.</p>
<p>Información agregada</p>
<p>Esto se refiere a información que por sí sola no identifica a una persona específica. Recopilamos cierta información sobre ti según los lugares que visitas en nuestra APLICACIÓN y a través de qué otras APLICACIONES puedes habernos encontrado. Esta información, que se recopila de diversas formas, se compila y analiza tanto de forma personal como agregada. Esta información puede incluir la dirección URL de la APLICACIÓN que te ha redirigido a nuestra APLICACIÓN, la URL a la que accedes después de visitar nuestra APLICACIÓN, el navegador que estás utilizando y tu dirección de Protocolo de Internet.</p>
<p>2.Cómo recopilamos y utilizamos la información</p>
<p>Los objetivos principales de Pixibox al recopilar y utilizar información son crear tu cuenta, brindarte los Servicios, mejorar el Servicio, contactarte y crear informes para uso interno. Almacenamos la información en nuestros servidores ubicados en México. No recopilamos ninguna información de identificación personal sobre ti a menos que nos la proporciones voluntariamente. Sin embargo, se te solicitará que nos proporciones cierta información de identificación personal cuando elijas utilizar ciertos productos o servicios disponibles en la APLICACIÓN. Esto puede incluir:</p>
<p>-Registrarte para obtener una cuenta en nuestra APLICACIÓN.</p>
<p>-Comprar puntos en nuestra aplicación.</p>
<p>-Suscribirte a ofertas especiales de terceros seleccionados.</p>
<p>-Enviarnos un mensaje de correo electrónico o contactarnos a través de otros medios electrónicos disponibles en nuestra aplicación.</p>
<p>-Enviar un formulario u otra información por teléfono o correo.</p>
<p>Realizar pedidos y comprar productos y servicios en nuestra aplicación. Al procesar cierta información, como información de pago con instituciones bancarias afiliadas o procesadores de pagos, encriptamos la transacción utilizando tecnología de encriptación de capa de conexión segura para evitar que tu información sea robada o interceptada.</p>
<p>Utilizaremos principalmente tu información de identificación personal para brindarte productos o servicios. También utilizaremos ciertas formas de información de identificación personal para mejorar el funcionamiento de nuestra APLICACIÓN, mejorar nuestros esfuerzos de marketing y promoción internos, analizar estadísticamente el uso de la APLICACIÓN, mejorar nuestras ofertas de productos y servicios y personalizar el contenido, diseño y servicios de nuestra APLICACIÓN. Podemos utilizar la información de identificación personal para enviarte información y para contactarte con respecto a avisos administrativos. Por último, podemos utilizar tu información de identificación personal para resolver disputas, solucionar problemas y hacer cumplir nuestros acuerdos contigo, incluidos nuestros Términos de Uso de la APLICACIÓN y esta Política de Privacidad. También podemos recopilar, o nuestros socios de publicidad de terceros pueden recopilar, cierta información agregada. Por ejemplo, podemos utilizar tu dirección IP para diagnosticar problemas con nuestros servidores o software, administrar nuestra APLICACIÓN y recopilar información demográfica. Nuestros socios de publicidad de terceros también pueden proporcionarnos informes agregados, pero no individuales, que nos indicarán cuántos anuncios se mostraron y se hicieron clic en nuestra APLICACIÓN, o ciertos patrones de comportamiento que ocurren al navegar por nuestra APLICACIÓN.</p>
<p>3.Tus derechos y opciones </p>
<p>Derecho a eliminar datos personales ("derecho al olvido"). En ciertas situaciones, como cuando tus datos personales han sido procesados de manera ilegal o has retirado tu consentimiento (si el procesamiento de tus datos personales se basa en el consentimiento), tienes el derecho de solicitar y obtener la eliminación de tus datos personales de nuestra parte, sujeto a ciertas excepciones. También ten en cuenta que cualquier solicitud de eliminación no garantiza la eliminación completa o total de cualquier contenido o información del Servicio. Por ejemplo, el contenido que publicas puede ser republicado o compartido por otros usuarios o terceros. Por favor, contáctanos por correo electrónico a pixiboxservicio@gmail.com para realizar una solicitud relacionada con tus derechos. Haremos todo lo posible para responder a tu solicitud dentro de los 60 días posteriores a la recepción de la misma. Si no podemos cumplir con tu solicitud dentro de los 60 días, te informaremos la razón y cuándo esperamos poder cumplir con tu solicitud.</p>
<p>4.Divulgación de información</p>
<p>No venderemos, intercambiaremos ni alquilaremos tu información de identificación personal (PII) a terceros. Sin embargo, proporcionamos algunos de nuestros servicios y productos a través de acuerdos contractuales con afiliados, proveedores de servicios, socios y otros terceros ("Socios de Servicio"). Nosotros y nuestros Socios de Servicio podemos necesitar utilizar cierta información de identificación personal para realizar tareas entre nuestras respectivas APLICACIONES o para entregarte productos o servicios. Por ejemplo, nuestro procesador de pagos debe recopilar tu información de facturación; debemos proporcionar tu información de dirección al servicio de entrega para entregar los productos que has ganado correctamente; y proporcionar tu información de pedido a terceros que nos ayudan a brindar servicio al cliente.</p>
<p>Alentaremos a nuestros Socios de Servicio a adoptar y promover políticas de privacidad sólidas. Sin embargo, el uso de tu información de identificación personal por parte de nuestros Socios de Servicio está sujeto a las respectivas políticas de privacidad de esos proveedores y no está bajo nuestro control. Salvo que se indique lo contrario en esta Política de Privacidad, este documento se refiere únicamente al uso y divulgación de la información que recopilamos de ti. Otras APLICACIONES accesibles a través de esta APLICACIÓN, incluidos nuestros Socios de Publicidad y Servicio, tienen sus propias políticas de privacidad y prácticas de recopilación, uso y divulgación de datos. Consulta la política de privacidad de cada APLICACIÓN. No somos responsables de las políticas o prácticas de terceros.</p>
<p>En ocasiones, es posible que las autoridades policiales o judiciales nos exijan proporcionar información de identificación personal a las autoridades gubernamentales correspondientes. En tales casos, divulgaremos información de identificación personal  al recibir una orden judicial, una citación o para cooperar con una investigación policial. Cooperamos plenamente con las agencias encargadas de hacer cumplir la ley para identificar a aquellos que utilizan nuestros servicios para actividades ilegales. Nos reservamos el derecho de informar a las agencias encargadas de hacer cumplir la ley sobre cualquier actividad que, de buena fe, creamos que es ilegal. También podemos proporcionar información agregada sobre las ventas, los patrones de tráfico y otra información relacionada con la APLICACIÓN de nuestros clientes a anunciantes de terceros.</p>
<p>5.Opciones del usuario sobre la recopilación y uso de la información </p>
<p>De vez en cuando, podemos enviarte correos electrónicos sobre nuevos productos y servicios que creemos que pueden interesarte. Además, si indicaste al registrarte que estás interesado en recibir información nuestra y de nuestros socios, es posible que ocasionalmente te llamemos o te enviemos correo directo sobre productos y servicios que pueden ser de tu interés. Solo Pixibox (o agentes que trabajen en nombre de Pixibox y bajo acuerdos de confidencialidad) te enviará estas solicitudes, y solo si previamente has indicado que deseas recibirlas. Si no deseas recibir estas solicitudes de nuestra parte, puedes "darte de baja" accediendo a tu cuenta en línea y editando la información de tu cuenta para dejar de recibir dichas ofertas y envíos por correo.</p>
<p>También tienes opciones con respecto a las cookies, como se describe anteriormente. Al modificar las preferencias de tu navegador, puedes elegir aceptar todas las cookies, recibir una notificación cuando se establezca una cookie o rechazar todas las cookies. Si eliges rechazar todas las cookies, es posible que algunas partes de nuestra APLICACIÓN no funcionen correctamente en tu caso.</p>
<p>6.Seguridad de tu información de identificación personal </p>
<p>En nuestra APLICACIÓN, puedes estar seguro de que tu información de identificación personal  está segura, de acuerdo con los estándares de la industria actual. Nos esforzamos por tomar medidas de seguridad adecuadas para proteger contra el acceso no autorizado, la alteración no autorizada, la divulgación o destrucción de tu información de identificación personal. Por ejemplo:</p>
<p>Trabajamos arduamente para asegurarnos de que los datos que recopilamos sean confiables, precisos, completos y actuales. Encriptamos toda la información de identificación personal para evitar que terceros no autorizados vean dicha información cuando se nos transmite. Además, solo conservamos la información recopilada durante el tiempo razonablemente necesario y la utilizamos únicamente para los fines para los que se recopiló o para cumplir con cualquier requisito legal o ético de informes o retención de documentos aplicable.</p>
<p>Limitamos el acceso a la información de identificación personal solo a empleados, contratistas y agentes específicos que tienen una necesidad razonable de entrar en contacto con tu información. Por ejemplo, podemos proporcionar a los miembros de nuestro equipo de soporte técnico un acceso limitado a tu cuenta para permitirles solucionar problemas que puedas tener con la APLICACIÓN.</p>
<p>Además, también utilizamos diversas salvaguardias físicas, electrónicas y procedimentales para proteger la información de identificación personal. Nuestros servidores seguros y nuestros centros de datos están protegidos por encriptación estándar de la industria, y nuestros servidores se encuentran detrás de firewalls y utilizan protección de contraseña de alto nivel.</p>
<p>Por último, el acceso a tu información de identificación personal está disponible a través de una contraseña e identificación de cliente única seleccionada por ti. Esta contraseña está encriptada. Recomendamos que no divulgues tu contraseña a nadie.</p>
<p>Para servirte de la manera más efectiva posible, las transacciones con tarjeta de débito son manejadas por instituciones bancarias y procesadores de pagos establecidos de terceros. Ellos reciben la información necesaria para verificar y autorizar tu tarjeta de débito u otra información de pago.</p>
<p>Desafortunadamente, ninguna transmisión de datos a través de Internet o cualquier red inalámbrica puede garantizarse como 100% segura. Como resultado, aunque nos esforzamos por proteger tu información de identificación personal, reconoces que: (a) existen limitaciones de seguridad y privacidad inherentes a Internet que están fuera de nuestro control; y (b) la seguridad, integridad y privacidad de toda la información y los datos intercambiados entre tú y nosotros a través de esta APLICACIÓN no pueden garantizarse.</p>
<p>7.Varios</p>
<p>Debes tener al menos 18 años para tener nuestro permiso para usar esta APLICACIÓN. Nuestra política es que no recopilamos, utilizamos ni divulgamos intencionalmente información de identificación personal sobre visitantes menores de edad. También debes tener en cuenta que cuando se revela voluntariamente información de identificación personal (es decir, tu nombre, dirección de correo electrónico, etc.) en los foros de discusión u otras áreas públicas de esta APLICACIÓN, esa información, junto con cualquier información revelada en tu comunicación, puede ser recopilada y utilizada por terceros y puede dar lugar a mensajes no solicitados (incluidos mensajes no deseados de correo no deseado) de terceros. Tales actividades están fuera de nuestro control y esta Política no se aplica a dicha información. Para conocer nuestras políticas de conducta en la APLICACIÓN, consulta nuestros Términos y Condiciones de Uso de la APLICACIÓN aquí.</p>
`;

export const privacyContentZHCN = `
<p>Pixibox非常重视用户隐私问题。 本隐私政策披露了 Pixibox 的隐私条例以及我们可能向您提供的相关产品和服务（统称为“服务”）。本政策还涵盖了我们如何处理我们收到或收集的有关您的个人信息和其他信息。请阅读以下信息，了解以下有关您使用本应用程序的信息。</p>
<p>您承认本隐私政策旨在与 APP 使用条款和条件（可在此处获取）一起阅读，并且通过访问或使用我们的 APP，即表示您同意受APP条款和条件的约束。</p>
<p>我们保留不时更改本隐私政策的权利。我们将通过向您帐户中指定的主要电子邮件地址发送通知、在我们的应用程序上放置醒目的通知以及更新此页面上的任何隐私信息来通知您我们处理个人信息的方式发生重大变化。您的继续使用修改后的应用程序和/或通过本应用程序提供的服务将构成您： (a) 承认修改后的政策 (b) 您同意遵守该政策并受其约束。</p>
<p>一、 我们收集的信息类型</p>
<p>为了更好地为您提供我们的服务，我们收集以下有关我们用户的各种类型的信息。</p>
<p>个人身份信息，是指让我们了解“您是谁”的具体信息。当您在本应用程序上进行某些活动时，例如注册帐户、购买积分、产品或服务、注册为了获取我们的时事通讯或电子邮件列表，或者向我们发送反馈，我们或我们的第三方支付处理商可能会要求您提供有关您自己的某些信息。个人身份信息可能包括您的名字和姓氏、电子邮件地址、邮寄地址、账单地址、电话号码和其他身份信息。</p>
<p>汇总信息。</p>
<p>这是指本身不能识别特定个人的信息。 我们根据您在我们的应用程序上访问的位置以及其他应用程序可能将您引导至我们来收集有关您的某些信息。这些信息以各种不同的方式收集，并在个人和汇总的基础上进行编译和分析这些信息可能包括指向您刚刚来自的应用程序的应用程序统一资源定位符（“URL”）、您访问我们的应用程序后转到哪个URL、您使用的浏览器以及您的互联网协议地址。</p>
<p>二、我们如何收集和使用信息</p>
<p>Pixibox 收集和使用信息的主要目标是创建您的帐户、向您提供服务、改进服务、与您联系以及创建供内部使用的报告。 我们将信息存储在位于墨西哥的服务器上</p>
<p>除非您自愿向我们提供，否则我们不会收集有关您的任何 个人身份信息。 但是，当您选择使用应用程序上提供的某些产品或服务时，您将需要向我们提供某些 个人身份信息。 这些可能包括：</p>
<p>在我们的APP上注册账户</p>
<p>在我们的应用程序上购买积分</p>
<p>注册特定第三方的特别优惠</p>
<p>向我们发送电子邮件或通过我们的应用程序提供的其他电子方式联系我们</p>
<p>通过电话或信件提交表格或传送其他信息； 或者在我们的应用程序上订购和购买产品和服务。</p>
<p>在处理某些信息时，例如与附属银行机构或支付处理商的支付信息，我们使用安全套接字层（ssl）加密技术对交易进行加密，以防止您的信息被窃取或拦截。</p>
<p>我们将主要使用您的 个人身份信息 向您提供产品或服务。 我们还将使用某些形式的 个人身份信息 来增强我们 APP 的运营，改善我们的内部营销和促销工作，统计分析 APP 使用情况，改进我们的产品和服务，并定制我们 APP 的内容、布局和服务。 我们可能会使用个人身份信息 向您提供信息并就行政通知与您联系。 最后，我们可能会使用您的 个人身份信息 来解决争议、解决问题并执行我们与您之间的协议，包括我们的 APP 使用条款和本隐私政策。</p>
<p>我们还可能收集或我们的第三方广告合作伙伴可能收集某些汇总信息。例如，我们可能使用您的 IP 地址来诊断我们的服务器或软件的问题、管理我们的应用程序并收集人口统计信息。我们的第三方广告合作伙伴还可能向我们提供汇总而非单独的报告，这些报告将告诉我们在我们的应用程序上显示和点击了多少广告，或者在浏览我们的应用程序时发生的某些行为模式。</p>
<p>三、您的权利和选择</p>
<p>删除个人数据的权利（“被遗忘权”）。 在某些情况下，例如您的个人数据被非法处理或您撤回同意（如果您的个人数据的处理是基于同意），您有权请求并获得我们删除您的个人数据，但有某些例外情况。 另请注意，任何删除请求并不能确保完全或完整地从服务中删除任何内容或信息。 例如，您发布的内容可能会被其他用户或第三方重新发布或转发。 请通过电子邮件联系我们（pixiboxservicio@gmail.com），提出有关您权利的请求。 我们将尽合理努力在收到请求后 60 天内回复您的请求。 如果我们无法在60天内满足您的请求，我们将告知您原因以及我们希望何时满足您的请求。</p>
<p>四、信息发布</p>
<p>我们不会向他人出售、交易或出租您的 个人身份信息。 我们确实通过与附属公司、服务提供商、合作伙伴和其他第三方（“服务合作伙伴”）签订的合同安排提供我们的一些服务和产品。我们和我们的服务合作伙伴可能需要使用一些 个人身份信息，以便在我们各自之间执行任务应用程序，或向您提供产品或服务。例如，我们的支付处理器必须收集您的账单信息； 我们必须向送货服务发布您的地址信息，才能运送您成功赢得的产品； 并将您的订单信息提供给任何帮助我们提供客户服务的第三方。</p>
<p>我们将鼓励我们的服务合作伙伴采用并推广强有力的隐私政策。 但是，我们的服务合作伙伴对您的个人身份信息的使用受这些提供商各自的隐私政策的约束，不受我们的控制。 除非本隐私政策中另有讨论，本文档仅涉及我们从您那里收集的信息的使用和披露。通过此应用程序访问的其他应用程序，包括我们的广告和服务合作伙伴，都有自己的隐私政策以及数据收集、使用和披露惯例。 请查阅各应用程序的隐私政策。 我们不对第三方的政策或做法负责。</p>
<p>有时，执法或司法机构可能会要求我们向相应的政府机构提供个人身份信息。在这种情况下，我们将在收到法院命令、传票或配合执法调查时披露个人身份信息。 我们与执法机构充分合作，查明那些使用我们的服务进行非法活动的人。我们保留向执法机构报告我们真诚地认为非法的任何活动的权利。 我们还可能向第三方广告商提供有关客户销售、流量模式和相关 APP 信息的汇总信息。</p>
<p>五、 用户对信息收集和使用的选择</p>
<p>我们可能会不时向您发送有关我们认为您可能感兴趣的新产品和服务的电子邮件。 此外，如果您在注册时表示您有兴趣接收来自我们和我们合作伙伴的信息，我们有时可能会致电或直接向您发送有关您可能感兴趣的产品和服务的邮件。只有 Pixibox（或代表 Pixibox 并遵守保密协议的代理）才会向您发送这些请求，并且仅当您之前表示希望接收这些请求时。 如果您不想收到我们的请求，您可以通过在线访问您的帐户编辑您的帐户信息来“选择退出”，以不再接收此类优惠和邮件。</p>
<p>如上所述，您还可以选择 cookie。 通过修改您的浏览器首选项，您可以选择接受所有 cookie、在设置 cookie 时收到通知或拒绝所有 cookie。 如果您选择拒绝所有cookie，我们的应用程序的某些部分可能无法在您的情况下正常工作。</p>
<p>六、您的个人身份信息安全</p>
<p>在我们的应用程序上，您可以放心，您的 个人身份信息 是安全的，符合当前的行业标准。 我们努力采取适当的安全措施来防止未经授权的访问、未经授权的更改、披露或破坏您的个人身份信息。例如：我们努力确保我们收集的数据可靠、准确、完整和最新。 我们对所有个人身份信息 进行加密，以防止未经授权的各方在传输给我们的信息时查看这些信息。 我们也仅在合理必要的时间内保留收集到的信息，并且仅将其用于收集信息的目的或遵守任何适用的法律或道德报告或文件保留要求。</p>
<p>我们将个人身份信息的访问权限仅限于有合理需要接触您的信息的特定员工、承包商和代理商。例如，我们可能会为我们的技术支持团队的成员提供对您帐户的有限访问权限，以便他们能够解决您在使用该应用程序时可能遇到的问题。</p>
<p>此外，我们还采用许多物理、电子和程序保护措施来保护 个人身份信息。我们的安全服务器和数据中心受到行业标准加密的保护，我们的服务器位于防火墙后面并采用高级密码保护。
最后，您可以通过您选择的密码和唯一客户ID访问您的个人身份信息，该密码已加密，我们建议您不要向任何人透露您的密码。</p>
<p>为了最有效地为您提供服务，借记卡交易由成熟的第三方银行机构和支付处理商处理。 他们收到验证和授权您的借记卡或其他付款信息所需的信息。</p>
<p>不幸的是，互联网或任何无线网络上的数据传输都不能保证 100% 安全。 因此，虽然我们努力保护您的 个人身份信息，但您承认：(a) 互联网固有的安全和隐私限制是我们无法控制的； (b) 无法保证您和我们之间通过本应用程序交换的任何及所有信息和数据的安全性、完整性和隐私性。</p>
<p>七、其他</p>
<p>您必须年满 18 岁才能获得我们使用此应用程序的许可。 我们的政策是我们不会故意收集、使用或披露有关未成年访客的个人身份信息。</p>
<p>您还应该注意，当您在本应用程序的论坛或其他公共区域自愿披露个人身份信息（即您的姓名、电子邮件地址等）时，该信息以及您在通信中披露的任何信息可能会被泄露。由第三方收集和使用，可能会导致第三方发送未经请求的消息（包括不需要的垃圾消息）。此类活动超出我们的控制范围，本政策不适用于此类信息。要了解我们的 APP 行为政策，请参阅我们的 APP使用条款和条件请参见此处。</p>
`;

export const privacyContentPTBR = `
<p>Na Pixibox, reconhecemos que sua privacidade é importante. Esta Política de Privacidade divulga as práticas de privacidade da Pixibox, bem como os produtos e serviços relacionados que podemos oferecer a você (coletivamente, os "Serviços"). Esta Política também cobre como as informações pessoais e outras informações que recebemos ou coletamos sobre você são tratadas. Leia as informações a seguir para saber o seguinte em relação ao uso deste APLICATIVO. Você reconhece que esta Política de Privacidade foi elaborada para ser lida em conjunto com os Termos e Condições de Uso do APP, disponíveis aqui, e que ao acessar ou usar nosso APP, você concorda em ficar vinculado aos termos e condições do APP, bem como esta Política. Reservamo-nos o direito de alterar esta Política de Privacidade de tempos em tempos. Iremos notificá-lo sobre mudanças significativas na forma como tratamos as informações pessoais, enviando-lhe um aviso para o endereço de e-mail principal especificado em sua conta, colocando um aviso proeminente em nosso APP e atualizando todas as informações de privacidade nesta página. Seu uso continuado do APP e/ou dos Serviços disponíveis através deste APP após tais modificações constituirá: (a) seu reconhecimento da Política modificada; e (b) sua concordância em cumprir e estar vinculado a essa Política.</p>
<p>1.Tipos de informações que coletamos</p>
<p>A fim de lhe fornecer melhores serviços, coletamos vários tipos de informações sobre nossos usuários, que são detalhadas abaixo. Informações pessoalmente identificáveis: Referem-se a informações que nos permitem saber os detalhes de "quem você é". Quando você se envolve em certas atividades neste APP, como registrar uma conta, comprar pontos, produtos ou serviços, postar conteúdo em fóruns de usuários, assinar nossos boletins informativos ou listas de e-mail ou enviar feedback para nós, nós ou terceiros processador de pagamentos, podemos solicitar que você forneça algumas informações sobre você.</p>
<p>Exemplos de informações podem incluir seu nome e sobrenome, endereço de e-mail, endereço de correspondência, endereço de cobrança, números de telefone e outras informações de identificação.</p>
<p>informação agregada</p>
<p>Refere-se a informações que por si só não identificam uma pessoa específica. Coletamos certas informações sobre você com base em onde você visita nosso APP e por meio de quais outros APPS você nos encontrou. Essas informações, que são coletadas de várias maneiras, são compiladas e analisadas de forma pessoal e agregada. Essas informações podem incluir a URL do APP que o redirecionou para nosso APP, a URL que você acessa após visitar nosso APP, o navegador que você está usando e seu endereço de Protocolo de Internet.</p>
<p>2.Como coletamos e usamos as informações</p>
<p>Os principais objetivos da Pixibox na coleta e uso de informações são criar sua conta, fornecer os Serviços, melhorar o Serviço, entrar em contato com você e criar relatórios para uso interno. Armazenamos as informações em nossos servidores localizados no México. Não coletamos nenhuma informação de identificação pessoal sobre você, a menos que você nos forneça voluntariamente. No entanto, você será obrigado a nos fornecer certas informações de identificação pessoal quando optar por usar determinados produtos ou serviços disponíveis no APP. Isso pode incluir:</p>
<p>-Cadastre-se para uma conta em nosso APP.</p>
<p>-Compre pontos em nosso aplicativo.</p>
<p>-Assine ofertas especiais de terceiros selecionados.</p>
<p>-Envie-nos um email ou contacte-nos através de outros meios eletrónicos disponíveis na nossa aplicação.</p>
<p>-Envie um formulário ou outras informações por telefone ou correio.</p>
<p>Faça pedidos e compre produtos e serviços em nosso aplicativo. Ao processar determinadas informações, como informações de pagamento com instituições bancárias afiliadas ou processadores de pagamento, criptografamos a transação usando tecnologia de criptografia de camada de soquete seguro para evitar que suas informações sejam roubadas ou interceptadas.
Usaremos principalmente suas informações de identificação pessoal para fornecer produtos ou serviços a você. Também usaremos certas formas de informações de identificação pessoal para melhorar o funcionamento de nosso APP, aprimorar nosso marketing interno e esforços promocionais, analisar estatisticamente o uso do APP, melhorar nossas ofertas de produtos e serviços e personalizar o conteúdo, design e serviços de nosso APP. nosso APLICATIVO. Podemos usar informações de identificação pessoal para enviar informações e entrar em contato com você sobre avisos administrativos. Finalmente, podemos usar suas informações de identificação pessoal para resolver disputas, corrigir problemas e fazer cumprir nossos acordos com você, incluindo nossos Termos de Uso do APP e esta Política de Privacidade. Também podemos coletar, ou nossos parceiros de publicidade terceirizados podem coletar, certas informações agregadas. Por exemplo, podemos usar seu endereço IP para diagnosticar problemas com nossos servidores ou software, administrar nosso APP e coletar informações demográficas. Nossos parceiros de publicidade terceirizados também podem nos fornecer relatórios agregados, mas não individuais, que nos dirão quantos anúncios foram exibidos e clicados em nosso APP ou determinados padrões de comportamento que ocorrem durante a navegação em nosso APP.
</p>
<p>3. Seus direitos e opções</p>
<p>Direito de apagar dados pessoais ("direito a ser esquecido"). Em determinadas situações, como quando seus dados pessoais foram processados ilegalmente ou você retirou seu consentimento (se o processamento de seus dados pessoais for baseado em consentimento), você tem o direito de solicitar e obter a exclusão de seus dados pessoais de nosso parte, salvo algumas exceções. Observe também que qualquer solicitação de remoção não garante a remoção completa ou total de qualquer conteúdo ou informação do Serviço. Por exemplo, o conteúdo que você publica pode ser republicado ou compartilhado por outros usuários ou terceiros. Entre em contato conosco pelo e-mail pixiboxservicio@gmail.com para fazer uma solicitação sobre seus direitos. Faremos o possível para responder à sua solicitação em até 60 dias após o recebimento. Se não pudermos atender à sua solicitação em 60 dias, notificaremos você sobre o motivo e quando esperamos poder atender à sua solicitação.</p>
<p>4. Divulgação de informações</p>
<p>Não venderemos, trocaremos ou alugaremos suas informações de identificação pessoal (PII) a terceiros. No entanto, fornecemos alguns de nossos serviços e produtos por meio de acordos contratuais com afiliados, provedores de serviços, parceiros e outros terceiros ("Parceiros de Serviços"). Nós e nossos Parceiros de Serviços podemos precisar usar certas informações de identificação pessoal para executar tarefas entre nossos respectivos APLICATIVOS ou para fornecer produtos ou serviços a você. Por exemplo, nosso processador de pagamentos deve coletar suas informações de cobrança; devemos fornecer suas informações de endereço ao serviço de entrega para entregar os produtos que você ganhou com sucesso; e forneça as informações do seu pedido a terceiros que nos ajudam a fornecer atendimento ao cliente.</p>
<p>Incentivaremos nossos parceiros de serviço a adotar e promover políticas de privacidade rígidas. No entanto, o uso de suas informações de identificação pessoal por nossos parceiros de serviço está sujeito às respectivas políticas de privacidade desses provedores e não está sob nosso controle. Salvo disposição em contrário nesta Política de Privacidade, este documento aborda apenas o uso e a divulgação de informações que coletamos de você. Outros APLICATIVOS acessíveis através deste APLICATIVO, incluindo nossos Parceiros de Serviços e Publicidade, têm suas próprias políticas de privacidade e práticas de coleta, uso e divulgação de dados. Consulte a política de privacidade de cada APLICATIVO. Não somos responsáveis pelas políticas ou práticas de terceiros.</p>
<p>Ocasionalmente, podemos ser solicitados pelas autoridades legais ou judiciais a fornecer informações de identificação pessoal às autoridades governamentais apropriadas. Nesses casos, divulgaremos informações de identificação pessoal mediante o recebimento de uma ordem judicial, intimação ou para cooperar com uma investigação policial. Cooperamos totalmente com as agências de aplicação da lei para identificar aqueles que usam nossos serviços para atividades ilegais. Reservamo-nos o direito de denunciar às autoridades policiais qualquer atividade que acreditemos de boa fé ser ilegal. Também podemos fornecer informações agregadas sobre as vendas de nossos clientes, padrões de tráfego e outras informações relacionadas ao APP para anunciantes terceirizados.</p>
<p>5. Escolhas do usuário em relação à coleta e uso de informações</p>
<p>Ocasionalmente, podemos enviar e-mails sobre novos produtos e serviços nos quais achamos que você pode estar interessado. Além disso, se você indicou quando se registrou que está interessado em receber informações nossas e de nossos parceiros, podemos ocasionalmente ligar ou enviar mala direta sobre produtos e serviços que possam ser de seu interesse. Somente a Pixibox (ou agentes que trabalham em nome da Pixibox e sob acordos de confidencialidade) enviarão a você essas solicitações e somente se você tiver indicado previamente que deseja recebê-las. Se você não deseja receber essas solicitações de nós, você pode "desativar" acessando sua conta on-line e editando as informações de sua conta para parar de receber tais ofertas e correspondências.</p>
<p>Você também tem opções em relação aos cookies, conforme descrito acima. Ao modificar as preferências do navegador, você pode optar por aceitar todos os cookies, receber uma notificação quando um cookie for definido ou rejeitar todos os cookies. Se você optar por rejeitar todos os cookies, algumas partes do nosso APP podem não funcionar corretamente para você.</p>
<p>6. Segurança de suas informações de identificação pessoal</p>
<p>Em nosso APP, você pode ter certeza de que suas informações de identificação pessoal estão seguras, de acordo com os padrões atuais do setor. Nós nos esforçamos para tomar medidas de segurança apropriadas para proteger contra acesso não autorizado, alteração não autorizada, divulgação ou destruição de suas informações de identificação pessoal. Por exemplo:</p>
<p>Trabalhamos muito para garantir que os dados que coletamos sejam confiáveis, precisos, completos e atuais. Criptografamos todas as informações de identificação pessoal para impedir que terceiros não autorizados visualizem essas informações quando elas são transmitidas a nós. Além disso, retemos as informações coletadas apenas pelo tempo razoavelmente necessário e as usamos apenas para os fins para os quais foram coletadas ou para cumprir qualquer relatório legal ou ético aplicável ou requisitos de retenção de documentos.</p>
<p>Limitamos o acesso a informações de identificação pessoal apenas a funcionários, contratados e agentes específicos que tenham uma necessidade razoável de entrar em contato com suas informações. Por exemplo, podemos fornecer aos membros de nossa equipe de suporte técnico acesso limitado à sua conta para permitir que eles solucionem problemas que você possa ter com o APLICATIVO.</p>
<p>Além disso, também usamos várias salvaguardas físicas, eletrônicas e processuais para proteger informações de identificação pessoal. Nossos servidores e data centers seguros são protegidos por criptografia padrão do setor, e nossos servidores estão protegidos por firewalls e usam proteção de senha de alto nível.</p>
<p>Por fim, o acesso às suas informações de identificação pessoal está disponível por meio de um ID de cliente exclusivo e senha selecionados por você. Esta senha é criptografada. Recomendamos que você não divulgue sua senha a ninguém.</p>
<p>Para atendê-lo da forma mais eficaz possível, as transações com cartão de débito são realizadas por instituições bancárias terceirizadas e processadores de pagamento estabelecidos. Eles recebem as informações necessárias para verificar e autorizar seu cartão de débito ou outras informações de pagamento.</p>
<p>Infelizmente, nenhuma transmissão de dados pela Internet ou qualquer rede sem fio pode ser garantida como 100% segura. Como resultado, embora nos esforcemos para proteger suas informações de identificação pessoal, você reconhece que: (a) existem limitações inerentes de segurança e privacidade da Internet que estão além de nosso controle; e (b) a segurança, integridade e privacidade de todas as informações e dados trocados entre você e nós por meio deste APLICATIVO não podem ser garantidos.</p>
<p>7. Outros</p>
<p>Você deve ter pelo menos 18 anos para ter nossa permissão para usar este APP. Nossa política é que não coletamos, usamos ou divulgamos intencionalmente informações de identificação pessoal sobre visitantes menores de idade. Você também deve estar ciente de que quando você divulga voluntariamente informações de identificação pessoal (ou seja, seu nome, endereço de e-mail, etc.) em fóruns de discussão ou outras áreas públicas deste APP, essas informações, juntamente com quaisquer informações divulgadas em sua comunicação, podem ser coletadas e usado por terceiros e pode resultar em mensagens não solicitadas (incluindo mensagens de spam) de terceiros. Tais atividades estão fora de nosso controle e esta Política não se aplica a tais informações. Para conhecer nossas políticas de conduta no APP, consulte aqui nossos Termos e Condições de Uso do APP.</p>

`;

export const agreementContentESMX = `
<p>Cláusula de Propinas</p>
<br/>
<p>usted está cordialmente invitado a suscribir este "Contrato de Servicio PixiBox" (en adelanle denominado "este Contrato") con el operador de PixiBox (consulle los términos de definición para oblener más detalles) y utilizar los servicios de PixiBox. Las palabras clave mencionadas al comienzo de los términos de servicio se utilizan únicamente con el propósito de ayudar a comprender el conlenido expresado en esta cláusula y no afectan ni limitan el significado o la inlerpretación de los términos de este contrato. Con el fin de proteger sus propios derechos e intereses, se recomienda que lea alentamente las disposiciones específicas de cada cláusula. Por favor, realice la revisión, ajusle y optimización de este párrafo de acuerdo con las normas y requisitos del contrato comercial.</p>
<br/>
<p>"Lea alentamente" Anles de hacer clic para aceptar este acuerdo duranle el proceso de solicitud de registro, le recomendamos que lea cuidadosamente este acuerdo. Por favor, lea con alención y comprenda completamente el conlenido de cada cláusula, especialmente aquellas que eximen o limitan la responsabilidad. La Cláusula de Ley Aplicable y Resolución de Disputas. Los términos que excluyen o limitan la responsabilidad estarán resaltados en negrita, y le sugerimos que los lea con alención. Si tiene alguna pregunta sobre el acuerdo, puede consultar al servicio de alención al cliente de PixiBox.</p>
<br/>
<p>"Acción de Firma" Cuando complele la información según las indicaciones en la página de registro, lea y aceple este acuerdo, y complele todos los procedimientos de registro, significa que ha leído, comprendido y aceptado completamente el conlenido de este acuerdo, y ha llegado a un acuerdo con PixiBox, convirtiéndose en un "usuario" de PixiBox. En el proceso de lectura de este acuerdo, si no está de acuerdo con este acuerdo o cualquiera de sus términos, debe delener inmediatamente el proceso de registro.</p>
<br/>
<p>1. Definición</p>
<p>Acuerdo de servicio PixiBox</p>
<br/>
<p>Servicios de PixiBox: PixiBox se basa en Inlernet y le brinda diversos servicios en diversas formas, incluyendo PixiBox, APP, sitio web, etc. (incluidas nuevas formas de servicio que aún no se han desarrollado lecnológicamente). Normas de PixiBox: Incluyen todas las normas de PixiBox, todas las reglas, inlerpretaciones, comunicados y otro conlenido que se hayan publicado y lanzado posleriormente en el canal, así como diversas normas, detalles de implementación, descripciones de procesos de productos, anuncios, etc.</p>
<br/>
<p>2. Alcance del acuerdo</p>
<p>2.1 Objeto del contrato</p>
<br/>
<p>"Objeto Equivalente"</p>
<p>Este acuerdo es celebrado conjuntamente entre usted y el operador de PixiBox, y tiene efecto contractual entre ambas partes.</p>
<br/>
<p>"Información del Objeto" </p>
<p>El término "operadores de PixiBox" se refiere a las entidades legales encargadas de la operación del centro comercial PixiBox. Según los términos de este acuerdo, el operador de PixiBox puede cambiar debido a ajustes comerciales. En caso de cambio de operador, el nuevo operador llevará a cabo este acuerdo con usted y le brindará los servicios correspondientes. Es importante destacar que el cambio de operador de PixiBox no afectará sus derechos establecidos en este acuerdo.</p>
<br/>
<p>Además, los operadores de PixiBox pueden agregar nuevos servicios con el fin de ofrecer una variedad de opciones a los usuarios. Si decide utilizar un nuevo servicio de PixiBox, se considerará que usted acepta que el nuevo operador lleve a cabo este acuerdo con usted. En caso de surgir alguna disputa, la determinación del objeto del contrato y de las partes involucradas en la disputa se basará en los servicios específicos que usted utilice y en las acciones específicas que afecten sus derechos e intereses.</p>
<br/>
<br/>
<p>2.2 Acuerdo Suplementario</p>
<p>Debido al rápido desarrollo de Internet, los términos establecidos en este acuerdo firmado por usted y PixiBox no pueden abarcar de manera exhaustiva todos los derechos y obligaciones entre ambas partes, y los acuerdos existentes pueden no satisfacer plenamente las necesidades de desarrollo futuro. Por lo tanto, cualquier acuerdo adicional que no esté incluido en este documento se considerará como un complemento a este acuerdo y tendrá el mismo efecto legal. En caso de que se establezca un nuevo acuerdo, PixiBox le informará al respecto. Al utilizar los servicios de PixiBox, se entenderá que usted acepta el acuerdo complementario mencionado anteriormente.</p>
<br/>
<p>3. Registro y uso de la cuenta </p>
<p>3.1 Gestión de la información de registro </p>
<p>3.1.1 Veracidad y legalidad</p>
<br/>
<p>"Información Veraz" </p>
<p>Al utilizar los servicios de PixiBox, usted se compromete a proporcionar de manera precisa y completa su información personal, incluyendo su nombre, dirección de correo electrónico, número de contacto, dirección de contacto, u otros datos requeridos según las instrucciones proporcionadas en la página de PixiBox. Esta información es necesaria para que PixiBox pueda brindarle información precisa y completa, así como para que otros usuarios puedan ponerse en contacto con usted. Usted reconoce y acepta que es su responsabilidad mantener la autenticidad y validez de la información proporcionada.</p>
<br/>
<p>3.1.2 Actualización y mantenimiento</p>
<br/>
<p>Se compromete a mantener actualizada la información que proporciona. En caso de que la ley requiera que PixiBox, como proveedor de servicios, verifique la información de ciertos usuarios (como vendedores, por ejemplo), PixiBox realizará inspecciones y verificaciones periódicas de acuerdo con la ley. Usted debe cooperar proporcionando información actualizada, veraz, completa y efectiva. Si PixiBox no puede comunicarse con usted utilizando la información proporcionada anteriormente, si no proporciona la información requerida de manera oportuna según lo solicitado por PixiBox, si la información proporcionada es claramente falsa, o si las autoridades administrativas y judiciales determinan que la información proporcionada es inválida, usted será responsable de todas las pérdidas y consecuencias adversas que se deriven para usted, otros usuarios y PixiBox.</p>
<br/>
<p>El equipo de operaciones de PixiBox puede enviarle avisos de consulta o solicitudes de corrección, y puede solicitarle que vuelva a certificar su información hasta que se suspenda o se termine parcial o totalmente la provisión de los servicios de PixiBox hacia usted. En tal caso, PixiBox no será responsable por dicha suspensión o terminación.</p>
<br/>
<p>4. Protección y autorización de la información del usuario</p>
<br/>
<p>4.1 Protección de la información personal</p>
<br/>
<p>PixiBox le otorga gran importancia a la Protección de la información personal de los usuarios (es decir, información que pueda identificar la identidad del usuario de manera independienle o en combinación con otra información). La Política de Privacidad establece que la recopilación, almacenamiento, uso, divulgación y Protección de su información personal se basan en el cumplimiento de los acuerdos relevantes de la plataforma.</p>
<br/>
<p>5.Excepciones</p>
<br/>
<p>5.1 Para posibles errores en el proceso de operación, como un precio incorrecto del producto, un nombre anormal del producto (como un precio y estilo incorrectos), etc., el equipo de operación explicará el acuerdo relevante sobre la resolución de disputas de la siguiente manera: </p>
<br/>
<p>5.2 Excepto en circunstancias especiales, la definición de estilos ocultos y raros en el estilo de PixiBox está sujeta a la descripción oficial. Si el nombre y el estilo son incorrectos, trataremos los ingresos más allá del rango razonable de acuerdo con la cláusula [Sobre excepciones]. </p>
<br/>
<p>5.3 Cuando encuentres algunos errores obvios, como el precio de varias especificaciones que es significativamente más bajo que el precio de un ese producto, este problema puede ser causado por errores del sistema. Si nos contactas a tiempo y nos informas del problema por la forma adecauda y oportuna, le daremos una recompensa razonable. Los ingresos de dichos problemas se tratarán de acuerdo con la cláusula [Sobre excepciones].</p>
<br/>
<p>5.4 En caso de que se detecte alguna anomalía o falla, el equipo de operaciones le proporcionará una explicación detallada del problema y buscará una solución después de verificarlo. La solución propuesta deberá ofrecerle beneficios iguales o superiores al costo que haya pagado efectivamente. Si no puede seguir el plan de tratamiento propuesto, ambas partes deberán buscar una comunicación amistosa para encontrar una solución alternativa. En caso de que se niegue a comunicarse o no podamos contactarlo de manera adecuada, procederemos a tratar el asunto de acuerdo con la cláusula [Sobre anomalías] después de transcurridos 3 días hábiles desde la confirmación de que no hemos logrado establecer contacto con usted.</p>
<br/>
<p>5.5 La cláusula [Sobre excepciones] se aplica cuando surjan disputas con el equipo de operaciones. En caso de que presente una queja, el equipo de operaciones no será responsable de proporcionarle información sobre quejas sin infringir su propiedad y reputación, excepto en el caso de un reembolso completo. Si ha obtenido recompensas, activos u otros beneficios debido a errores del sistema u otras razones explicadas por PixiBox, dichos activos no están protegidos y PixiBox no será responsable de los mismos, excepto en el caso de un reembolso total. Si el equipo de operaciones es culpable o comete un error que resulta en daños a su propiedad o reputación, usted tiene derecho a solicitar una compensación a PixiBox. El monto de la compensación no deberá exceder el 120% del valor de la recarga realizada por usted. PixiBox no tiene la obligación de compensar más allá de la pérdida real del usuario.</p>
<br/>
<br/>
<p>6.Incumplimiento del contrato por parte del usuario y su manejo</p>
<br/>
<p>6.1 Si ocurre una de las siguientes circunstancias, se considerará que ha incumplido el contrato: </p>
<p>(1) Violación de las disposiciones de las leyes y regulaciones relevantes al utilizar los servicios de PixiBox.</p>
<p>(2) Violación del presente Contrato o el Acuerdo Complementario del presente Contrato (es decir, el Artículo 2.2 de este Contrato) acordado. Para satisfacer las necesidades de un gran número de usuarios de servicios eficientes y de alta calidad, entiendes y aceptas que PixiBox puede estipular los procedimientos y estándares para el incumplimiento del contrato en las reglas de PixiBox. Por ejemplo, PixiBox puede determinar si constituye un incumplimiento del contrato en función de la relación entre sus datos de usuario y los datos masivos de usuarios; tiene la obligación de proporcionar pruebas suficienles y explicaciones razonables para las anomalías de tus datos, de lo contrario, se considerará un incumplimiento del contrato. </p>
<br/>
<p>6.2 Medidas para tratar el incumplimiento del contrato: "Procesamiento de información": Si la información que publica en PixiBox constituye un incumplimiento del contrato, PixiBox puede eliminar o bloquear de inmediato la información correspondienle o retirar tus productos de los estanles de acuerdo con las reglas correspondienles. "Restricciones de comportamiento": Si sus acciones en PixiBox, o las acciones que no se han implementado en PixiBox pero tienen un impacto en PixiBox y sus usuarios, constituyen un incumplimiento del contrato, PixiBox puede aplicar medidas como la deducción de puntos, la restricción de los derechos de uso de funciones, la suspensión de la provisión de algunos o todos los servicios para ti, etc. Si el comportamiento del cliente constituye un incumplimiento fundamental del contrato, PixiBox puede bloquear su cuenta y cesar la provisión de servicios para usted. "Procesamiento de cuenta de pago PixiBox": Cuando incumple el contrato y existen ciertas circunstancias como fraude, venta de productos falsificados, apropiación de cuentas de otras personas, o si existe un riesgo de poner en peligro la seguridad de las transacciones de otras personas, el equipo de operación determinará el grado de riesgo de su comportamiento y tomará medidas coercitivas para la cuenta, como la cancelación de la recolección de pagos y la delención de los pagos de fondos. "Publicación de los resultados del procesamiento": PixiBox puede hacer pública la información sobre las medidas de incumplimiento del contrato mencionadas anteriormente y otra información ilegal confirmada por documentos legales efectivos de los órganos administrativos o judiciales nacionales en PixiBox. </p>
<br/>
<p>6.3 Responsabilidad de indemnización: Si sus acciones causan pérdidas a PixiBox y/o sus afiliados (incluyendo pérdidas económicas directas, pérdida de buena voluntad y pérdidas económicas indirectas como compensación, resolución, honorarios de abogados y gastos legales pagados a lerceros), el cliente debe indemnizar a PixiBox y/o sus afiliados por todas las pérdidas mencionadas anteriormente. Si sus acciones causan que PixiBox y/o sus afiliados sean objeto de una reclamación por parte de un lercero, PixiBox y/o sus afiliados pueden recuperar todas las pérdidas de ti después de asumir el pago monetario y otras obligaciones hacia el lercer partido. </p>
<br/>
<p>6.4 Acuerdo especial: "Procesamiento asociado": Si usted termina este acuerdo debido a un incumplimiento grave del contrato, con el fin de mantener el orden y proteger los derechos del consumidor, PixiBox y/o sus afiliados pueden no cooperar con usted bajo otros acuerdos y tomar medidas para suspender o incluso terminar el acuerdo, y se le notificará de acuerdo con lo estipulado en el Artículo 8 de este acuerdo. Si los otros acuerdos firmados por PixiBox y usted, y los acuerdos firmados por PixiBox Mall y/o sus afiliados contigo, establecen claramente la situación de su cooperación bajo esta página del acuerdo, PixiBox estará fuera de mantenimiento. Con el fin de mantener el orden y proteger los derechos del consumidor, el acuerdo puede suspenderse o incluso terminarse cuando se reciba una instrucción, y se le notificará de acuerdo con lo estipulado en el Artículo 8 de este acuerdo.</p>
<br/>
<p>7.Cambios en el acuerdo</p>
<br/>
<p>PixiBox se reserva el derecho de realizar modificaciones en este Contrato y en el Acuerdo Complementario de forma periódica, de acuerdo con los cambios en las leyes y regulaciones nacionales, así como para mantener el orden de las transacciones y proteger los derechos e intereses de los consumidores. Se le notificará sobre dichas modificaciones de acuerdo con lo estipulado, para que esté al tanto de los cambios y pueda tomar las medidas necesarias. Es su responsabilidad revisar regularmente los términos y condiciones actualizados y comprender plenamente su contenido.</p>
<br/>
<p>Si el cliente no está de acuerdo con los cambios, tiene derecho a comunicarle con PixiBox para dar su opinión anles de la fecha de entrada en vigor de los cambios. Si se acepta su opinión, PixiBox ajustará los cambios de manera apropiada. Si aún no está de acuerdo con los cambios que han entrado en vigor, debe dejar de utilizar el servicio de PixiBox a partir de la fecha en que se determine que los cambios entran en vigor, y los cambios no tendrán efecto sobre usted. Si continúa utilizando el servicio de PixiBox después de que los cambios entren en vigor, se considerará que usted está de acuerdo con los cambios que han entrado en vigor.</p>
<br/>
<br/>
<p>8. Noticia</p>
<p>8.1 Notificaciones</p>
<br/>
<p>El PixiBox le enviará notificaciones utilizando los métodos de contacto mencionados anteriormente, como anuncios en PixiBox, mensajes de texto SMS al número de contacto que usted proporcionó, y correos electrónicos a la dirección de correo electrónico registrada. Se considerará que los correos electrónicos, mensajes de texto, mensajes del sistema y mensajes dentro de la plataforma enviados a su cuenta han sido entregados una vez que se envíen correctamente.</p>
<br/>
<p>En caso de disputas relacionadas con actividades comerciales en PixiBox, el cliente acepta que las autoridades judiciales (incluyendo, pero no limitado a, tribunales) pueden enviarle documentos legales a través de métodos de comunicación modernos como mensajes de texto, correos electrónicos, herramientas de mensajería instantánea o por correo (incluyendo, pero no limitado a, documentos judiciales). El número de leléfono móvil, dirección de correo electrónico o cuenta de PixiBox que hayas proporcionado para recibir documentos legales serán considerados como los medios de entrega válidos. La dirección postal que indiques será tu dirección de contacto legal o una dirección válida que hayas proporcionado.</p>
<br/>
<p>Aceptas que las autoridades judiciales pueden utilizar uno o más de los métodos de entrega mencionados anteriormente para enviarle documentos legales. Además, estos métodos de entrega se aplicarán en cada etapa del proceso judicial, como en la presentación de demandas, incluyendo la primera instancia, segunda instancia, revisión, ejecución y procedimientos de supervisión.</p>
<br/>
<p>Es la responsabilidad del cliente asegurarle de que la información de contacto proporcionada sea precisa, válida y esté actualizada en tiempo real. Si la información de contacto es incorrecta o no notifica los cambios a tiempo, podría ser responsable de las consecuencias legales derivadas de la falta de entrega o la entrega tardía de los documentos legales.</p>
<br/>
<p>9. Antiadicción y Protección de menores</p>
<br/>
<p>9.1 El exceso de participación en actividades de entretenimiento de pago afectará negativamente su salud física y mental, así como su vida normal. Se recomienda que controle adecuadamente el tiempo que utiliza el servicio cada día y consuma de manera moderada dentro de su capacidad financiera razonable.</p>
<br/>
<p>9.2 Si usted es el tutor de un usuario menor de edad, por favor cumple con la obligación de tulela y evita que los usuarios menores de edad se entreguen a la adicción, el consumo excesivo, la ingesta accidental de partes y otros comportamientos perjudiciales para su salud física y mental. Proporciona información de identificación práctica y válida para demostrar que efectivamente se trata de un menor de edad y negocia de manera razonable con el equipo de operaciones. Esta plataforma no está obligada a reembolsar la recarga realizada por menores que ya han sido enviadas (afectando las ventas secundarias) y cuyo tiempo sea superior a 7 días.</p>
<br/>
<p>10.Terminación del acuerdo </p>
<br/>
<p>10.1 Circunstancias de terminación</p>
<p>"terminación iniciada por el usuario" tiene derecho a terminar este acuerdo de las siguientes maneras:</p>
<p>(1) Anles de que entren en vigencia los cambios, dejas de utilizar el servicio y expresamente no deseas aceptar los cambios;</p>
<br/>
<p>(2) Expresamente no deseas continuar utilizando el servicio de PixiBox y cumples con las condiciones de terminación del servicio.</p>
<br/>
<p>En el caso de "terminación iniciada por PixiBox", PixiBox puede notificarle la terminación de este acuerdo de acuerdo con los métodos de notificación establecidos en el Artículo 8 de este acuerdo:</p>
<br/>
<p>(1) Si violas este acuerdo, PixiBox terminará este acuerdo de acuerdo con la cláusula de incumplimiento de contrato;</p>
<br/>
<p>(2) Si robas cuentas de otras personas, publica información prohibida, engañas a otros, vendes productos falsificados, perturbas el orden del mercado o utilizas medios indebidos para oblener ganancias, PixiBox sellará tu cuenta de acuerdo con las reglas de PixiBox;</p>
<br/>
<p>(3) Además de las circunstancias anleriores, si ha violado repetidamente las disposiciones relevantes de las reglas de PixiBox y las circunstancias son graves, PixiBox tomará medidas para incautar tu cuenta de acuerdo con las reglas de PixiBox;</p>
<br/>
<p>(4) Tu cuenta es recuperada por PixiBox de acuerdo con este acuerdo;</p>
<br/>
<p>(5) Estás relacionado con productos en PixiBox, publicando o vendiendo productos falsificados y de mala calidad/que infringen derechos, violando los derechos legítimos e intereses de lerceros, u otras violaciones graves de la ley;</p>
<br/>
<p>(6) Otras circunstancias en las que el servicio deba ser lerminado.</p>
<br/>
<br/>
<br/>
<p>10.2 Procedimientos posteriores a la terminación del acuerdo</p>
<br/>
<p>"Divulgación de información del usuario" Tras la terminación de este acuerdo, a menos que lo exija expresamente la ley, PixiBox no tiene la obligación de divulgar ninguna información de su cuenta a usted o a un lercero designado.</p>
<p>Después de la terminación de este acuerdo, el equipo de operaciones retiene los siguientes derechos:</p>
<br/>
<p>(1) Continuar almacenando toda la información mencionada en el Artículo 5 de este acuerdo que usted haya conservado en PixiBox.</p>
<br/>
<p>(2) En caso de incumplimiento contractual por su parte en el pasado, PixiBox puede responsabilizarlo por dicho incumplimiento de acuerdo con lo establecido en este acuerdo.</p>
<p>"Procesamiento de transacciones" Tras la terminación de este acuerdo, en relación a los pedidos de transacción generados duranle la vigencia de este acuerdo, PixiBox puede notificar a la contraparte de la transacción y decidir si cerrar el pedido de transacción de acuerdo con la voluntad de la contraparte; si la contraparte solicita continuar con la transacción, usted deberá seguir cumpliendo este acuerdo y las disposiciones establecidas en el pedido de transacción correspondienle, y asumirá cualquier pérdida o costo adicional que se deriven de ello.</p>
<br/>
<p>11. Ley Aplicable, Jurisdicción y Otros</p>
<br/>
<p>"Ley Aplicable"</p>
<p> La validez, entrada en vigencia, inlerpretación, modificación, complemento, terminación y resolución de controversias relacionadas con este Contrato se regirán por las leyes de la República Popular China en su lerritorio continental. En caso de que no existan disposiciones relevantes en la ley, se seguirán las prácticas comerciales y/o prácticas de la industria.</p>
<br/>
<p>"Divisibilidad" </p>
<p>Si alguna disposición de este Contrato se considera inválida, nula o no aplicable, dicha disposición se considerará separable y no afectará la validez y aplicabilidad de las demás disposiciones restanles de este contrato.</p>
`;

export const agreementContentEN = `
<p>Tips clause</p>
<p>You are welcome to sign this "PixiBox Service Agreement" (hereinafter referred to as "this
Agreement") with the operator of the PixiBox (see the definition terms for details) and
make the PixiBox service! The index keywords listed in the front of the terms of service
are only The purpose of helping you understand the subject matter expressed in this clause does
not affect or limit the meaning or interpretation of the terms of this agreement. In order to protect your own rights and interests, it is recommended that you carefully read the specific expressions of each clause.
</p>
<p>"Read carefully" Before you click to agree to this agreement during the registration application
process, you should read this agreement carefully. Please read carefully and fully understand the
contents of each clause, especially the clauses that exempt or limit liability. Applicable Law and
Dispute Resolution Clause. Terms that exclude or limit liability will be underlined in bold and you
should read them carefully. If you have any questions about the agreement, you can consult
the customer service of PixiBox.
</p>
<p>"Signing Action" When you fill in the information according to the prompts on the registration page, read and agree to this agreement, and complete all the registration procedures, it means that you have fully read, understood and accepted the entire content of this agreement, and reached an agreement with PixiBox. , become a "user" of PixiBox. In the process of reading this agreement, if you do not agree with this agreement or any of its terms, you should
immediately stop the registration process.</p>
<p>1. Definition</p>
<p>PixiBox Service Agreement</p>
<p>PixiBox: Refers to PixiBox APP, PixiBox H5 website (named PixiBox).</p>
<p>PixiBox Services: PixiBox is based on the Internet, and provides various services to you in various forms including PixiBox, APP, website, etc. (including new service forms that
have not yet developed technology). .</p>
<p>PixiBox Rules: Including all PixiBox rules, all rules, interpretations, announcements
and other content that have been published and subsequently released in the channel, as well as
various rules, implementation details, Product process descriptions, announcements, etc.</p>
<p>2. Scope of the agreement</p>
<p>2.1 Subject of the contract</p>
<p>"Equal Subject” This agreement is jointly concluded by you and the operator of PixiBox,
and this agreement has contractual effect between you and the operator of PixiBox.
"Subject Information" PixiBox operators refer to the legal entities that operate PixiBox
Mall. Under this agreement, the operator of PixiBox may be changed according to the
business adjustment of PixiBox. The changed operator of PixiBox will perform
this agreement with you and provide services to you. The change of operator of PixiBox will not will affect your rights under this Agreement. PixiBox operators may also add new
services to provide new PixiBox. If you use the new PixiBox service, it is deemed that you agree to the new operator to perform this agreement with you. In the event of a dispute, you
can determine the subject of the contract with you and the counter party to the dispute based on the specific services you use and the specific behavior object that affects your rights and interests.
</p>
<p>2.2 Supplementary Agreement</p>
<p>Due to the rapid development of the Internet, the terms set out in this agreement signed by you and PixiBox cannot completely list and cover all rights and obligations between you and PixiBox, and the existing agreements cannot guarantee that they fully meet the needs of future
development. Therefore, the agreements that have not been added to the PixiBox are
supplementary agreements to this agreement, which are inseparable from this agreement and
have the same legal effect. If there is a new agreement, PixiBox will inform you. If you use
PixiBox services, it is deemed that you agree to the above supplementary agreement.
</p>
<p>3. Account registration and use </p>
<p>3.1 Registration Information Management</p>
<p>3.1.1 Real and legal</p>
<p>"Real Information" When using the services of PixiBox, you should provide your information
(including your name and email address, contact number, contact address, etc.) accurately and
completely according to the prompts on the page of PixiBox, so that PixiBoxcan provide you
with accurate and complete information. or other users to contact you. You understand and agree that you are obliged to maintain the authenticity and validity of the information you provide.
</p>
<p>3.1.2 Update maintenance</p>
<p>You should update the information you provide in a timely manner. Under the circumstance that the law clearly requires PixiBox as a service provider to verify the information of some users (such as sellers, etc.), PixiBox will from time to time in accordance with the law. For inspection and verification, you should cooperate in providing up-to-date, true, complete and effective
information. If PixiBox fails to contact you according to the information you provided for the last time, you fail to provide information in a timely manner as required by PixiBox. If the information you provide is obviously false or the administrative and judicial organs verify that the information you provide is invalid, you You will be responsible for all losses and adverse
consequences caused to you, others and PixiBox. The PixiBox operation team may send you a notice of inquiry or request for rectification, and require you to re-certify until it suspends or terminates the provision of part or all of the PixiBox service to you, and PixiBox will not be
responsible for this.
</p>
<p>4. Protection and authorization of user information</p>
<p>4.1 Protection of personal information</p>
<p>PixiBox attaches great importance to the protection of users' personal information (that is,
information that can identify the user's identity independently or in combination with other
information). Privacy Policy The collection, storage, use, disclosure and protection of your
personal information are based on compliance with relevant WeChat platform agreements.
</p>
<p>5. About exceptions</p>
<p>5.1 For possible errors in the operation process, such as wrong product price, abnormal product
name (such as wrong price and style), etc., the operation team will explain the relevant
agreement on dispute resolution as follows:-</p>
<p>5.2 Except for special circumstances, the definition of hidden and rare styles in the Bang
Box style is subject to the official description. If the name and style are wrong, we will deal with
the income beyond the reasonable range according to the [About exception] clause.
</p>
<p>5.3 When you find some obvious errors, such as the price of multiple specifications is significantly
lower than the price of a single product, this problem may be caused by work mistakes, if you
contact us in time and feedback the problem, we will give you a reasonable reward . The proceeds from such issues will be dealt with in accordance with the[About Exceptions] clause.
contact us in time and feedback the problem, we will give you a reasonable reward . The proceeds from such issues will be dealt with in accordance with the [About Exceptions] clause.
</p>
<p>5.4 When an abnormality or failure is found, the operation team will explain the relevant problem to you and provide a solution after the problem is verified. The benefit of the solution should be same or higher than the cost you actually paid.lf you cannot follow the relevant treatment plan, the two parties should conduct friendly communication.lf you refuse to communicate or cannot contact you normally, we will deal with it in accordance with the [About Abnormal clause 3 working days after we confirm that we cannot contact you.</p>
<p>5.5[About exceptions] The clause is the principle when you have relevant disputes with the
operation team. For any customer complaints, the operation team will not be responsible for
[providing you with any customer complaints without infringing on your property and
reputation.Indemnity obligations other than full refund]. And if the rewards, asset values,etc. You 
have obtained come from system errors and other reasons explained by PixiBox, the assets you
have obtained are not protected, and PixiBox will not be responsible for any such assets
[provide you with the full amount. Other indemnity obligations other than refund].In the
event of the operation team's own fault or error resulting in damage to your property or
reputation, you have the right to apply for relevant compensation to PixiBox. The amount
of compensation should not exceed 120% of your relevant recharge behavior. PixiBoxwill not The part that deviates from the actual loss of the user has the right to not be compensated
</p>
<p>6. User's breach of contract and handling</p>
<p>6.1 lf one of the following circumstances occurs, you shall be deemed to be in breach of contract:</p>
<p>(1) Violating the provisions of relevant laws and regulations when using the services of PixiBox</p>
<p>(2) Violation of this Agreement or the</p>
<p>Supplementary Agreement to this Agreement (that is, Article 2.2 of this Agreement) Article) agreed In order to meet the needs of a large number of users for efficient and high-quality services, you understand and agree that PixiBox can stipulate the procedures and standards for breach of contractin the PixiBox rules. For example: PixiBox can determine whether you constitute a breach of contract based on the relationship between your user data and massive user data; you are obliged to provide sufficient evidence and reasonable explanations for your data anomalies, otherwise it will be deemed a breach of contract.</p>
<p>6.2 Measures to deal with breach of contract "Information processing  f the information you
publish on the PixiBox constitutes a breach of contract, the PixiBox can immediately delete or block the corresponding information or remove your products from the shelves according to the corresponding rules "Behavior Restrictions" lf your actions on the
PixiBox, or the actions that have not been implemented on the PixiBox but have an
impact on the PixiBox and its users constitute a breach of contract, the PixiBox
can enforce your account according to the
corresponding rules. Deduction of points, restriction offunction use rights, suspension of
providing some or all services to you, etc. If your behavior constitutes a fundamental breach of
contract, PixiBoxmay seal up your account and terminate the provision of services to you.
"PixiBox Payment Account Processing 
When you breach the contract and there are certain circumstances such as fraud, selling fakes.
embezzling other people's accounts, or you have a risk of endangering the security of other
people's transaction security accounts, the operation team will determine your blindness
according to the degree of risk of your behavior. Box Mall takes coercive measures for the account such as cancellation of payment collection and fund stop payment. "Publication of Processing Results" PixiBox can publicize the information on your above mentioned breach of contract measures and other illegalinformation confirmed by effective legal documents of national administrative orjudicial organs on PixiBox.
</p>
<p>6.3Liability for compensation</p>
<p>If your actions cause PixiBox and/or its affiliates to suffer losses (including direct economic losses, loss of goodwill, and indirect economic losses such as compensation, settlement, attorney fees and litigation fees paid to the outside world), you should Indemnify PixiBox and/or its
affiliates for all the above losses lf your actions cause PixiBoxand/or its affiliates to suffer a claim by a third party, PixiBox and/or its affiliates may recover all losses from you after
assuming monetary payment and other obligations to the third party</p>
<p>6.4 Special agreement</p>
<p>"Associated processing if you terminate this agreement due to serious breach of contract, for
the purpose of maintaining order and protecting consumer rights, PixiBoxand/or its affiliates
may not cooperate with you under other agreements Take measures to suspend or even
terminate the agreement and notify you in the manner stipulated in Article 8 of this agreement.
lf the other agreements signed by PixiBox and you and the agreements signed by PixiBox
Mall and/or its affiliates with you clearly stipulate the situation ofyour cooperation under this
agreement page, the PixiBox is out of maintenance.For the purpose of order and
protection of consumer rights, the agreement may be suspended or even terminated when an lf the other agreements signed by PixiBox and you and the agreements signed by PixiBox
Mall and/or its affiliates with you clearly stipulate the situation ofyour cooperation under this
agreement page, the PixiBox is out of maintenance.For the purpose of order and
protection of consumer rights, the agreement may instruction is received, and you will be notified in the manner stipulated in Article 7 of this agreement.
</p>
<p>7. Eight Agreement Changes</p>
<p>PixiBox may modify this Agreement and Supplementary Agreement from time to time in
accordance with changes in national laws and regulations and the need to maintain the
transaction order to protect the rights and interests of consumers. You will be notified in the
manner stipulated in Article 8.
</p>
<p>If you do not agree with the changes, you have the right to contact PixiBox for feedback before the effective date of the changes. If the feedback is adopted, PixiBox will adjust the changes appropriate. If you still do not agree with the changes that have taken effect, you should stop using the PixiBoxservice from the date when the changes are determined to take effect, and the changes have no effect on you; if you continue to use the changes after the changes take effect PixiBox service, it is deemed that you agree to the changes that have taken effect.</p>
<p>8. Notice</p>
<p>8.1 Delivery of notices</p>
<p>The PixiBox will notify you through the above contact methods, including written notices
sent electronically, including but not limited to announcements in the PixiBox, sending
SMS to the contact number provided by you, and email address provided to you. Sending e-mails,
sending information, system messages and in-site messages to your account will be deemed to be delivered after successful sending.
</p>
<p>For any disputes arising from trading activities on the PixiBox, you agree that judicial
authorities (including but not limited to people's courts) may serve legal documents to you through modern communication methods such as mobile phone text messages, emails, instant messaging tools, or by mail. (including but not limited to litigation documents). The contact information such as the mobile phone number, e-mail or PixiBox account that you designate to receive legal documents are the mobile phone number and email contact information you provide when registering and updating in PixiBox, and the account number generated by PixiBox when you register a user, Legal documents issued
by judicial organs to the above contact information are deemed to be served. The mailing address you specify is your legal contact address or a valid contact address you provide.
</p>
<p>You agree that the judicial authority may use one or more of the above delivery methods to deliver legal documents to you. The judicial authority may deliver legal documents to you by various methods. </p>
<p>You agree that the above methods of service apply to each stage of the judicial process. Such as
entering the proceedings, including but not limited to the first instance, second instance, retrial, enforcement and supervision procedures.
</p>
<p>You should ensure that the contact information provided is accurate, valid and updated in real
time. If the contact information provided is inaccurate, or the changed contact information is
not notified in time, the legal documents cannot be delivered or are not delivered in time, you shall be responsible for the legal consequences that may arise therefrom.
</p>
<p>9. Anti-addiction and protection of minors</p>
<p>9.1 Excessive indulging in paid entertainment activities will adversely affect your physical and
mental health and normal life. It is recommended that you appropriately control the time you use
the service every day, and consume moderately within your reasonable financial capacity.
</p>
<p>9.2 If you are the guardian of a minor user, please take the initiative to fulfill the obligation of
guardianship to prevent minor users from indulging in addiction, excessive consumption,
eating parts by mistake and other behaviors that are detrimental to their physical and mental
health. You provide practical and valid identification information to prove that itis
indeed committed by a minor and reasonably negotiate with the operation team. This platform
is not obligated to refund the recharge of minors that have been shipped (affecting secondary
sales) and whose time is more than 7 days.
</p>
<p>10. Termination of the Agreement</p>
<p>10.1 Circumstances of termination</p>
<p>"User-Initiated Termination" You have the right to terminate this Agreement in any of the following ways:</p>
<p>(1) Before the changes take effect, you stop using and expressly do not want to accept the changes;</p>
<p>(2) You expressly do not want to continue to use the PixiBoxservice and meet the service
termination conditions.</p>
<p>In the event of "termination initiated by PixiBox", PixiBox may notify you to terminate this Agreement in the manner listed in Article 8 of this Agreement:</p>
<p>(1) If you violate this agreement, PixiBox terminates this agreement in accordance with the breach of contract;</p>
<p>(2) If you steal other people's accounts, publish prohibited information, defraud others, sell fakes,
disrupt market order, or take improper means to make profits, PixiBoxwill seal up your account
in accordance with PixiBox's rules;
</p>
<p>(3) In addition to the above circumstances,
because you have repeatedly violated the relevant provisions of the PixiBox rules and the
circumstances are serious, PixiBox has seized your account in accordance with the Bang
Box Mall rules;
</p>
<p>(4) Your account is recovered by PixiBox in accordance with this Agreement;</p>
<p>(5) You are associated with products in the PixiBox, publishing or selling fake and
shoddy/infringing goods, infringing on the legitimate rights and interests of others, or other
serious violations of the law;
</p>
<p>(6) Other circumstances in which the service should be terminated.</p>
<p>10.2 Processing after termination of the agreement</p>
<p>"User Information Disclosure" After the termination of this agreement, except as expressly provided by law, PixiBoxhas no obligation to disclose any information in your account to you or
a third party you designate.
</p>
<p>After the termination of this agreement, the operation team still enjoys the following rights:</p>
<p>(1) Continue to save the various types of information listed in Article 5 of this Agreement
that you have retained in the PixiBox;
</p>
<p>(2) For your past breach of contract, PixiBoxmay still hold you accountable for breach of contract in accordance with this Agreement.</p>
<p>"Transaction processing" After the termination of this agreement, for the transaction orders you
generate during the existence of this agreement, PixiBox can notify the counterparty of the transaction and decide whether to close the transaction order according to the wishes of the
counterparty; if the counterparty requests to continue If it is fulfilled, you shall continue to
perform this agreement and the stipulations of the transaction order with respect to such
transaction order, and shall bear any loss or any additional cost arising therefrom.
</p>
<p>11. Applicable Law, Jurisdiction and Others</p>
<p>"Applicable Law" The conclusion, entry into force, interpretation, revision, supplementary
termination of execution and dispute resolution of this Agreement shall be governed by the laws of the Mainland of the People's Republic of China; if there are no relevant provisions in the law,
business practices and/or industry practices shall be referred to. 
</p>
<p>"Severability" If any provision of this Agreement is deemed void, void or unenforceable, such
provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions of this agreement.
</p>
`;

export const agreementContentZHCN = `
<p>提示条款</p>
<p>欢迎您签署本《PixiBox服务协议》（以下简称“本协议”）！ 服务条款前面列出的索引关键字仅帮助您理解本条款中表达的主题的目的并不不影响或限制本协议条款的含义或解释。 为了保护您自身的权益，建议您仔细阅读各条款的具体表述。</p>
<p>注册申请时点击同意本协议前请“仔细阅读”过程中，您应仔细阅读本协议。 请仔细阅读并充分理解各条款的内容，特别是免除或限制责任的条款。排除或限制责任的条款将以粗体下划线显示，并且您应该仔细阅读它们。如果您对协议有任何疑问，可以咨询PixiBox 的客户服务。</p>
<p>《签署行动》当您按照注册页面提示填写信息、阅读并同意本协议，并完成全部注册程序时，即表示您已充分阅读、理解并接受本协议全部内容，并与PixiBox达成协议，成为PixiBox的“用户”。 在阅读本协议的过程中，如果您不同意本协议或其任何条款，您应该立即停止注册过程。</p>
<p>一、定义</p>
<p>PixiBox服务协议</p>
<p>PixiBox：指PixiBox APP、PixiBox H5网站（名为PixiBox）。</p>
<p>PixiBox服务：PixiBox基于互联网，以PixiBox、APP、网站等多种形式为您提供各种服务。 （包括新的服务形式尚未开发出技术）。</p>
<p>PixiBox规则：包括所有PixiBox规则、所有规则、解释、公告以及其他已发布并随后在频道上发布的内容，以及各类规则、实施细则、产品流程说明、公告等。</p>
<p>二、协议范围</p>
<p>2.1 合同标的</p>
<p>《平等主体》本协议由您与PixiBox运营者共同签订，本协议在您与 PixiBox 运营商之间具有合同效力。“主体信息” PixiBox 运营商是指运营 PixiBox 的法人实体根据本协议，PixiBox 的运营商可能会根据以下情况发生变更：PixiBox业务调整。 PixiBox 更改后的操作符将执行
与您签订本协议并向您提供服务。 PixiBox运营商的变更不会影响您在本协议项下的权利。 PixiBox 运营商也可能会添加新的提供新的 PixiBox 服务。如果您使用新的PixiBox服务，即视为您同意新运营商与您共同履行本协议。如果发生争议，您可以根据您使用的具体服务以及影响您权益的具体行为对象，确定与您及争议对方的合同主体。</p>
<p>2.2 补充协议</p>
<p>由于互联网的快速发展，您与 PixiBox 签署的本协议中的条款无法完整列出和涵盖您与 PixiBox 之间的所有权利和义务，现有协议也不能保证完全满足未来的需要发展。 因此，尚未添加到 PixiBox 中的协议有本协议的补充协议，与本协议不可分割；具有同等法律效力。如果有新的协议，PixiBox 会通知您。 如果你使用PixiBox服务，即视为您同意上述补充协议。 </p>
<p>三、账户注册及使用</p>
<p>3.1 注册信息管理</p>
<p>3.1.1 实际和法律</p>
<p>“真实信息” 在使用PixiBox服务时，您应提供您的信息（包括您的姓名和电子邮件地址联系电话、联系地址等）准确且完全按照PixiBox页面的提示操作，以便PixiBox为您提供
并提供准确、完整的信息。 或其他用户与您联系。 您理解并同意，您有义务保持所提供信息的真实性、有效性。</p>
<p>3.1.2 更新维护</p>
<p>您应当及时更新您所提供的信息。 在法律明确要求PixiBox作为服务提供商验证部分用户（如卖家等）信息的情况下，PixiBox将不时依法依规进行核实。 为便于查证，您应配合提供最新、真实、完整、有效的资料信息。如果PixiBox未能根据您上次提供的信息与您取得联系，则说明您未能按照PixiBox的要求及时提供信息。 如果您提供的信息明显虚假或行政、司法机关核实您提供的信息无效，您将承担一切损失和不利后果
对您、他人和 PixiBox 造成的后果。 PixiBox运营团队可能会向您发出询问或要求整改的通知，并要求您重新进行认证，直至暂停或终止向您提供部分或全部PixiBox服务，且PixiBox不会对此负责。</p>
<p>四、用户信息的保护与授权</p>
<p>4.1 个人信息的保护</p>
<p>PixiBox非常重视对用户个人信息（即能够独立或者与他人结合识别用户身份信息）。 隐私政策包括收集、存储、使用、披露和保护您的信息个人信息基于遵守相关微信平台协议。</p>
<p>5.关于例外情况</p>
<p>5.1 对于操作过程中可能出现的错误，如产品价格错误、产品异常等名称（如价格、款式错误）等，运营团队会解释相关争议解决协议如下：</p>
<p>5.2 除特殊情况外，Bang中隐藏和稀有样式的定义盒子样式以官方说明为准。 如果名称和款式有误，我们将处理超出【关于例外】条款规定的合理范围的收入。</p>
<p>5.3 当您发现一些明显的错误，例如多个规格的价格明显不同时低于单品价格，此问题可能是工作失误造成的，如果您及时联系我们并反馈问题，我们会给您合理的奖励。 此类发行的收益将按照【关于例外情况】条款处理。及时联系我们并反馈问题，我们会给您合理的奖励。 此类发行的收益将按照【关于例外情况】条款处理。</p>
<p>5.4 当发现异常或故障时，运营团队将向您解释相关问题，并在核实问题后提供解决方案。 解决方案的效益应等于或高于您实际支付的费用，如果您不能遵循相关处理方案，双方应进行友好沟通，如果您拒绝沟通或无法正常联系您，我们将予以处理根据【关于异常条款】我们确认无法联系到您后3个工作日。</p>
<p>5.5【关于例外】本条款是您与本网站发生相关争议时的原则运营团队。 对于任何客户投诉，运营团队将不承担任何责任[为您提供任何客户投诉而不侵犯您的财产和声誉。全额退款以外的赔偿义务]。 如果奖励、资产价值等 你因系统错误及PixiBox解释的其他原因而获得的资产，您所获得的资产不受保护，PixiBox 不对任何此类资产负责【为您提供全额。 退款以外的其他赔偿义务]。因运营团队自身失误或失误导致您的财产损失或声誉，您有权向 PixiBox 申请相关赔偿。 量补偿金额不应超过您相关充值行为的120%。 PixiBox不会对用户造成的偏离实际损失的部分有权不予赔偿。</p>
<p>六、用户违约及处理</p>
<p>6.1 有下列情形之一的，视为您违约：（1）使用PixiBox服务时违反相关法律法规的规定
(2) 违反本协议或本协议的补充协议（即本协议第2.2条）约定为了满足大量用户对高效、优质服务的需求，您理解并同意PixiBox可以规定程序和标准违反 PixiBox 规则中的收缩。 例如：PixiBox可以根据您的用户数据与海量用户数据之间的关系判断您是否构成违约； 您有义务就您的数据异常提供充分的证据和合理的解释，否则将被视为违约。</p>
<p>6.2 违约处理措施《对您所提供信息的信息处理》</p>
<p>如果您在PixiBox上发布的行为构成违约，PixiBox可以立即根据相应规则《行为限制》删除、屏蔽相应信息或下架您的商品。PixiBox，或尚未在 PixiBox 上实现但具有对 PixiBox 及其用户的影响构成违约，PixiBox可以根据以下规定强制执行您的帐户相应的规则。 扣分、限制功能使用权、暂停使用向您提供部分或全部服务等。 如果您的行为构成根本违反
根据合同，PixiBox可能会查封您的账户并终止向您提供服务。《PixiBox 支付账户处理
当您违反合同并存在欺诈、售假等某些情况时。盗用他人账户，或者存在危害他人安全的风险人们的交易安全账户，运营团队将决定你的盲目性根据您行为的风险程度。 Box Mall对该账户采取取消收款、资金止付等强制措施。 “处理结果公示” PixiBox可以在PixiBox上公示您的上述违约措施信息以及经国家行政或司法机关生效法律文件确认的其他违法信息。
</p>
<p>6.3 赔偿责任</p>
<p>如果您的行为导致PixiBox和/或其关联公司遭受损失（包括直接经济损失、商誉损失以及向外界支付的赔偿金、和解费、律师费、诉讼费等间接经济损失），您应对PixiBox和/或其关联公司进行赔偿。 /或其若您的行为导致 PixiBox 和/或其关联公司遭受第三方索赔，PixiBox 和/或其关联公司可在事后向您追偿所有损失。向第三方承担金钱支付和其他义务。</p>
<p>6.4 特别协议</p>
<p>“因严重违约而终止本协议的关联处理，PixiBox 和/或其关联公司维护秩序和保护消费者权益的目的可能不会根据其他协议与您合作 采取暂停甚至采取措施终止本协议并按照本协议第八条规定的方式通知您。如果PixiBox与您签署的其他协议以及PixiBox签署的协议
商城及/或其关联公司与您明确约定您在此项下的合作情况协议页面，PixiBox 已停止维护。为了订购和使用为保护消费者权益，当PixiBox与您签署的其他协议以及PixiBox签署的协议发生时，本协议可能会被暂停甚至终止。商城及/或其关联公司与您明确约定您在此项下的合作情况协议页面，PixiBox 已停止维护。为了订购和使用为保护消费者权益，本协议可能会收到指令，并将按照本协议第七条规定的方式通知您。
</p>
<p>7. 八项协议变更</p>
<p>PixiBox 可能会不时修改本协议及补充协议根据国家法律法规的变化和维护的需要
交易秩序，保护消费者权益。 您将在以下位置收到通知第八条规定的方式。</p>
<p>如果您不同意变更，您有权在变更生效日期前联系 PixiBox 寻求反馈。 如果反馈被采纳，PixiBox 将适当调整更改。 如果您仍不同意已生效的变更，您应自变更确定生效之日起停止使用PixiBox服务，变更对您不产生影响； 如果您在变更生效后继续使用变更后的PixiBox服务，则视为您同意已生效的变更。</p>
<p>八、注意事项</p>
<p>8.1 通知的送达</p>
<p>PixiBox将通过上述联系方式通知您，包括书面通知以电子方式发送，包括但不限于 PixiBox 中的公告、发送通过短信发送至您提供的联系电话以及为您提供的电子邮件地址。 发送电子邮件，向您的账户发送信息、系统消息、现场消息，发送成功后即视为送达。</p>
<p>对于因PixiBox交易活动产生的任何争议，您同意司法管辖机关（包括但不限于人民法院）可以通过手机短信、电子邮件、即时通讯工具、邮寄等现代通讯方式向您送达法律文书。 （包括但不限于诉讼文件）。 您指定用于接收法律文件的手机号码、电子邮箱或PixiBox账户等联系信息是您在PixiBox注册、更新时提供的手机号码、电子邮箱联系信息，以及您注册时PixiBox生成的账号。注册用户、出具法律文件司法机关对上述联系方式均视为送达。 您指定的邮寄地址是您的合法联系地址或您提供的有效联系地址。</p>
<p>您同意司法机关可以采用上述一种或多种送达方式向您送达法律文书。 司法机关可以通过多种方式向您送达法律文书。您同意上述送达方式适用于司法程序的各个阶段。 例如进入诉讼程序，包括但不限于一审、二审、再审、执行和监督程序。您应确保所提供的联系方式准确、有效、真实更新时间。如果所提供的联系信息不准确，或者更改后的联系信息
未及时通知，无法送达或未及时送达法律文件的，由此产生的法律后果由您自行承担。
</p>
<p>九、防沉迷及未成年人保护</p>
<p>9.1 过度沉迷于付费娱乐活动会对您的身体和健康产生不利影响心理健康和正常生活。 建议您适当控制使用时间每日服务，并在合理经济能力范围内适度消费。</p>
<p>9.2 如果您是未成年用户的监护人，请主动履行以下义务：</p>
<p>监护防止未成年人沉迷成瘾、过度消费、误食部位及其他有损身心的行为健康。 您提供实际有效的身份信息以证明其是确实是未成年人所为，并与运营团队合理协商。 这个平台已发货的未成年人充值没有义务退还（影响二次元）销售）且时间超过7天。</p>
<p>十、 协议的终止</p>
<p>10.1 终止的情况</p>
<p>“用户主动终止”您有权通过以下方式之一终止本协议：</p>
<p>(1) 在变更生效前，您停止使用并明确不愿接受变更的；</p>
<p>(2) 您明确不想继续使用PixiBox服务并满足该服务终止条件。</p>
<p>若发生“PixiBox 主动终止”的情况，PixiBox 可以按照本协议第八条所列方式通知您终止本协议：</p>
<p>（1）如果您违反本协议，PixiBox将按照违约行为终止本协议；</p>
<p>（2）如果盗用他人账号、发布禁止信息、诈骗他人、售假、扰乱市场秩序，或采取不正当手段牟利，PixiBox将查封您的账户遵守 PixiBox 的规则；</p>
<p>（3）除上述情形外，
由于您多次违反PixiBox规则的相关规定及情节严重，PixiBox已按照PixiBox规则要求查封您的账号；
</p>
<p>（4）PixiBox按照本协议约定恢复您的账户；</p>
<p>（5）您与PixiBox中的产品存在关联，发布、销售假冒伪劣产品；伪劣/侵权商品、侵犯他人合法权益或其他严重违法行为；</p>
<p>（6）其他应当终止服务的情形。</p>
<p>10.2 协议终止后的处理</p>
<p>《用户信息披露》本协议终止后，除法律明确规定外，PixiBox没有义务向您或其他方披露您账户中的任何信息到第三方。本协议终止后，运营团队仍享有以下权利：</p>
<p>（1）继续保存本协议第五条所列各类信息您保留在 PixiBox 中的信息；</p>
<p>（2）对于您以往的违约行为，PixiBox仍可依据本协议追究您的违约责任。《交易处理》 本协议终止后，对于您的交易订单在本协议存续期间，PixiBox可以通知交易对方并根据交易对方的意愿决定是否关闭交易订单交易对手； 如果对方要求继续履行的话，您应当继续
履行本协议及交易订单的相关规定交易订单，并承担由此产生的任何损失或任何额外费用。
</p>
<p>十一、适用法律、管辖权及其他</p>
<p>《适用法律》的结论、生效、解释、修改、补充本协议的终止执行及争议解决适用中华人民共和国大陆地区法律； 如果法律没有相关规定，应参考商业惯例和/或行业惯例。</p>
<p>“可分割性”</p>
<p>如果本协议的任何条款被视为无效、作废或不可执行，则此类条款</p>
<p>条款应被视为可分割的，且不影响本协议其余条款的有效性和可执行性。</p>
`;

export const agreementContentPTBR = `
<p>Cláusula de lembrete</p>
<p>Você está cordialmente convidado a celebrar este "Contrato de Serviço PixiBox" (doravante referido como "Este Contrato") com a operadora PixiBox (consulte os termos de definição para obter mais detalhes) e usar os serviços PixiBox. As palavras-chave mencionadas no início dos termos de serviço são utilizadas exclusivamente com o objetivo de ajudar na compreensão do conteúdo expresso nesta cláusula e não afetam ou limitam o significado ou a interpretação dos termos deste contrato. A fim de proteger seus próprios direitos e interesses, é recomendável que você leia atentamente as disposições específicas de cada cláusula. Por favor, revise, ajuste e otimize este parágrafo de acordo com as regras e requisitos do contrato comercial.</p>
<p>"Por favor, leia" antes de clicar para aceitar este contrato durante o processo de solicitação de registro, recomendamos que você leia atentamente este contrato. Por favor, leia atentamente e entenda completamente o conteúdo de cada cláusula, especialmente aquelas que isentam ou limitam a responsabilidade. A Cláusula de Lei Aplicável e Resolução de Disputas. Os termos que excluem ou limitam a responsabilidade serão destacados em negrito e sugerimos que você os leia com atenção. Se você tiver alguma dúvida sobre o contrato, consulte o atendimento ao cliente da PixiBox.</p>
<p>"Ação de Assinatura" Quando você preenche as informações conforme indicado na página de registro, lê e aceita este contrato e conclui todos os procedimentos de registro, isso significa que você leu, entendeu e aceitou totalmente o conteúdo deste contrato e chegou a um acordo com a PixiBox, tornando-se um "usuário" da PixiBox. No processo de leitura deste acordo, se você não concordar com este acordo ou qualquer um de seus termos, você deve interromper imediatamente o processo de registro.</p>
<p>1.Definição</p>
<p>Acordo de Serviço PixiBox</p>
<p>Serviços PixiBox: PixiBox é baseado na Internet e fornece vários serviços em várias formas, incluindo PixiBox, APP, site, etc. (incluindo novas formas de serviço ainda não desenvolvidas tecnologicamente). Regras do PixiBox: Inclui todas as Regras do PixiBox, todas as regras, interpretações, anúncios e outros conteúdos que foram publicados e posteriormente divulgados no canal, bem como diversas regras, detalhes de implementação, descrições do processo do produto, anúncios, etc.</p>
<p>2. Âmbito do acordo</p>
<p>2.1 Objetivo do contrato</p>
<p>"Objeto Equivalente"</p>
<p>Este acordo é celebrado conjuntamente entre você e a operadora PixiBox, e tem efeito contratual entre ambas as partes.</p>
<p>"Informações do objeto"</p>
<p>O termo “operadores PixiBox” refere-se às pessoas jurídicas responsáveis pela operação do shopping PixiBox. Nos termos deste acordo, o operador da PixiBox pode mudar devido a ajustes de negócios. Em caso de mudança de operador, o novo operador cumprirá este acordo consigo e fornecer-lhe-á os serviços correspondentes. É importante ressaltar que a mudança de operadora da PixiBox não afetará seus direitos estabelecidos neste contrato.</p>
<p>Além disso, as operadoras PixiBox podem agregar novos serviços para oferecer diversas opções aos usuários. Se você decidir usar um novo serviço PixiBox, será considerado que você concordou com o novo operador para realizar este acordo com você. Em caso de disputa, a determinação do objeto do contrato e das partes envolvidas na disputa será baseada nos serviços específicos que você usa e nas ações específicas que afetam seus direitos e interesses.</p>
<p>2.2 Acordo Suplementar</p>
<p>Devido ao rápido desenvolvimento da Internet, os termos estabelecidos neste acordo assinado por você e a PixiBox não podem cobrir exaustivamente todos os direitos e obrigações entre ambas as partes, e os acordos existentes podem não atender totalmente às necessidades de desenvolvimento futuro. Portanto, quaisquer contratos adicionais que não estejam incluídos neste documento serão considerados como um complemento a este contrato e terão o mesmo efeito legal. No caso de um novo acordo ser estabelecido, a PixiBox irá informá-lo sobre isso. Ao utilizar os serviços da PixiBox, entender-se-á que aceita o acordo complementar mencionado acima.</p>
<p>3.Registro e uso da conta</p>
<p>3.1 Gestão das informações cadastrais</p>
<p>3.1.1 Veracidade e legalidade</p>
<p>"Informações verdadeiras"</p>
<p>Ao usar os serviços da PixiBox, você concorda em fornecer de forma precisa e completa suas informações pessoais, incluindo seu nome, endereço de e-mail, número de contato, endereço de contato ou outras informações necessárias de acordo com as instruções fornecidas na página da PixiBox. Esta informação é necessária para que a PixiBox possa lhe fornecer informações precisas e completas, bem como para que outros usuários possam entrar em contato com você. Você reconhece e concorda que é sua responsabilidade manter a autenticidade e validade das informações fornecidas.</p>
<p>3.1.2 Atualização e manutenção</p>
<p>Você concorda em manter atualizadas as informações fornecidas. Caso a PixiBox, como prestadora de serviços, seja obrigada por lei a verificar as informações de determinados usuários (como fornecedores, por exemplo), a PixiBox realizará inspeções e verificações periódicas de acordo com a lei. Você deve cooperar fornecendo informações atualizadas, verdadeiras, completas e eficazes. Se a PixiBox não conseguir entrar em contato com você usando as informações fornecidas acima, se você não fornecer as informações necessárias em tempo hábil conforme solicitado pela PixiBox, se as informações fornecidas forem claramente falsas ou se as informações fornecidas forem consideradas inválidas por processos administrativos e judiciais autoridades , você será responsável por todas as perdas e consequências adversas para você, outros usuários e PixiBox.</p>
<p>A equipe de operações da PixiBox pode enviar a você notificações de consulta ou solicitações de correção e pode solicitar que você re-certifique suas informações até que o fornecimento dos serviços da PixiBox a você seja suspenso ou parcial ou totalmente encerrado. Nesse caso, a PixiBox não será responsável por tal suspensão ou rescisão.</p>
<p>4. Proteção e autorização das informações do usuário</p>
<p>4.1 Proteção de informações pessoais</p>
<p>A PixiBox atribui grande importância à proteção das informações pessoais dos usuários (ou seja, informações que possam identificar a identidade do usuário de forma independente ou em combinação com outras informações). A Política de Privacidade afirma que a coleta, armazenamento, uso, divulgação e proteção de suas informações pessoais são baseadas na conformidade com os contratos de plataforma relevantes.</p>
<p>5.Exceções</p>
<p>Maneira:</p>
<p>5.2 Exceto em circunstâncias especiais, a definição de estilos ocultos e raros no estilo PixiBox está sujeita à descrição oficial. Se o nome e o estilo estiverem incorretos, trataremos a receita além do intervalo razoável de acordo com a cláusula [Sobre exceções].</p>
<p>5.3 Quando você encontra alguns erros óbvios, como o preço de várias especificações que é significativamente inferior ao preço daquele produto, esse problema pode ser causado por erros do sistema. Se você entrar em contato conosco a tempo e relatar o problema de maneira adequada e oportuna, nós lhe daremos uma recompensa razoável. As receitas de tais emissões serão tratadas de acordo com a cláusula [Sobre Exceções].</p>
<p>5.4 Caso seja detectada alguma anormalidade ou falha, a equipe de operações lhe fornecerá uma explicação detalhada do problema e buscará uma solução após a verificação. A solução proposta deve oferecer benefícios iguais ou superiores ao custo efetivamente pago. Se você não conseguir seguir o plano de tratamento proposto, ambas as partes devem buscar uma comunicação amigável para encontrar uma solução alternativa. Caso se recuse a comunicar ou não possamos contactá-lo devidamente, procederemos ao tratamento do assunto de acordo com a cláusula [Sobre anomalias] passados 3 dias úteis a partir da confirmação de que não conseguimos estabelecer contacto com você.</p>
<p>5.5 A cláusula [Sobre exceções] se aplica quando surgem disputas com a equipe de operações. No caso de você registrar uma reclamação, a equipe de operações não será responsável por fornecer informações sobre a reclamação sem infringir sua propriedade e reputação, exceto no caso de reembolso total. Se você obteve recompensas, ativos ou outros benefícios devido a erros do sistema ou outros motivos explicados pela PixiBox, tais ativos não estão protegidos e a PixiBox não será responsável por eles, exceto no caso de reembolso total. Se a equipe de operações cometer uma falha ou um erro que resulte em danos à sua propriedade ou reputação, você tem o direito de solicitar uma indenização à PixiBox. O valor da compensação não poderá ultrapassar 120% do valor da recarga feita por você. A PixiBox não tem obrigação de compensar além da perda real do usuário.</p>
<p>6. Violação do contrato pelo usuário e sua gestão</p>
<p>6.1 Se ocorrer uma das seguintes situações, será considerado que você violou o contrato:</p>
<p>(1) Violação das disposições das leis e regulamentos relevantes ao usar os serviços PixiBox.</p>
<p>(2) Violação deste Contrato ou do Contrato Complementar a este Contrato (ou seja, Seção 2.2 deste Contrato) acordado. A fim de atender às necessidades de um grande número de usuários por serviços eficientes e de alta qualidade, você entende e concorda que a PixiBox pode estipular os procedimentos e padrões para quebra de contrato nas regras da PixiBox. Por exemplo, a PixiBox pode determinar se isso constitui uma quebra de contrato com base na relação entre seus Dados do Usuário e os Dados do Usuário em Massa; tem a obrigação de fornecer provas suficientes e explicações razoáveis para anomalias nos seus dados, sob pena de ser considerada uma quebra de contrato.</p>
<p>6.2 Medidas para lidar com a quebra de contrato: "Processamento de Informação": Se a informação que publicar na PixiBox constituir uma quebra de contrato, a PixiBox pode eliminar ou bloquear imediatamente a informação relevante ou retirar os seus produtos das prateleiras de acordo com as regras correspondentes eles "Restrições de comportamento": Se as suas ações na PixiBox, ou ações que não foram implementadas na PixiBox mas têm impacto na PixiBox e nos seus utilizadores, constituem uma quebra de contrato, a PixiBox pode aplicar medidas como dedução de pontos, restrição de direitos de utilização funções, a suspensão da prestação de alguns ou todos os serviços para você, etc. Se o comportamento do cliente constituir uma violação fundamental do contrato, a PixiBox poderá bloquear sua conta e interromper o fornecimento de serviços a você. "Processamento de Conta de Pagamento PixiBox": Quando você quebra o contrato e existem determinadas circunstâncias, como fraude, venda de produtos falsificados, sequestro de contas de outras pessoas ou se houver risco de comprometer a segurança das transações de outras pessoas, a equipe de operação determinará o grau de risco do seu comportamento e tomará medidas de execução para a conta, como o cancelamento da cobrança de pagamentos e a exclusão dos pagamentos de fundos. "Publicação dos resultados do processamento": a PixiBox pode tornar públicas na PixiBox as informações sobre as medidas de quebra de contrato acima mencionadas e outras informações ilegais confirmadas por documentos legais eficazes de órgãos administrativos ou judiciais nacionais.</p>
<p>6.3 Responsabilidade por Indenização: Se suas ações causarem perdas à PixiBox e/ou suas afiliadas (incluindo perdas econômicas diretas, perda de fundo de comércio e perdas econômicas indiretas, como compensação, liquidação, honorários advocatícios e despesas legais pagas a terceiros), o o cliente deve indenizar a PixiBox e/ou suas afiliadas por todas as perdas mencionadas acima. Se suas ações fizerem com que a PixiBox e/ou suas afiliadas sejam objeto de uma reclamação de terceiros, a PixiBox e/ou suas afiliadas podem recuperar todas as perdas de você após assumir o pagamento monetário e outras obrigações para com o terceiro.</p>
<p>6.4 Acordo Especial: "Processamento Associado": Se você rescindir este acordo devido a uma violação grave do contrato, a fim de manter a ordem e proteger os direitos do consumidor, a PixiBox e/ou suas afiliadas podem não cooperar com você sob outros acordos e tomar medidas para suspender ou mesmo rescindir o acordo, e você será notificado de acordo com as disposições do Artigo 8 deste acordo. Se os outros acordos assinados pela PixiBox e você, e os acordos assinados pela PixiBox Mall e/ou suas afiliadas com você, indicarem claramente o status de sua cooperação sob esta página de acordo, a PixiBox ficará fora de manutenção. A fim de manter a ordem e proteger os direitos do consumidor, o contrato poderá ser suspenso ou mesmo rescindido mediante o recebimento de uma instrução, e você será notificado de acordo com o disposto no Artigo 7 deste contrato.</p>
<p>7. Alterações no contrato</p>
<p>A PixiBox reserva-se o direito de fazer alterações neste Contrato e no Contrato Complementar de tempos em tempos, de acordo com as alterações nas leis e regulamentos nacionais, a fim de manter a ordem das transações e proteger os direitos e interesses dos consumidores. Você será notificado de tais alterações conforme fornecidas, para que esteja ciente das alterações e possa tomar as medidas necessárias. É sua responsabilidade revisar regularmente os termos e condições atualizados e entender completamente seu conteúdo.</p>
<p>Caso o cliente não concorde com as alterações, ele tem o direito de entrar em contato com a PixiBox para dar sua opinião antes da data efetiva das alterações. Se a sua opinião for aceita, a PixiBox ajustará as alterações de acordo. Se você ainda não concordar com as alterações que entraram em vigor, você deve parar de usar o serviço PixiBox a partir da data em que as alterações forem consideradas efetivas, e as alterações não terão efeito sobre você. Se você continuar a usar o serviço PixiBox depois que as alterações entrarem em vigor, será considerado que você concordou com as alterações que entraram em vigor.</p>
<p>8. Notícias</p>
<p>8.1 Notificações</p>
<p>A PixiBox enviará notificações usando os métodos de contato mencionados acima, como anúncios na PixiBox, mensagens de texto SMS para o número de contato que você forneceu e e-mails para o endereço de e-mail registrado. E-mails, mensagens de texto, mensagens do sistema e mensagens intra-plataforma enviadas para sua conta serão consideradas entregues assim que forem enviadas com sucesso.</p>
<p>Em caso de disputas relacionadas a atividades comerciais na PixiBox, o cliente concorda que as autoridades judiciais (incluindo, entre outras, tribunais) podem enviar documentos legais a ele por meio de métodos de comunicação modernos, como mensagens de texto, e-mails, ferramentas de mensagens instantâneas ou por correio (incluindo, mas não limitado a, documentos judiciais). O número de telemóvel, endereço de e-mail ou conta PixiBox que forneceu para receber documentos legais será considerado o meio de entrega válido. O endereço postal que você fornecer será seu endereço de contato legal ou um endereço válido que você forneceu.</p>
<p>Você concorda que a aplicação da lei pode usar um ou mais dos métodos de entrega acima para lhe enviar documentos legais. Além disso, esses métodos de entrega serão aplicados em cada etapa do processo judicial, como a apresentação de reclamações, incluindo primeira instância, segunda instância, revisão, execução e procedimentos de supervisão.</p>
<p>É da responsabilidade do cliente garantir que os dados de contacto fornecidos são precisos, válidos e atualizados em tempo real. Se suas informações de contato estiverem incorretas ou você não notificar as alterações a tempo, poderá ser responsabilizado por consequências legais resultantes da não entrega ou entrega tardia de documentos legais.</p>
<p>9. Antidependência e proteção de menores</p>
<p>9.1 A participação excessiva em atividades de entretenimento pagas afetará adversamente sua saúde física e mental, bem como sua vida normal. É recomendável que você controle adequadamente o tempo que usa o serviço todos os dias e consuma moderadamente dentro de sua capacidade financeira razoável.</p>
<p>9.2 Se for o tutor de um utilizador menor de idade, por favor cumpra a obrigação de tutela e evite que os menores de idade se entreguem ao vício, consumo excessivo, ingestão acidental de peças e outros comportamentos prejudiciais à sua saúde física e mental. Forneça informações de identificação práticas e válidas para provar que você é de fato menor de idade e negocie razoavelmente com a equipe de operações. Esta plataforma não é obrigada a reembolsar a recarga feita por menores que já tenham sido enviados (afetando vendas secundárias) e cujo tempo seja superior a 7 dias.</p>
<p>10. Rescisão do contrato</p>
<p>10.1 Circunstâncias de Rescisão</p>
<p>A "Rescisão Iniciada pelo Usuário" tem o direito de rescindir este Contrato das seguintes formas:</p>
<p>(1) Antes de as alterações entrarem em vigor, você deixa de usar o serviço e expressamente não deseja aceitar as alterações;</p>
<p>(2) Você expressamente não deseja continuar usando o serviço PixiBox e concorda com os termos de rescisão do serviço.</p>
<p>No caso de "rescisão iniciada pela PixiBox", a PixiBox poderá notificá-lo sobre a rescisão deste contrato de acordo com os métodos de notificação estabelecidos no Artigo 8 deste contrato:</p>
<p>(1) Se você violar este contrato, a PixiBox rescindirá este contrato de acordo com a cláusula de violação do contrato;</p>
<p>(2) Se você roubar contas de outras pessoas, publicar informações proibidas, enganar outras pessoas, vender produtos falsificados, perturbar a ordem do mercado ou usar meios impróprios para obter lucro, a PixiBox selará sua conta de acordo com as regras da PixiBox;</p>
<p>(3) Além das circunstâncias acima, se você violou repetidamente as disposições relevantes das regras da PixiBox e as circunstâncias são graves, a PixiBox tomará medidas para confiscar sua conta de acordo com as regras da PixiBox;</p>
<p>(4) Sua conta é recuperada pela PixiBox de acordo com este contrato;</p>
<p>(5) Você está associado a produtos na PixiBox, postando ou vendendo produtos falsificados e de má qualidade/violação, violando os direitos e interesses legítimos de terceiros ou outras violações graves da lei;</p>
<p>(6) Outras circunstâncias em que o serviço deve ser encerrado.</p>
<p>10.2 Procedimentos após a rescisão do contrato</p>
<p>"Divulgação de informações do usuário" Após a rescisão deste contrato, a menos que expressamente exigido por lei, a PixiBox não tem obrigação de divulgar nenhuma informação de sua conta para você ou terceiros designados.</p>
<p>Após a rescisão deste contrato, a equipe de operações mantém os seguintes direitos:</p>
<p>(1) Continuar a armazenar todas as informações mencionadas no Artigo 5 deste contrato que você guardou na PixiBox.</p>
<p>(2) Em caso de violação de contrato por você no passado, a PixiBox pode responsabilizá-lo por tal violação de acordo com as disposições deste contrato.</p>
<p>"Processamento da Transação" Ao término deste contrato, em relação às solicitações de transação geradas durante a vigência deste contrato, a PixiBox poderá notificar a contraparte da transação e decidir se encerra a solicitação de transação de acordo com a vontade da contraparte; Se a contraparte solicitar a continuação da transação, você deverá continuar cumprindo este contrato e as disposições estabelecidas na solicitação de transação correspondente, e assumirá qualquer perda ou custo adicional derivado dela.</p>
<p>11. Lei Aplicável, Jurisdição e Outros</p>
<p>"Lei aplicável"</p>
<p>  A validade, entrada em vigor, interpretação, modificação, complementação, rescisão e resolução de disputas relacionadas a este Contrato serão regidas pelas leis da República Popular da China em seu território continental. Caso não haja disposições relevantes na lei, as práticas comerciais e/ou práticas da indústria serão seguidas.</p>
<p>"Divisibilidade"</p>
<p>Se qualquer disposição deste Contrato for considerada inválida, nula ou inexequível, essa disposição será considerada separável e não afetará a validade e aplicabilidade das demais disposições deste Contrato.</p>

`;

export const ruleContentEN = `
<p>What is PixiBox?</p>
<p>PixiBox is an exciting and popular mystery box product that captivates young people. Our platform is available 24/7, allowing players to open their boxes anytime, anywhere. Through PixiBox, you can get a wide variety of high-value products at an incredibly low cost by opening the boxes. From cutting-edge electronics to exclusive collectible figures and toys, our mystery boxes contain popular and surprising products at every opening. Besides, we also offer a wide variety of exciting and fun games.</p>
<br/>
<p>We have classified our mystery boxes into different categories to suit your preferences. Every time you open a box, you will receive a random product from that category. You can see all the boxes you have purchased in the "My box" section and select the delivery of open products directly to your home. The status of the shipment of the products can be checked in the "My order" section. If you are not satisfied with a particular item, you also have the option to redeem it on our platform and the corresponding credits will be refunded to your account.</p>
`;

export const ruleContentESMX = `
<p>¿Que es PixiBox?!</p>
<p>PixiBox es un emocionante y popular producto de cajas misteriosas que cautiva a los jóvenes. Nuestra plataforma está disponible las 24 horas, lo que permite a los jugadores abrir sus cajas en cualquier momento y lugar. A través de PixiBox, puedes obtener una amplia variedad de productos de alto valor a un costo increíblemente bajo al abrir las cajas. Desde dispositivos electrónicos de última generación hasta figuras coleccionables y juguetes exclusivos, nuestras cajas misteriosas contienen productos populares y sorprendentes en cada apertura. También ofrecemos una variedad de juegos emocionantes y divertidos.</p>
<br/>
<p>Hemos clasificado nuestras cajas misteriosas en diferentes categorías para adaptarnos a tus preferencias. Cada vez que abres una caja, recibirás un producto aleatorio de esa categoría. Puedes ver todas las cajas que has adquirido en la sección "Mis cajas" y seleccionar el envío de los productos abiertos directamente a tu hogar. El estado del envío de los productos se puede verificar en la sección "Mis pedidos". Si no estás satisfecho con un artículo en particular, también tienes la opción de canjearlo en nuestra plataforma y los créditos correspondientes se reembolsarán en tu cuenta.</p>
`

export const ruleContentZHCN = `
<p>什么是 PixiBox？</p>
<p>PixiBox 是一款令人兴奋且年轻人都喜欢的潮流盲盒产品，平台24小时在线，玩家可随时随地拆盒，您可以在其中以令人难以置信的低成本通过打开盒子得到各种各样且高价值的商品，超人气热门手机数码、手办、限定玩具，稀有隐藏款盲盒在线惊喜拆箱，游戏玩法多样，趣味无穷。</p>
<br/>
<p>我们产品对多种类型的在线神秘盒子进行了分类。 每次你打开一个盒子，你将随机会得到其中一件商品。您可以在“我的盒子”中查看你已经购买的盒子，并且选择将开出的商品送到您家，发货商品的物流状态可在“我的订单”中查询。如果玩家不满意抽中的物品，您也可以选择直接在我们平台将该商品兑换，换取的相应金币会退回到你的账户内。</p>
`

export const ruleContentPTBR = `
<p>O que é PixiBox?!</p>
<p>PixiBox é um produto de caixa misteriosa emocionante e popular que cativa os jovens. Nossa plataforma está disponível 24 horas por dia, 7 dias por semana, permitindo que os jogadores abram suas caixas a qualquer hora e em qualquer lugar. Através da PixiBox, você pode obter uma grande variedade de produtos de alto valor a um custo incrivelmente baixo de abertura das caixas. De eletrônicos de ponta a figuras e brinquedos colecionáveis exclusivos, nossas caixas misteriosas contêm produtos populares e surpreendentes a cada abertura. Nós também oferecemos uma variedade de jogos emocionantes e divertidos.</p>
<br/>
<p>Classificamos nossas caixas misteriosas em diferentes categorias para atender às suas preferências. Cada vez que você abrir uma caixa, receberá um produto aleatório dessa categoria. Pode ver todas as caixas que adquiriu na secção "A minha caixa" e selecionar a entrega de produtos abertos diretamente em sua casa. O status do envio dos produtos pode ser verificado na seção "Meu pedido". Se não estiver satisfeito com um determinado artigo, também tem a opção de recuperá-lo na nossa plataforma e as moedas correspondentes serão devolvidos à sua conta.</p>
`