import React, { useState } from "react";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { useNavigate } from "react-router-dom";
import VerifyCodeInput from "@/bizComponents/VerifyCodeInput";
import { useTranslation } from "react-i18next";
import AgreementModal from "@/bizComponents/AgreementModal";
import { Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import { codeType, getCode } from "@/service/common";
import { login } from "@/service/login";
import { setPassword } from "@/service/user";
import './index.scss';
import { ACCESS_TOKEN, emailPattern, passwordPattern, phonePattern } from "@/interfaces";

enum Step {
  code,
  register
}

enum InputMode {
  password = "password",
  code = 'code'
}

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [accountNumber, setAccountNumber] = useState(undefined);
  const [password, setAccountPwd] = useState(undefined);
  const [setupPassword, setSetupPassword] = useState(undefined);
  const [code, setVerifyCode] = useState(undefined);
  const [inputMode, setInputMode] = useState<InputMode>(InputMode.code);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [step, setStep] = useState<Step>(Step.code);

  const getCodeHook = useRequest(getCode, {manual: true});
  const loginHook = useRequest(login, {manual: true});
  const setPasswordHook = useRequest(setPassword, {manual: true});

  const pwdMode = inputMode === InputMode.password;
  const loginDisabled = pwdMode ? !password : !code;
  const codeType = accountNumber?.includes('@') ? 'email' : 'mobile';

  const handleSwitchInputMode = () => {
    setInputMode(inputMode === InputMode.password ? InputMode.code : InputMode.password)
  }

  const handleSetPassword = () => {
    if (!passwordPattern.test(setupPassword as string)) {
      Toast.show({
        content: t('bb.password-format-wrong'),
        duration: 2000
      });
      return;
    }
    setPasswordHook.runAsync({
      password: setupPassword
    }).then(res => {
      if (res?.code === 0) {
        Toast.show({
          content: t('bb.set-successfully'),
          duration: 2000,
          afterClose: () => navigate('/')
        })
      }
    })
  }

  const handleConfirm = () => {
    switch (step) {
      case Step.code:
        handleLogin();
        return;
      case Step.register:
        handleSetPassword();
        return;
    }
  }

  const onAgreeChange = () => {
    setAgreeChecked(!agreeChecked);
  }

  const renderTips = () => {
    switch (step) {
      case Step.code:
        if (pwdMode) {
          return t('bb.please-input-password');
        } else {
          return t('bb.please-input-verification-code');
        }
      case Step.register:
        return t('bb.set-password');
    }
  }

  const checkInputValue = (value) => {
    return new Promise((resolve, reject) => {
      if (value.includes('@')) {
        if (!emailPattern.test(value)) {
          reject(t('bb.input-format-wrong'))
        }
      } else {
        if (!phonePattern.test(value)) {
          reject(t('bb.input-format-wrong'))
        }
      }
      resolve(null);
    })
  }

  const onSendCode = (cb) => {
   checkInputValue(accountNumber)
     .then(() => {
       const type: codeType = accountNumber.includes('@') ? 'email' : 'sms';
       const payload = {
         [codeType]: accountNumber,
         type
       }
       getCodeHook.runAsync(payload).then(res => {
         if (res?.code === 0) {
           cb();
           Toast.show({
             content: t('bb.send-successfully'),
             duration: 2000
           })
         }
       })
     }).catch(e => {
     Toast.show({
       content: e,
       duration: 2000
     })
   })
  }

  const handleLogin = () => {
    const payload: any = {
      [codeType]: accountNumber,
      password,
      code,
      loginType: inputMode,
      codeType,
    }
    if (!agreeChecked) {
      Toast.show({
        content: t('bb.please-check-agreement'),
        position: 'bottom',
        duration: 3000
      })
      return;
    }
    loginHook.runAsync(payload).then(res => {
      if (res?.data) {
        localStorage.setItem(ACCESS_TOKEN, res.data.accessToken);
        if (res.data.pwdConfig) {
          // 展示设置密码框
          setStep(Step.register);
          return;
        }
        navigate('/')
      }
    })
  }

  const onSkip = () => navigate('/');

  const renderBtn = () => {
    const btnText = () => {
      switch (step) {
        case Step.code:
          return t('bb.login');
        case Step.register:
          return t('bb.continue');
      }
    }

    const btnDisabled = () => {
      switch (step) {
        case Step.code:
          return loginDisabled;
        case Step.register:
          return !setupPassword;
      }
    }
    const loading = () => {
      switch (step) {
        case Step.code:
          return loginHook.loading;
        case Step.register:
          return false;
      }
    }
    return (
      <>
        <div className="register-btn-group">
          {
            step === Step.register &&
            <Button fill="outline" onClick={onSkip} className="skip-btn">{t('bb.skip')}</Button>
          }
          <Button
            className={step === Step.code ? 'login-btn' : ''}
            wrapperPadding={step === Step.register ? 0 : '0'}
            disabled={btnDisabled()}
            onClick={handleConfirm}
            size={step === Step.register ? 'middle' : 'large'}
            loading={loading()}
          >
            {btnText()}
          </Button>
        </div>
        {
          step === Step.code && <AgreementModal onChecked={onAgreeChange} />
        }
      </>
    )
  }

  return (
    <div className="login-wrapper">
      <div className="title">
        <p className="start-login">{t('bb.start-login')}</p>
        <p className="tips">{renderTips()}</p>
      </div>
      <Input
        placeholder={t('bb.email-or-phone-number-ten-digits')}
        value={accountNumber as string}
        onChange={(v) => setAccountNumber(v)}
        clearable
        readOnly={step === Step.register}
      />
      {
        step === Step.code &&
        [
          <Input
            key="password"
            style={{display: !pwdMode ? 'none' : 'flex'}}
            placeholder={t('bb.please-input-password')}
            type="password"
            value={password as string}
            onChange={(v) => setAccountPwd(v)}
            clearable
          />,
          <VerifyCodeInput
            key="code"
            style={{display: pwdMode ? 'none' : 'flex'}}
            btnDisabled={!accountNumber}
            placeholder={t('bb.please-input-verification-code')}
            value={code as string}
            onChange={(v) => setVerifyCode(v)}
            clearable
            onSend={onSendCode}
          />
        ]
      }
      {
        step === Step.register &&
        <Input
          placeholder={t('bb.set-password-six-digits')}
          type="password"
          value={setupPassword as string}
          onChange={setSetupPassword}
          clearable
        />
      }
      {
        step === Step.code &&
        <p 
          className="switch-input-mode"
          onClick={handleSwitchInputMode}
        >
          {pwdMode ? t('bb.verification-code-input') : t('bb.password-input')}
        </p> 
      }
      <div className="btn-wrapper">
        {renderBtn()}
      </div>
    </div>
  )

}

export default Login;