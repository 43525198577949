import { agreementContentZHCN, privacyContentZHCN, ruleContentZHCN } from "@/locales/largeContent";

export default {
	translation: {
		"bb.start-login": "开始登入",
		"bb.please-input-email-or-phone-number": "请输入Email或者手机号码",
		"bb.email-or-phone-number-ten-digits": "Email/手机号（十位数字）",
		"bb.next-step": "下一步",
		"bb.please-input-verification-code": "请输入验证码",
		"bb.please-input-password": "请输入密码",
		"bb.get-verification-code": "获取验证码",
		"bb.resend": "重新发送",
		"bb.verification-code-input": "验证码输入",
		"bb.password-input": "密码输入",
		"bb.agree-agreement-and-privacy": "我已阅读并同意<0>{{agreement}}</0><1>{{privacy}}</1>",
		"bb.agree": "我已阅读并同意",
		"bb.agreement": "《服务条款》",
		"bb.agreement-title": "服务条款",
		"bb.privacy": "《隐私协议》",
		"bb.privacy-title": "隐私协议",
		"bb.set-password": "设置密码",
		"bb.set-password-six-digits": "设置6位数字密码",
		"bb.continue": "继续",
		"bb.skip": "跳过",
		"bb.box": "盒子",
		"bb.mine": "我的",
		"bb.all-boxes": "所有盒子",
		"bb.top-up": "充值",
		"bb.rules-of-the-game": "游戏规则",
		"bb.recommendation": "推荐",
		"bb.box-detail": "盒子详情",
		"bb.buy": "购买",
		"bb.try-it-for-free": "免费试玩",
		"bb.items-in-the-box": "盒内商品",
		"bb.open-box-tip": "提示：当你开盒后，你会获得以下商品中的其中一个商品。",
		"bb.confirm-pay": "确认支付",
		"bb.box-price": "盒子价格",
		"bb.the-order-closes-in-time": "{{time}}分后订单关闭",
		"bb.freight": "运费",
		"bb.pay": "支付",
		"bb.pay-success": "支付成功",
		"bb.open-case": "开盒",
		"bb.open-it-later": "稍后再开",
		"bb.open-it-now": "立即开箱",
		"bb.if-later-open-in-my-box": "选择稍后再开，可前往\"我的盒子\"里进行开箱",
		"bb.deliver": "发货",
		"bb.recycle-some-money": "回收（<0>{{amount}}</0>）",
		"bb.one-more-time": "再来一次",
		"bb.value-of-item": "商品价值",
		"bb.confirm-order": "确认订单",
		"bb.pay-now": "立即支付",
		"bb.recycle-some-items": "回收{{number}}件商品",
		"bb.recycle-price": "回收金额",
		"bb.confirm-recycle": "确认回收",
		"bb.my-orders": "我的订单",
		"bb.my-boxes": "我的盒子",
		"bb.address": "收货地址",
		"bb.about-us": "关于我们",
		"bb.contact-customer-service": "联系客服",
		"bb.faq": "常见问题",
		"bb.customer-agreement": "客户协议",
		"bb.customer-service-feedback": "客服反馈",
		"bb.setting": "设置",
		"bb.transactions": "交易记录",
		"bb.pending-pay": "待付款",
		"bb.done": "已完成",
		"bb.closed": "已关闭",
		"bb.the-order-closes-in-minutes": "{{time}}分后订单关闭",
		"bb.cancel-order": "取消订单",
		"bb.payment-now": "立即付款",
		"bb.close": "关闭",
		"bb.transaction-done": "交易完成",
		"bb.pending-open-box": "待开箱",
		"bb.pending-pick-up": "待提货",
		"bb.recycled": "已回收",
		"bb.pending-deliver": "待发货",
		"bb.delivered": "已发货",
		"bb.order-time": "下单时间",
		"bb.recycle": "回收",
		"bb.open-box": "开箱",
		"bb.price": "价格",
		"bb.exchange-amount": "兑换金额",
		"bb.your-items-prepare-deliver": "你的商品正在准备发货中...",
		"bb.your-items-delivered": "你的商品已经发货，请耐心等待...",
		"bb.your-items-signed": "你的商品已被签收...",
		"bb.box-number": "盒子编号",
		"bb.withdraw": "提现",
		"bb.box-buy": "盲盒购买",
		"bb.top-up-amount": "充值金额",
		"bb.please-input-top-up-amount": "请输入你要充值的金额",
		"bb.contact-us": "联系我们",
		"bb.email": "邮箱",
		"bb.email-require": "*邮箱",
		"bb.work-time": "工作时间",
		"bb.problem-feedback": "问题反馈",
		"bb.please-input-suggestion-or-problem": "请填写建议或者问题",
		"bb.upload-screenshot": "上传截图",
		"bb.your-email-or-phone": "你的邮箱/手机号",
		"bb.submit": "提交",
		"bb.add-address": "添加收货地址",
		"bb.confirm": "确认",
		"bb.add-address-2": "增加收货地址",
		"bb.modify-address": "修改收货地址",
		"bb.full-name-require": "*姓名（完整名字）",
		"bb.phone-number-require": "*电话号码",
		"bb.phone-number": "电话号码",
		"bb.full-address-require": "*详细地址",
		"bb.postal-code": "邮编",
		"bb.version": "版本",
		"bb.logout": "退出登入",
		"bb.withdraw-record": "提现记录",
		"bb.withdraw-amount-available": "当前可提现金额",
		"bb.withdraw-amount": "提现金额",
		"bb.your-phone-number": "你的手机号码",
		"bb.your-email": "你的邮箱",
		"bb.apply-withdraw": "申请提现",
		"bb.withdraw-all-amount": "全部提现",
		"bb.withdraw-result": "提现结果",
		"bb.withdraw-apply-success": "提现申请成功，等待客服联系处理",
		"bb.apply-time": "申请时间",
		"bb.applied": "已申请",
		"bb.processing": "处理中",
		"bb.withdraw-success": "提现成功",
		"bb.withdraw-failed": "提现失败",
		"bb.process-time": "处理时间",
		"bb.home": "首页",
		"bb.address-detail": "收货地址详情",
		"bb.order-detail": "订单详情",
		"bb.login": "登录",
		"bb.phone-or-email": "手机号/邮箱",
		"bb.consignee": "收货人",
		"bb.order-number": "订单编号",
		"bb.selected-delivery": "已选定发货",
		"bb.signed": "已签收",
		"bb.set-default-address": "设置为默认地址",
		"bb.system-busy": "系统繁忙",
		"bb.system-error": "系统错误",
		"bb.balance-insufficient": "可用余额不足",
		"bb.login-expired": "登入已过期，请重新登入",
		"bb.user-not-exist": "用户不存在",
		"bb.password-error": "登录密码错误",
		"bb.code-expired": "验证码已过期",
		"bb.email-send-failed": "邮件发送失败，请重试",
		"bb.sms-send-failed": "短信发送失败，请重试",
		"bb.select-country-title": "请选择你的国家及语言",
		"bb.privacy-content": privacyContentZHCN,
		"bb.agreement-content": agreementContentZHCN,
		"bb.change-lang": "切换语言",
		"bb.cancel-order-alert-title": "是否确认取消该笔订单？",
		"bb.rule-content": ruleContentZHCN,
		"bb.input-amount-wrong": "输入金额有误",
		"bb.input-format-wrong": "格式错误",
		"bb.please-check-agreement": "请先勾选同意协议",
		"bb.set-successfully": "设置成功",
		"bb.add-successfully": "添加成功",
		"bb.deleted-successfully": "删除成功",
		"bb.update-successfully": "更新成功",
		"bb.payment-successfully": "支付成功",
		"bb.recover-successfully": "回收成功",
		"bb.cancel-successfully": "取消成功",
		"bb.send-successfully": "发送成功",
		"bb.the-picture-is-too-large": "图片超大",
		"bb.feedback-successfully": "反馈成功",
		"bb.password-format-wrong": "密码格式有误",
		"bb.request-is-timeout": "请求超时",
		"bb.yes": "是",
		"bb.no": "否",
		"bb.number-of-products": "个商品",
		"bb.pay-with": "使用{{type}}付款",
		"bb.minimum-recharge": `最低充值$`
	}
}