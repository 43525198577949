import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './index.scss';
import { useContactStore, ContactState } from '@/store';

const Contact = () => {
  const { t } = useTranslation();
  const fetch = useContactStore((state: ContactState) => state.fetch);
  const contactInfo = useContactStore((state: ContactState) => state.info);

  useEffect(() => {
    if (!contactInfo.email) {
      fetch();
    }
  }, [contactInfo.email])

  return (
    <div className="contact-wrapper">
      <p className="contact-us">{t('bb.contact-us')}</p>
      <div className="contact-content">
        <div className="item-wrapper" key="whats-app">
          <p className="title">WhatsApp</p>
          <a className="value" href={contactInfo.whatsApp_link}>{contactInfo.whatsApp}</a>
        </div>
        <div className="item-wrapper" key="email">
          <p className="title">{t("bb.email")}</p>
          <a className="value" href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
        </div>
        <div className="item-wrapper" key="work-time">
          <p className="title">{t("bb.work-time")}</p>
          <p className="value">{contactInfo.timeRange}</p>
        </div>
      </div>
    </div>
  )
}

export default Contact;