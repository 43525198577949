import React, { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Input from "@/components/Input";
import TextArea from "@/components/TextArea";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import { addOrUpdateAddress, getAddressInfo } from "@/service/address";
import { Toast, Checkbox } from "antd-mobile";
import { useSetState } from "ahooks";
import { CHECKED_ADDRESS_ITEM_KEY, DEFAULT_ADDRESS, NON_DEFAULT_ADDRESS } from "@/interfaces";
import { encrypt, decrypt } from "@/utils/util";
import './index.scss';

interface InputProps {
  key: string
  placeholder: string
  onChange: (arg: any) => void
  value: string | number
  component: any
  componentProps?: any
}

const AddressInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useSetState({
    receiveName: "",
    receivePostcode: "",
    receiveAddress: "",
    receiveMobile: "",
    receiveEmail: "",
    isDefault: NON_DEFAULT_ADDRESS,
  });

  const inputItems: InputProps[] = [
    {
      key: 'name',
      placeholder: 'bb.full-name-require',
      onChange: (v: string) => handleChange('receiveName', v),
      value: state.receiveName,
      component: Input
    },
    {
      key: 'email',
      placeholder: 'bb.email-require',
      onChange: (v: string) => handleChange('receiveEmail', v),
      value: state.receiveEmail,
      component: Input
    },
    {
      key: 'phone',
      placeholder: 'bb.phone-number-require',
      onChange: (v: string) => handleChange('receiveMobile', v),
      value: state.receiveMobile,
      component: Input
    },
    {
      key: 'address',
      placeholder: 'bb.full-address-require',
      onChange: (v: string) => handleChange('receiveAddress', v),
      value: state.receiveAddress,
      component: TextArea,
      componentProps: {
        autoSize: true,
        rows: 1
      }
    },
    {
      key: 'postalCode',
      placeholder: 'bb.postal-code',
      onChange: (v: string) => handleChange('receivePostcode', v),
      value: state.receivePostcode,
      component: Input
    },
  ];

  const handleChange = (key: string, value: string) => {
    setState({
      ...state,
      [key]: value
    })
  }

  const isNotEmpty = v => !!v;

  const checkRequiredValue = (value) => {
    for(let i in value) {
      if (!isNotEmpty(value[i]) && i !== 'receivePostcode') {
        return true
      }
    }
    return false;
  }

  const onConfirm = () => {
    addOrUpdateAddress(state).then(res => {
      if (res?.code === 0) {
        if (id) {
          const sessionItem = decrypt(sessionStorage.getItem(CHECKED_ADDRESS_ITEM_KEY), {});
          if (+id === +sessionItem.id) {
            const data = {
              ...state,
              id: +id,
            }
            sessionStorage.setItem(CHECKED_ADDRESS_ITEM_KEY, encrypt(data));
          }
        }
        Toast.show({
          content: id ? t('bb.update-successfully') : t('bb.add-successfully'),
          duration: 1000,
          afterClose: () => {
            navigate(-1)
          }
        })
      }
    })
  }

  const getDetail = async () => {
    const res = await getAddressInfo(id);
    const { receiveName, receivePostcode, receiveAddress, receiveMobile, receiveEmail, isDefault } = res?.data || {};
    const values = {
      receiveName,
      receivePostcode,
      receiveAddress,
      receiveMobile,
      receiveEmail,
      id,
      isDefault,
    }
    setState(values)
  }

  const onSetDefault = () => {
    setState({
      isDefault: state.isDefault === DEFAULT_ADDRESS ? NON_DEFAULT_ADDRESS : DEFAULT_ADDRESS,
    })
  }

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id])

  return (
    <div className="address-info-wrapper">
      {
        inputItems.map((item: InputProps) => (
          <item.component
            placeholder={t(item.placeholder)}
            key={item.key}
            className="address-input"
            value={item.value}
            onChange={item.onChange}
            {...item.componentProps}
          />
        ))
      }
      {
        id &&
        <Checkbox
          className="set-default-address"
          checked={state.isDefault === DEFAULT_ADDRESS}
          onChange={onSetDefault}
        >
          {t('bb.set-default-address')}
        </Checkbox>
      }
      <Button
        fixed
        wrapperPadding={"0 24px"}
        onClick={onConfirm}
        size="large"
        disabled={checkRequiredValue(state)}
      >
        {t('bb.confirm')}
      </Button>
    </div>
  )

}

export default AddressInfo;