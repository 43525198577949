import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

const p = '/api/account/';

// 账户提现
export const applyWithdraw = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'withdrawal/apply',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 获取交易记录
export const getTransactions = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'consume/queryPage',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 获取账户余额
export const getBalance = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryInfo',
		method: 'post',
		headers: {
			'access-token': true,
			hideToast: true
		}
	}))
}

// 获取提现记录
export const getWithdrawList = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'withdrawal/queryPage',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 账户充值
export const rechargeApply = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'recharge/apply',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}