import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

const p = '/api/address/';

// 新增/修改 地址
export const addOrUpdateAddress = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'addOrUpdateInfo',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 删除 地址
export const deleteAddress = (id: string | number): Promise<any> => {
	return tryCatch(request({
		url: p + 'del',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			id
		}
	}))
}

// 获取地址列表
export const getAddressList = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryPage',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 获取地址详情
export const getAddressInfo = (id: string | number): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryInfo',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			id
		}
	}))
}
// 获取默认地址
export const getDefaultAddress = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryDefault',
		method: 'post',
		headers: {
			'access-token': true
		}
	}))
}