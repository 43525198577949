import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

const p = '/api/problem/';

// 问题提交
export const submitFeedback = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'submit',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}