import React from "react";
import { CenterPopup as AdmCenterPopup, CenterPopupProps } from "antd-mobile";
import cn from 'classnames';
import "./index.scss";

const Modal = (props: CenterPopupProps) => {
  const { className, children, onClose, ...others } = props;

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <AdmCenterPopup
      className={cn("bb-modal", className)}
      bodyClassName="bb-modal-body"
      maskClassName="bb-modal-mask"
      {...others}
    >
      <div className="bb-modal-content">{children}</div>
      {
        onClose &&
        <div className="close-btn" onClick={handleClose}>×</div>
      }
      <div className="blur-block">
        <div className="left" />
        <div className="center" />
        <div className="right" />
      </div>
    </AdmCenterPopup>
  )

}

export default Modal;
