export const addComma = (str: string) => {
	return str.replace(/(?=\B(\d{3})+$)/g, ',');
}

export const removeRepeat = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	const newData = [...data];
	for(let i = 0; i < newData.length; i++) {
		for(let j = i + 1; j < newData.length; j++) {
			if (newData[i].id === newData[j].id) {
				newData.splice(j, 1);
				j--;
			}
		}
	}
	return newData;
}


export const uuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = Math.random() * 16 | 0;
		const v = c === "x" ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	})
}

export const divide = (num1: number, num2: number) => {
	let r1, r2, m, n;
	try {
		r1 = num1.toString().split('.')[1].length;
	} catch {
		r1 = 0;
	}
	try {
		r2 = num2.toString().split(".")[1].length;
	} catch {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	n = (r1 >= r2) ? r1 : r2;
	return Number((Math.round(num1 * m - num2 * m) / m).toFixed(n));
}

export const getJsonData = (str: string) => {
	let res;
	try {
		res = JSON.parse(str);
	} catch {
		return false;
	}
	return res;
}

export const encrypt = (arg: any) => {
	const str = JSON.stringify(arg);
	const eStr = encodeURIComponent(str);
	return window.btoa(eStr);
}

export const decrypt = (string: string, defaultValue?: any) => {
	const _string = string ? string : encrypt(defaultValue);
	const eStr = window.atob(_string);
	const str = decodeURIComponent(eStr);
	let res: any;
	try {
		res = JSON.parse(str);
	} catch {
		res = JSON.parse(defaultValue);
	}
	return res;
}