import request from "@/utils/request";
import tryCatch from '@/utils/tryCatch';
import { BannerPositionEnum } from "@/interfaces";

const p = '/api/common/';

export type codeType = 'sms' | 'email';

export interface IGetCodeData {
	type: codeType
	mobile?: string
	email?: string
}

// 获取短信或邮箱验证码
export const getCode = async (data: IGetCodeData): Promise<any> => {
	const url = data.type === 'sms' ? p + 'getSmsCode' : p + 'getEmailCode';

	return tryCatch(request({
		url,
		method: 'post',
		data: {
			smsCodeType: 1,
			...data
		}
	}))
}

// 文件上传
export const uploadFile = (data: FormData): Promise<any> => {
	return tryCatch(request({
		url: p + 'file/upload',
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data
	}))
}

// 获取banner
export const getBanner = async (bannerPosition: BannerPositionEnum): Promise<any> => {
	return tryCatch(request({
		url: p + 'banner/queryInfo',
		method: 'post',
		data: {
			bannerPosition
		}
	}))
}