import React, { CSSProperties } from "react";
import './index.scss';
import cn from 'classnames';
import { Ellipsis, Image } from "antd-mobile";
import Coin from "@/components/Coin";
import { useTranslation } from "react-i18next";

interface IDataInfo {
  name: string
  currentAmount: string | number  // 当前价格
  originAmount: string | number // 原价
  quantity?: string | number // 数量
  productName?: string // 产品名
  pic?: string // 主图
  discount?: number | string // 折扣率
}

interface IProps {
  className?: string
  dataInfo?: IDataInfo
  onClick?: (args: any) => void
  size?: 'large' | 'normal'
  style?: CSSProperties
}

const ItemCard = (props: IProps) => {
  const { className, dataInfo, onClick, size = 'large', style } = props;
  const { discount, pic, name, currentAmount, originAmount, quantity, productName } = dataInfo;
  const { t } = useTranslation();

  const handleClick = () => {
    onClick && onClick(dataInfo);
  }

  const showLargeInfo = () => size === 'large';

  const quantityString = quantity + t('bb.number-of-products');

  return (
    <div className={cn("bb-item-card", size, className)} onClick={handleClick} style={style}>
      {
        discount && showLargeInfo() &&
        <div className="flag">
          <span className="text">{discount}% OFF</span>
        </div>
      }
      <Image src={pic} className={`image-${size}`} />
      <div className="content">
        {
          showLargeInfo() &&
          <Ellipsis className="title" content={name} />
        }
        <Coin amount={currentAmount} className={`current-price ${size}`} />
        {
          showLargeInfo() &&
          <Coin amount={originAmount} className="origin-price"/>
        }
        <Ellipsis className="quantity" content={productName ?? quantityString} />
      </div>
    </div>
  )

}

export default ItemCard;