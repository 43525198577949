import React, { useEffect } from "react";
import { Image, NoticeBar } from "antd-mobile";
import Button from "@/components/Button";
import { useTranslation } from "react-i18next";
import Loading from "@/components/Loading";
import { getBoxInfo } from "@/service/box";
import { useNavigate, useParams } from "react-router-dom";
import { useSetState } from "ahooks";
import { getTryPlayTimes } from "@/service/user";
import { calcBuyBtnAmount, itemInBoxAdapt } from "@/utils/biz";
import { createOrder } from "@/service/order";
import { ACCESS_TOKEN, ErrorCode, IState } from "@/interfaces";
import BoxFromPayToOpen from "@/bizComponents/BoxFromPayToOpen";
import Coin from "@/components/Coin";
import { useBalanceStore, BalanceState } from "@/store";
import ProductItems from "@/pages/BoxDetail/components/ProductItems";
import './index.scss';
import ItemInBox from "@/pages/BoxDetail/components/ItemInBox";

const BoxDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isLogin = localStorage.getItem(ACCESS_TOKEN);
  const balance = useBalanceStore((state: BalanceState) => state.balance);
  const fetchBalance = useBalanceStore((state: BalanceState) => state.fetch);

  const [state, setState] = useSetState<IState>({
    loading: false,
    tryTimes: undefined,
    payVisible: false,
    tryFreeVisible: false,
    payLoading: false,
    buyBoxLoading: false,
    boxInfo: {
      curBoxAmount: undefined,
      originBoxAmount: undefined,
      boxImg2: "",
      boxName: ""
    },
    productInfoList: [],
    orderNo: '',
    leftTime: 0,
  })

  const onBuyBox = async () => {
    if (!isLogin) {
      navigate('/login');
      return;
    }
    if (balance === null) {
      fetchBalance();
    }
    setState({
      buyBoxLoading: true
    })
    const res = await createOrder(id);
    if (res?.data) {
      const { data } = res;
      setState({
        payVisible: true,
        orderNo: data.orderNo,
        leftTime: data.expireTime
      })
    }
    setState({
      buyBoxLoading: false
    })
  }

  const getBoxDetailInfo = async () => {
    setState({
      loading: true
    })
    const res = await getBoxInfo({ id });
    if (res?.data) {
      const productInfoList = itemInBoxAdapt(res?.data?.productInfoList);
      setState({
        boxInfo: res?.data?.boxInfo || {},
        productInfoList
      })
    }
    setState({
      loading: false
    })
  }

  const getTryTimes = async () => {
    try {
      const res = await getTryPlayTimes(id);
      if (res) {
        setState({
          tryTimes: res?.code === 0
        })
      }
    } catch (e) {
      // 次数用完
      if (e.code === ErrorCode.PARAM_ERROR) {
        setState({
          tryTimes: false
        })
      }
    }
  }

  const onCloseOpenBox = () => {
    setState({
      tryFreeVisible: false
    })
    getTryTimes();
  }

  const onTryFree = () => {
    if (!isLogin) {
      navigate('/login');
      return;
    }
    setState({
      tryFreeVisible: true
    })
  }

  const onOneMoreTime = async () => {
    const res = await createOrder(id);
    if (res?.data) {
      const { data } = res;
      setState({
        payVisible: true,
        orderNo: data.orderNo,
        leftTime: data.expireTime
      })
    }
  }

  useEffect(() => {
    getBoxDetailInfo();
    getTryTimes();
  }, [])

  return (
    <>
      {
        state.loading ? <Loading fullscreen /> :
          <div className="detail-wrapper">
            <div className="box-container">
              <p className="title">{state.boxInfo?.mainDesc}</p>
              <div className="amount-container">
                <Coin amount={state.boxInfo?.curBoxAmount} size="large"/>
                <Coin amount={state.boxInfo?.originBoxAmount} className="origin-amount"/>
              </div>
              <Image src={state.boxInfo?.boxImg2} className="box-image"/>
              <NoticeBar
                className="tips"
                content={state.boxInfo?.labelText}
                delay={1000}
                icon={null}
              />
              <Button
                wrapperPadding={"0"}
                color="warning"
                size="large"
                onClick={onBuyBox}
                loading={state.buyBoxLoading}
              >
                {t('bb.buy')}
              </Button>
              {
                (state.tryTimes || state.tryTimes === undefined) &&
                <p className="try-free" onClick={onTryFree}>{t("bb.try-it-for-free")}</p>
              }
              <ItemInBox note={state.boxInfo?.labelBottomText} show={state.productInfoList.length > 0} />
            </div>
            <ProductItems dataSource={state.productInfoList} />
            <BoxFromPayToOpen
              boxName={state.boxInfo.boxName}
              boxImage={state.boxInfo.boxImg2}
              visible={state.payVisible}
              tryFreeVisible={state.tryFreeVisible}
              orderNo={state.orderNo}
              onOpenAgain={onOneMoreTime}
              leftTime={state.leftTime}
              boxPrice={state.boxInfo.curBoxAmount}
              differencePrice={calcBuyBtnAmount(balance, state.boxInfo.curBoxAmount)}
              onClosePayModal={() => setState({ payVisible: false })}
              onPaySuccess={() => {
                setState({ payVisible: false });
                fetchBalance();
              }}
              onCloseOpenBox={onCloseOpenBox}
              onOpenItLater={() => setState({ tryFreeVisible: false })}
            />
          </div>
      }
    </>
  )

}

export default BoxDetail;