import React from "react";
import { useNavigate } from "react-router-dom";
import './index.scss';
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <p
      className="transactions-in-nav-right"
      onClick={() => navigate('/withdraw')}
    >
      {t('bb.withdraw')}
    </p>
  )

}

export default Transactions;