import React from "react";
import { Tabs as AdmTabs, TabsProps } from "antd-mobile";
import './index.scss';

interface IProps extends TabsProps {
  dataSource: any[]
  fixed?: boolean
}

const Tabs = (props: IProps) => {
  const { dataSource, fixed, ...others } = props;

  return (
    <AdmTabs className={`bb-tabs ${fixed ? 'fixed' : ''}`} activeLineMode="fixed" {...others}>
      {
        dataSource.map(item => (
          <AdmTabs.Tab title={item.title} key={item.key} />
        ))
      }
    </AdmTabs>
  )

}

export default Tabs;