import React from "react";
import cn from 'classnames';

import './index.scss';

interface IProps {
	amount: number | string
	size?: 'small' | 'middle' | 'large'
	className?: string
}

const Coin = (props: IProps) => {
	const clsPrefix = 'bb-coin-';
	const { amount, size = 'small', className } = props;
	const sizeCls = clsPrefix + size;

	return (
		<span className={cn("bb-coin", sizeCls, className)}>
			<span className={clsPrefix + 'icon'} />
			<span>{amount}</span>
		</span>
	)
}

export default Coin;