import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './index.scss';
import Coin from "@/components/Coin";
import { useBalanceStore, BalanceState } from "@/store";

const Balance = () => {
  const nav = useNavigate();
  const location = useLocation();
  const fetch = useBalanceStore((state: BalanceState) => state.fetch);
  const balance = useBalanceStore((state: BalanceState) => state.balance);

  const handleLinkTo = () => {
    if (location.pathname === '/top-up') {
      return;
    }
    nav('/top-up');
  }

  useEffect(() => {
    fetch();
  }, [location.pathname])

  return (
    <>
      {
        balance !== null ? (
          <div className="balance-in-nav-right" onClick={handleLinkTo}>
            <Coin amount={balance} />
          </div>
        ) : null
      }
    </>
  )

}

export default Balance;