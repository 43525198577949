import { useSetState, useThrottleFn } from "ahooks";
import { calcCardMargin } from "@/utils/biz";
import { useEffect } from "react";

const useDynamicItemHooks = (args?: any) => {
	const [state, setState] = useSetState({
		margin: 12,
		number: 0,
	})

	const { run } = useThrottleFn(() => {
		const { margin, number } = calcCardMargin(args);
		setState({
			margin,
			number
		})
	}, {wait: 300});

	useEffect(() => {
		run();
		window.addEventListener('resize', run);
		return () => {
			window.removeEventListener('resize', run);
		}
	}, [])

	return {
		margin: state.margin,
		number: state.number
	}

}

export default useDynamicItemHooks;