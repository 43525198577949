import React, { useEffect } from "react";
import cn from "classnames";
import Swiper from "@/components/Swiper";
import Button from "@/components/Button";
import Input from "@/components/Input";
import { useTranslation } from "react-i18next";
import AgreementModal from "@/bizComponents/AgreementModal";
import { Toast } from "antd-mobile";
import { getBanner } from "@/service/common";
import { BannerPositionEnum, twoDecimalNumberPattern } from "@/interfaces";
import { bannerListAdapt } from "@/utils/biz";
import './index.scss';
import { useSearchParams } from "react-router-dom";
import { rechargeApply } from "@/service/account";
import { getRechargeAmountLimit, getRechargeConfig } from "@/service/dict";
import { useSetState } from "ahooks";
import { getJsonData } from "@/utils/util";

const TopUp = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [state, setState] = useSetState({
    value: undefined,
    active: null,
    checked: false,
    banner: [],
    minRechargeAmount: 0,
    supportChannel: [],
    itemList: []
  })

  useEffect(() => {
    Promise.all([
      getBanner(BannerPositionEnum.topUp),
      getRechargeConfig(),
      getRechargeAmountLimit()
    ]).then(([banner, config, limit]) => {
      if (banner?.data) {
        setState({
          banner: bannerListAdapt(banner?.data)
        })
      }
      if (config?.data) {
        if (typeof config.data === 'string') {
          const configData = getJsonData(config.data);
          if (configData) {
            setState({
              minRechargeAmount: configData.min_recharge_amount,
              supportChannel: configData.support_channel
            })
          }
        }
      }
      if (limit?.data) {
        if (typeof limit.data === 'string') {
          const itemList = getJsonData(limit.data);
          if (itemList) {
            setState({
              itemList
            })
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    const v = searchParams.get('v');
    if (v) {
      setState({
        value: v
      })
    }
  }, [])

  const onItemClick = (data: number) => {
    setState({
      active: data,
      value: data
    })
  }

  const onInput = (v: string) => {
    if (twoDecimalNumberPattern.test(v) || !v) {
      setState({
        active: null,
        value: v
      })
    }
  }

  const onChecked = () => {
    setState({
      checked: !state.checked,
    })
  }

  const onPay = (type: string) => {
    if (state.value < state.minRechargeAmount) {
      Toast.show({
        content: t('bb.minimum-recharge') + state.minRechargeAmount,
      })
      return;
    }
    if (!state.checked) {
      Toast.show({
        content: t('bb.please-check-agreement'),
        position: 'bottom'
      })
      return;
    }
    rechargeApply({
      amount: state.value,
      channel: type.toUpperCase()
    }).then(res => {
      if (res?.data?.url) {
        window.location.href = res?.data?.url
      }
    })

  }

  return (
    <div className="top-up-wrapper">
      <div className="top-content">
        <p className="text">{t('bb.top-up-amount')}</p>
        <Input 
          placeholder={t('bb.please-input-top-up-amount')}
          value={state.value}
          onChange={onInput}
        />
        <p className="tips">$1=<span className="coin" />1</p>
        <div className="recommend">
          <p className="title">{t('bb.recommendation')}</p>
          <div className="item-list">
            {
              state.itemList.map(item => (
                <div
                  key={item}
                  className={cn("item", {
                    active: item === state.active
                  })}
                  onClick={() => onItemClick(item)}
                >
                  $&nbsp;{item}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <Swiper dataSource={state.banner}/>
      <AgreementModal onChecked={onChecked}/>
      <div className="pay-btn-group">
        {
          state.supportChannel.map((item, idx) => (
            <Button
              key={item + idx}
              size="middle"
              color="warning"
              onClick={() => onPay(item)}
              disabled={!state.value}
              style={{height: 41}}
            >
              {t('bb.pay-with', {type: item})}
            </Button>
          ))
        }
      </div>
    </div>
  )

}

export default TopUp;