import React from "react";
import Common from "./Common";
import { waitDeliverAdapt } from "@/utils/biz";
import Empty from "@/components/Empty";
import { BoxStatusEnum } from "@/interfaces";

interface IProps {
	dataSource: any[]
}

const WaitDeliver = (props: IProps) => {
	const formattedData = waitDeliverAdapt(props.dataSource);

	return (
		<>
			{
				formattedData.length === 0 ?
					<Empty /> :
					<div className="wait-deliver-wrapper">
						{
							formattedData.map(item => (
								<Common item={item} type={BoxStatusEnum.waitDeliver} key={item.id} />
							))
						}
					</div>
			}
		</>
	)

}

export default WaitDeliver;