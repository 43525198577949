import React from "react";
import Common from "./Common";
import Empty from "@/components/Empty";

interface IProps {
  dataSource: any[]
}
const WaitPay = (props: IProps) => {

  return (
    <>
      {
        props.dataSource.length === 0 ?
        <Empty /> :
          <div className="closed-wrapper">
            {
              props.dataSource.map(item => (
                <Common key={item.id} item={item} type="closed" />
              ))
            }
          </div>
      }
    </>
  )
}

export default WaitPay;