const tryCatch = async (arg, rejectError: boolean = false) => {
	let response: any;
	try {
		response = await Promise.resolve(arg);
	} catch (e: any) {
		if (rejectError) {
			// 上层自行处理error
			response = e;
			await Promise.reject(e)
		}
	}
	return response;
}


export default tryCatch;