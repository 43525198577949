import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from "react-i18next";
import './index.scss';

const TabBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const tabs = [
    {
      key: 'home',
      path: '/',
      title: 'bb.home',
    },
    {
      key: 'mine',
      path: '/mine',
      title: 'bb.mine',
    },
  ]

  const handleClick = (path: string) => {
    navigate(path);
  }

  return (
    <div className="bb-tab-bar">
      {
        tabs.map(item => (
          <div
            className={cn("item", {
              active: pathname === item.path
            })}
            key={item.key}
            onClick={() => handleClick(item.path)}
          >
            <div className={`icon ${item.key}`}/>
            <p>{t(item.title)}</p>
          </div>
        ))
      }
    </div>
  )
}

export default TabBar;