import React from "react";
import { useNavigate } from "react-router-dom";
import './index.scss';
import { useTranslation } from "react-i18next";

const Withdraw = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <p className="withdraw-in-nav-right" onClick={() => navigate('/withdraw-record')}>{t('bb.withdraw-record')}</p>
  )

}

export default Withdraw;