import { agreementContentPTBR, privacyContentPTBR, ruleContentPTBR } from "@/locales/largeContent";

export default {
	translation: {
		"bb.start-login": "Iniciar sessão",
		"bb.please-input-email-or-phone-number": "Por favor insira o seu e-mail ou número de telefone",
		"bb.email-or-phone-number-ten-digits": "E-mail/número de telefone(10 dígitos)",
		"bb.next-step": "Seguinte",
		"bb.please-input-verification-code": "Por favor insira seu código de verificação",
		"bb.please-input-password": "Por favor insira sua senha",
		"bb.get-verification-code": "Receber",
		"bb.resend": "Reenviar",
		"bb.verification-code-input": "Insira o código de verificação",
		"bb.password-input": "Introduza a senha",
		"bb.agree-agreement-and-privacy": "Li e aceito o <0>{{agreement}}</0> e a <1>{{privacy}}</1>",
		"bb.agree": "Li e aceito o ",
		"bb.agreement": "\"Contrato de Serviço\"",
		"bb.agreement-title": "Contrato de Serviço",
		"bb.privacy": "\"Política de Privacidade\"",
		"bb.privacy-title": "Política de Privacidade",
		"bb.set-password": "Configurar senha",
		"bb.set-password-six-digits": "Configurar uma senha de 6 dígitos",
		"bb.continue": "Continuar",
		"bb.skip": "Saltar",
		"bb.box": "Caixa",
		"bb.mine": "Meu",
		"bb.all-boxes": "Todas as caixas",
		"bb.top-up": "Recarregar",
		"bb.rules-of-the-game": "Regras do jogo",
		"bb.recommendation": "Recomendar",
		"bb.box-detail": "Detalhes da caixa",
		"bb.buy": "Comprar",
		"bb.try-it-for-free": "Jogar gratuitamente",
		"bb.items-in-the-box": "Produtos nesta caixa",
		"bb.open-box-tip": "Nota: Um dos produtos será encontrado ao abrir a caixa.",
		"bb.confirm-pay": "Confirmar pagamento",
		"bb.box-price": "Preço da caixa",
		"bb.the-order-closes-in-time": "O pedido será encerrado após as {{time}}",
		"bb.freight": "Frete",
		"bb.pay": "Pagar",
		"bb.pay-success": "Pagar com sucesso",
		"bb.open-case": "Abrir",
		"bb.open-it-later": "Abrir mais tarde",
		"bb.open-it-now": "Abrir agora",
		"bb.if-later-open-in-my-box": "Escolher \"abrir mais tarde\" e você pode ir para \"Minha caixa\" para abri-lo.",
		"bb.deliver": "Enviar",
		"bb.recycle-some-money": "Recuperar(<0>{{amount}}</0>)",
		"bb.one-more-time": "De novo",
		"bb.value-of-item": "Valor do produto",
		"bb.confirm-order": "Confirmar a ordem",
		"bb.pay-now": "Pagar agora",
		"bb.recycle-some-items": "Recuperar {{number}} products",
		"bb.recycle-price": "Valor de recuperação",
		"bb.confirm-recycle": "Confirmar recuperação",
		"bb.my-orders": "Meu pedido",
		"bb.my-boxes": "Minha caixa",
		"bb.address": "Endereço de entrega",
		"bb.about-us": "Sobre nós",
		"bb.contact-customer-service": "Entre em contato com o atendimento ao cliente",
		"bb.faq": "Problema comum",
		"bb.customer-agreement": "",
		"bb.customer-service-feedback": "Comentário do problema",
		"bb.setting": "Configuração",
		"bb.transactions": "Registro de transação",
		"bb.pending-pay": "Pendente para pagar",
		"bb.done": "Concluído",
		"bb.closed": "Fechado",
		"bb.the-order-closes-in-minutes": "O pedido será fechado após {{time}}",
		"bb.cancel-order": "Cancelar pedido",
		"bb.payment-now": "Pagar agora",
		"bb.close": "Fechar",
		"bb.transaction-done": "Transação concluída",
		"bb.pending-open-box": "Pendente para abrir",
		"bb.pending-pick-up": "Pendente para abrir",
		"bb.recycled": "Recuperado",
		"bb.pending-deliver": "Pendente para enviar",
		"bb.delivered": "Enviado",
		"bb.order-time": "Hora do pedido",
		"bb.recycle": "Recuperar",
		"bb.open-box": "Abrir",
		"bb.price": "Preço",
		"bb.exchange-amount": "Monto de canje",
		"bb.your-items-prepare-deliver": "Valor de troca",
		"bb.your-items-delivered": "Seu produto foi enviado, por favor aguarde para atualizar o status do envio...",
		"bb.your-items-signed": "Seu produto foi entregue...",
		"bb.box-number": "Número da caixa",
		"bb.withdraw": "Retirar",
		"bb.box-buy": "Comprar caixa",
		"bb.top-up-amount": "Valor da recarga",
		"bb.please-input-top-up-amount": "Por favor, insira o valor que deseja recarregar.",
		"bb.contact-us": "Contatar connosco",
		"bb.email": "Email",
		"bb.email-require": "*Email",
		"bb.work-time": "Horário de trabalho",
		"bb.problem-feedback": "Comentário do problema",
		"bb.please-input-suggestion-or-problem": "Por favor, preencha sugestões ou perguntas",
		"bb.upload-screenshot": "Carregar captura de tela",
		"bb.your-email-or-phone": "Carregar captura de tela",
		"bb.submit": "Enviar",
		"bb.add-address": "Adicionar endereço de entrega",
		"bb.confirm": "Confirmar",
		"bb.add-address-2": "Adicionar endereço de entrega",
		"bb.modify-address": "Modificar endereço de entrega",
		"bb.full-name-require": "*Nome (nome completo)",
		"bb.phone-number-require": "*Número de telefone",
		"bb.phone-number": "Número de telefone",
		"bb.full-address-require": "*Endereço detalhado",
		"bb.postal-code": "Código postal",
		"bb.version": "Versão",
		"bb.logout": "Sair",
		"bb.withdraw-record": "Registro de retirada",
		"bb.withdraw-amount-available": "Valor atualmente disponível para retirada",
		"bb.withdraw-amount": "Valor retirado",
		"bb.your-phone-number": "Seu número de telefone",
		"bb.your-email": "Seu Email",
		"bb.apply-withdraw": "Solicitar retirada",
		"bb.withdraw-all-amount": "Todas as caixas",
		"bb.withdraw-result": "Resultado da retirada",
		"bb.withdraw-apply-success": "A solicitação de retirada foi bem-sucedida, aguarde o processamento do atendimento ao cliente.",
		"bb.apply-time": "Tempo de aplicação",
		"bb.applied": "Aplicado",
		"bb.processing": "Em processo",
		"bb.withdraw-success": "Retirada bem-sucedida",
		"bb.withdraw-failed": "Retirada falhada",
		"bb.process-time": "Tempo de processamento",
		"bb.home": "Início",
		"bb.address-detail": "Detalhes do endereço de entrega",
		"bb.order-detail": "Detalhes do pedido",
		"bb.login": "Iniciar sessão",
		"bb.phone-or-email": "E-mail/número de telefone",
		"bb.consignee": "Receptor",
		"bb.order-number": "Número do pedido",
		"bb.selected-delivery": "Selecionado para entrega",
		"bb.signed": "Foi recebido",
		"bb.set-default-address": "Definir como endereço padrão",
		"bb.system-busy": "Sistema está ocupado",
		"bb.system-error": "Erro do sistema",
		"bb.balance-insufficient": "Saldo insuficiente",
		"bb.login-expired": "A sessão expirou, faça o login novamente",
		"bb.user-not-exist": "Usuário não encontrado",
		"bb.password-error": "Senha incorreta",
		"bb.code-expired": "O código de verificação expirou",
		"bb.email-send-failed": "Falha no envio do e-mail, tente novamente",
		"bb.sms-send-failed": "Falha no envio do SMS, tente novamente",
		"bb.select-country-title": "Por favor selecione seu país e idioma",
		"bb.privacy-content": privacyContentPTBR,
		"bb.agreement-content": agreementContentPTBR,
		"bb.change-lang": "Mudar idioma",
		"bb.cancel-order-alert-title": "Tem certeza que deseja cancelar este pedido?",
		"bb.rule-content": ruleContentPTBR,
		"bb.input-amount-wrong": "Valor incorreto inserido",
		"bb.input-format-wrong": "Formato incorreto",
		"bb.please-check-agreement": "Por favor, leia e concorde com o acordo primeiro",
		"bb.set-successfully": "Definido com sucesso",
		"bb.add-successfully": "Adicionado com sucesso",
		"bb.deleted-successfully": "Apagado com sucesso",
		"bb.update-successfully": "Atualizado com sucesso",
		"bb.payment-successfully": "Pagamento bem sucedido",
		"bb.recover-successfully": "Recuperado com sucesso",
		"bb.cancel-successfully": "Cancelado com sucesso",
		"bb.send-successfully": "Entregar bem sucedida",
		"bb.the-picture-is-too-large": "A imagem é muito grande",
		"bb.feedback-successfully": "Dar feedback bem sucedido",
		"bb.password-format-wrong": "Erro de formato de senha",
		"bb.request-is-timeout": "A solicitação expirou",
		"bb.yes": "Sim",
		"bb.no": "Não",
		"bb.number-of-products": " produtos",
		"bb.pay-with": "Pagar por {{type}}",
		"bb.minimum-recharge": `Recarga mínima é de $`
	}
}