import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

const p = '/api/user/';

// 获取个人信息
export const getUserInfo = (): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryInfo',
		method: 'post',
		headers: {
			'access-token': true,
			hideToast: true
		}
	}), true)
}

// 是否是老用户 废弃
// export const checkUser = (data: { [x: string]: any }): Promise<any> => {
// 	// pwdSetStatus true 已设置密码，false ：未设置密码
// 	// isNew  true 新用户，false 老用户
// 	return tryCatch(request({
// 		url: p + 'check',
// 		method: 'post',
// 		data
// 	}))
// }

// 设置密码
export const setPassword = (data: { password: string }): Promise<any> => {
	return tryCatch(request({
		url: p + 'setPwd',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 回收试算接口
export const boxRecycleCount = (boxId: number[]): Promise<any> => {
	return tryCatch(request({
		url: p + 'box/recycleCount',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			boxId
		}
	}))
}

// 我的盒子列表
export const getMyBoxList = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'box/queryPage',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

export interface IBoxRecycleParam {
	boxId: number[]
	recycleCheckAmount: number
}

// 确认回收接口
export const boxRecycle = (data: IBoxRecycleParam): Promise<any> => {
	return tryCatch(request({
		url: p + 'box/recycle',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 用户试玩接口
export const tryItForFree = (boxId): Promise<any> => {
	return tryCatch(request({
		url: p + 'box/tryPlay',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			boxId
		}
	}))
}

// 获取用户试玩接口次数
export const getTryPlayTimes = (boxId): Promise<any> => {
	return tryCatch(request({
		url: p + 'box/tryPlayTimes',
		method: 'post',
		headers: {
			'access-token': true,
			hideToast: true
		},
		data: {
			boxId
		}
	}), true)
}
