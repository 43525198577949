import { tsToTime } from "@/utils/time";
import { divide, getJsonData } from "@/utils/util";
import { CHECKED_LANG_TYPE_KEY, LangType } from "@/interfaces";
import { setDefaultConfig } from "antd-mobile";
import ZH_CN from "@/locales/antd/ZH_CN.json";
import EN from "@/locales/antd/EN.json";
import PT_BR from "@/locales/antd/PT_BR.json";
import ES_MX from "@/locales/antd/ES_MX.json";

export const boxTypeListAdapt = (data: any[], t: any) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}
	const top = [
		{
			key: 'recommendation',
			title: t('bb.recommendation')
		}
	]
	const list = data.map(item => ({
		key: item.boxType,
		title: item.boxTypeName
	}))

	return [...top, ...list];
}

export const bannerListAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		pic: item.bannerDetail,
		url: item.redirectUrl,
		linkType: item.linkType
	}))
}

export const boxItemListAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		name: item.boxName,
		currentAmount: item.curBoxAmount,
		originAmount: item.originBoxAmount,
		discount: item.discountRate,
		pic: item.boxImg1,
		quantity: item.productCount,
		id: item.id
	}))
}

export const itemInBoxAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		pic: item.productImg1,
		id: item.id,
		currentAmount: item.productAmount,
		productName: item.productName
	}))
}

export const myBoxListAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		userBoxStatus: item.userBoxInfo?.userBoxStatus,
		productImg1: item.productInfo?.productImg1,
		productName: item.productInfo?.productName,
		openBoxTime: item.userBoxInfo?.openBoxTime,
		orderNo: item.userBoxInfo?.orderNo,
		id: item.userBoxInfo?.id,
		productAmount: item.productInfo?.productAmount,
		boxName: item.boxInfo?.boxName,
		boxImg1: item.boxInfo?.boxImg1,
		boxImg2: item.boxInfo?.boxImg2,
		curBoxAmount: item.boxInfo?.curBoxAmount,
		orderTime: tsToTime(item.userBoxInfo?.createTime),
		receiveAddress: item.userBoxInfo?.receiveAddress,
		receiveEmail: item.userBoxInfo?.receiveEmail,
		receiveMobile: item.userBoxInfo?.receiveMobile,
		receiveName: item.userBoxInfo?.receiveName,
		deliveryStatus: item.userBoxInfo?.deliveryStatus,
		boxId: item.boxInfo?.id,
		recycleAmount: item.userBoxInfo?.recycleAmount,
		deliveryAmount: item.userBoxInfo?.deliveryAmount
	}))
}

export const waitOpenBoxAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		pic: item.boxImg1,
		pic2: item.boxImg2,
		name: item.boxName,
		price: item.curBoxAmount,
		orderTime: item.orderTime,
		orderNo: item.orderNo,
		id: item.id,
		boxId: item.boxId
	}))
}

export const waitDeliverAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		pic: item.productImg1,
		name: item.productName,
		price: item.curBoxAmount,
		orderTime: item.orderTime,
		orderNo: item.orderNo,
		id: item.id,
		receiveAddress: item.receiveAddress,
		receiveEmail: item.receiveEmail,
		receiveMobile: item.receiveMobile,
		receiveName: item.receiveName,
		deliveryStatus: item.deliveryStatus,
		freight: item.deliveryAmount
	}))
}

export const waitPickupBoxAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		pic: item.productImg1,
		price: item.productAmount,
		orderTime: item.orderTime,
		orderNo: item.orderNo,
		id: item.id,
		name: item.productName
	}))
}

export const recycledBoxAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		pic: item.productImg1,
		price: item.recycleAmount,
		orderTime: item.orderTime,
		orderNo: item.orderNo,
		id: item.id,
		name: item.productName
	}))
}

export const myOrderListAdapt = (res: any) => {
	const { data, ts } = res;
	const { list } = data;
	if (!Array.isArray(list) || list.length === 0) {
		return [];
	}

	return list.map(item => ({
		orderStatus: item.status,
		id: item.id,
		name: item.boxName,
		price: item.orderAmount,
		expireTime: item.expireTime,
		date: tsToTime(item.createTime),
		pic: item.boxMasterImg,
		detailPic: item.boxDetailImg,
		orderNo: item.orderNo,
		ts,
		boxId: item.boxId
	}))
}

export const withdrawRecordAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}
	return data.map(item => ({
		id: item.id,
		applyTime: tsToTime(item.applyTime),
		tradeAmount: item.tradeAmount,
		status: item.status,
		processTime: item.complateTime ? tsToTime(item.complateTime) : undefined
	}))
}

export const transactionsAdapt = (data: any[]) => {
	if (!Array.isArray(data) || data.length === 0) {
		return [];
	}

	return data.map(item => ({
		id: item.id,
		tradeType: item.tradeType, // 1.充值 ； 2.提现； 3.消费 ; 4.回收
		directionType: item.directionType, // 1-进账,2-出账,
		tradeAmount: item.tradeAmount,
		time: tsToTime(item.createTime),
		remark: item.remark
	}))
}

export const calcBuyBtnAmount = (balance: number, price: number) => {
	const c = divide(balance, price);
	return c >= 0 ? null : Math.abs(c);
}

export const setDefaultLang = () => {
	let lang = getJsonData(localStorage.getItem(CHECKED_LANG_TYPE_KEY));
	if (!lang || !(lang in LangType)) {
		localStorage.removeItem(CHECKED_LANG_TYPE_KEY);
		localStorage.setItem(CHECKED_LANG_TYPE_KEY, JSON.stringify(LangType.ES_MX));
		lang = LangType.ES_MX;
	}
	const langMap = {
		'ZH_CN': ZH_CN,
		'EN': EN,
		'PT_BR': PT_BR,
		'ES_MX': ES_MX
	}
	setDefaultConfig({ locale: langMap[lang] });
	return lang;
}

export const calcCardMargin = (sideWidth = 0) => {
	// 动态计算屏幕内卡片的边距
	const SCREEN_WIDTH = window.innerWidth - sideWidth;
	const ITEM_WIDTH = 110;
	const number = parseInt(String(SCREEN_WIDTH / ITEM_WIDTH), 10);
	const rest = (SCREEN_WIDTH - ITEM_WIDTH * number);
	const margin = parseInt(String(rest / (number + 1)), 10);

	return {
		margin,
		number
	};
}
