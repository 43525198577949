import request from "@/utils/request";
import tryCatch from "@/utils/tryCatch";

const p = '/api/order/';

// 支付盒子运费
export const payFreight = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'payment/freight',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}

// 创建盒子运费订单
export const createFreight = (userBoxIds: number[]): Promise<any> => {
	return tryCatch(request({
		url: p + 'createFreight',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			userBoxIds
		}
	}))
}

// 取消订单
export const cancelOrder = (id: string | number): Promise<any> => {
	return tryCatch(request({
		url: p + 'cancel',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			id
		}
	}))
}

// 创建订单
export const createOrder = (boxId: string | number): Promise<any> => {
	return tryCatch(request({
		url: p + 'create',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			boxId
		}
	}))
}

// 去发货-试算运费
export const deliveryCount = (boxId: number[]): Promise<any> => {
	return tryCatch(request({
		url: p + 'delivery/count',
		method: 'post',
		headers: {
			'access-token': true
		},
		data: {
			boxId
		}
	}))
}

// 支付盒子订单
export const payBox = (orderNo: string | number): Promise<any> => {
	return tryCatch(request({
		url: p + 'payment/box',
		method: 'post',
		headers: {
			'access-token': true,
		},
		data: {
			orderNo
		}
	}))
}


// 我的订单-订单列表
export const getOrderList = (data): Promise<any> => {
	return tryCatch(request({
		url: p + 'queryPage',
		method: 'post',
		headers: {
			'access-token': true
		},
		data
	}))
}