import { create } from 'zustand';
import { getIndexPopup } from "@/service/dict";
import { LinkTypeEnum } from "@/interfaces";

interface data {
	showIcon?: boolean
	iconImg?: string
	iconLink?: string
	iconLinkEnv?: LinkTypeEnum
	version?: string
}

interface IndexPopupState {
	indexPopup: data
	fetch: () => void
	reset: () => void
}

const useIndexPopupStore = create((set): IndexPopupState => ({
	indexPopup: {},
	fetch: async () => {
		const res = await getIndexPopup();
		if (res?.data) {
			const data = typeof res.data === 'string' ? JSON.parse(res.data || '{}') : {};
			set({
				indexPopup: data
			})
		}
	},
	reset: () => {
		set({
			indexPopup: {}
		})
	}
}))

export {
	useIndexPopupStore,
	IndexPopupState
};