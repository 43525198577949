import React from "react";
import Loading from "@/components/Loading";
import ItemCard from "@/bizComponents/ItemCard";
import cn from 'classnames';
import { InfiniteScroll } from "antd-mobile";
import './items.scss';
import useDynamicItemHooks from "@/hooks/use-dynamic-item-hooks";

interface IProps {
	loading: boolean
	dataSource: any[]
	onItemClick: (data: any) => void
	loadMore: (isRetry: boolean) => Promise<any>
	hasMore: boolean
}

const Items = (props: IProps) => {
	const { loading, dataSource, onItemClick, loadMore, hasMore } = props;
	const { margin, number } = useDynamicItemHooks();
	const padding = (v) => dataSource.length <= number ? v : margin;

	return (
		<>
			<div
				className='home-items-wrapper'
				style={{
					paddingLeft: padding(12),
					paddingRight: padding(12)
				}}
			>
				{
					loading ? <Loading /> :
						<>
							{
								dataSource.map((item, idx) => (
									<ItemCard
										className={cn('card', {
											'margin-none': (idx + 1) % number === 0
										})}
										dataInfo={item}
										onClick={onItemClick}
										key={item.id}
										style={{
											marginRight: padding(10)
										}}
									/>
								))
							}
						</>
				}
			</div>
			{
				!loading &&
				<InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}/>
			}
		</>
	)
}

export default Items;